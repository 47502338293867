import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../Axios/AxiosFunctions";
import BodyLoader from "../components/BodyLoader";
import { BaseURL } from "../Config/apiUrl";

const Dashboard = () => {
  const { access_token } = useSelector((state) => state?.authReducer);
  const currentUser = useSelector((state) => state.authReducer.user);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState([]);

  // getData
  const getData = async () => {
    const url = BaseURL("users/stats");

    setIsLoading(true);
    const response = await Get(url, access_token);
    setIsLoading(false);

    if (response !== undefined) {
      setStats(response?.data?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return <BodyLoader />;
  }

  return (
    <section className="container-fluid reported-ads">
      <div>
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>STATISTICS</h1>
        </div>

        <div id="root">
          <div className="container pt-5">
            <div className="row align-items-stretch">
              {stats.length > 0 &&
                stats.map((e) => (
                  <div className="c-dashboardInfo col-lg-3 col-md-6 mt-4">
                    <div className="wrap">
                      <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                        {e?.name}
                      </h4>
                      <span className="hind-font caption-12 c-dashboardInfo__count">
                        {e?.value}
                      </span>
                      {e?.active && <span className="">{e?.active}</span>}
                      {e?.cancelled && <span className="">{e?.cancelled}</span>}
                      {e?.completed && <span className="">{e?.completed}</span>}
                      {e?.deactive && <span className="">{e?.deactive}</span>}
                      {e?.booking && <span className="">{e?.booking}</span>}
                      {e?.cancel && <span className="">{e?.cancel}</span>}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
