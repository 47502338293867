import { UpdateRounded } from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";

const AddCommissionModal = ({
  open,
  setOpen,
  onUpdate,
  updating,
  prevData,
}) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [keyType] = useState(prevData?.KeyType);
  const [commission, setCommission] = useState(prevData?.adminCommission?.rate);
  const [commissionId] = useState(prevData?._id);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title text-color text-left">
          Edit Commission
        </span>
        <div data-validate="Commission is required">
          {updating && <span className="label-input100">Commission</span>}
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="commission"
            placeholder="Commission"
            value={commission}
            onChange={(e) => {
              setCommission(e.target.value);
            }}
          />
        </div>

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isApiCall}
              class="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                const payload = {
                  keyType,
                  adminCommission: { rate: commission * 1 },
                };
                onUpdate(payload, commissionId, setIsApiCall);
              }}
            >
              {updating
                ? isApiCall
                  ? "Editing..."
                  : "Edit"
                : isApiCall
                ? "Adding..."
                : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddCommissionModal;
