import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import ModalSkeleton from "./ModalSkeleton";

const EditUserModal = ({ open, setOpen, data, type, onUpdate }) => {
  const [firstName, setFirstName] = useState(data?.firstName);
  const [lastName, setLastName] = useState(data?.lastName);
  const [middleName, setMiddleName] = useState(data?.middleName);
  const [email, setEmail] = useState(data?.email);
  const [contact, setContact] = useState(data?.phoneNumber);
  const [countryCode, setCountryCode] = useState("SO+252");
  const [isApiCall, setIsApiCall] = useState(false);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          Edit {`${type}`}
        </span>

        <div data-validate="First Name is required" className="">
          <span className="label-input100">First Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="firstname"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>
        <div data-validate="Middle Name is required" className="mt-3">
          <span className="label-input100">Middle Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="middleName"
            placeholder="Middle Name"
            value={middleName}
            onChange={(e) => {
              setMiddleName(e.target.value);
            }}
          />
        </div>
        <div data-validate="Last Name is required" className="mt-3">
          <span className="label-input100">Last Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="lastname"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        <div data-validate="Email is required" className="mt-3">
          <span className="label-input100">Email</span>
          <input
            className="add-evidence-modal-input full"
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div data-validate="Country code is required" className="d-flex mt-3">
          <div className="col-md-3">
            <span className="label-input100">Country Code</span>
            <input
              className="add-evidence-modal-input full"
              type="text"
              name="country-code"
              placeholder="Country Code"
              disabled="true"
              value={countryCode}
              onChange={(e) => {
                setCountryCode(e.target.value);
              }}
            />
          </div>

          <div data-validate="Contact is required" className="mx-1 col-md-9">
            <span className="label-input100">Phone Number</span>
            <input
              className="add-evidence-modal-input full"
              type="number"
              name="contact"
              placeholder="63xxxxx"
              value={contact}
              onChange={(e) => {
                setContact(e.target.value);
              }}
            />
          </div>
        </div>
        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isApiCall}
              className="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                const params = {
                  firstName,
                  lastName,
                  middleName,
                  email,
                  contact,
                  countryCode: "+252",
                };

                for (let key in params) {
                  if (params[key] == "") {
                    return toast.warn(`${key} can not be empty.`);
                  }
                }
                onUpdate(params, setIsApiCall);
              }}
            >
              {isApiCall ? "Editing..." : "Edit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default EditUserModal;
