import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get, Post, Delete, Patch } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import AddUserModal from "../components/AddUserModal";
import EditUserModal from "../components/EditUserModal";
import { Col, Container, Row } from "react-bootstrap";
// Mui Button
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import PaginationComponent from "../components/PaginationComponent/index";

function AdminLists() {
  const [isAddingAdmin, setIsAddingAdmin] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [adminData, setAdminsData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState();

  const getAdminsData = async (pageNo, newLimit) => {
    setIsGetting(true);
    const url = BaseURL(
      `users/get-by-admin?role=manager&isActive=true&page=${pageNo}&limit=${newLimit}`
    );
    // let params = {
    //   role: "admin",
    //   isActive: true,
    // };
    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setLimit(responseData?.data?.totalRecords);
      setAdminsData(responseData?.data?.data);
    }

    setIsGetting(false);
  };

  const addAdmin = async (data, setIsApiCall) => {
    if (data?.password.length < 8)
      return toast.warn("Password must be at least 8 characters long.");
    setIsApiCall(true);
    const url = BaseURL(`users/admin-signup`);
    let params = {
      role: "manager",
      ...data,
    };

    const responseData = await Post(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success("Admin Added Successfully");
      setIsAddingAdmin(false);
      getAdminsData(1, recordsLimit);
      return;
    }
    setIsApiCall(false);
  };

  const updateAdmin = async (data, setIsApiCall) => {
    let params = {
      role: "manager",
      phoneNumber: data?.contact,
      ...data,
    };
    setIsApiCall(true);

    const url = BaseURL(`users/${editingData?._id}`);

    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success(`Admin updated successfully!`);
      getAdminsData(1, recordsLimit);
      setIsEditing(false);
      return;
    }
    setIsApiCall(false);
  };
  const deleteAdmin = async () => {
    const url = BaseURL(`users/${deletingId}`);

    const responseData = await Delete(url, null, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Admin deleted successfully!`);
      getAdminsData(1, recordsLimit);
      setIsDeleting(false);
      return;
    }
  };

  useEffect(() => {
    getAdminsData(1, recordsLimit);
  }, []);

  const HandlePageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
  };
  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAdminsData(page, recordsLimit);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>ADMINS</h1>
      </div>

      {/* Add Section Button Container */}

      <div className="fieldSetMainContainer">
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center"></div>
          <div className="d-flex align-items-center">
            <MuiButton
              onClick={() => setIsAddingAdmin(true)}
              variant="contained"
              color="primary"
              className="custom-btn rounded-lg radius-8 ms-4"
              startIcon={<IoMdAddCircleOutline />}
            >
              Add
            </MuiButton>
          </div>
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Last Updated</th>
                    <th>Active</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {adminData?.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan={8}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {adminData?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (pageNumber - 1) * recordsLimit}</td>
                          <td>{item?.firstName}</td>
                          <td>{item?.lastName}</td>
                          <td>{item?.email}</td>
                          <td>{`${item?.countryCode}${item?.phoneNumber}`}</td>
                          <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>{item?.isActive ? "Yes" : "No"}</td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsEditing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Edit
                                </Button>
                              </li>
                              <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsDeleting(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Col md={12} className="paginationContainer">
          {adminData?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      {/* AreYouSureModal */}
      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message={`Do you really want to delete this admin?`}
        handleYes={() => deleteAdmin()}
      />
      {isAddingAdmin && (
        <AddUserModal
          open={isAddingAdmin}
          setOpen={setIsAddingAdmin}
          onUpdate={addAdmin}
          type={"Admin"}
        />
      )}
      {isEditing && (
        <EditUserModal
          open={isEditing}
          setOpen={setIsEditing}
          onUpdate={updateAdmin}
          type={"Admin"}
          data={editingData}
        />
      )}
    </section>
  );
}

export default AdminLists;
