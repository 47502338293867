import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AddCommissionModal from "../components/AddCommissionModal";
import { toast } from "react-toastify";

// Mui Button

const Commission = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddingCity, setIsAddingCity] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState();
  const [commissions, setCommissions] = useState([]);

  const getAllCommissions = async (pageNo, newLimit) => {
    setIsGetting(true);
    const url = BaseURL(`config/commissions`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setCommissions(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const updateCommission = async (data, id, setIsApiCall) => {
    setIsApiCall(true);
    const url = BaseURL(`config/commissions/${id}`);
    let params = {
      KeyType: data.KeyType,
      adminCommission: data.adminCommission,
    };

    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Updated successfully`);
      setIsEditing(false);
      getAllCommissions();
      return;
    }
    getAllCommissions();
  };

  useEffect(() => {
    getAllCommissions();
  }, []);

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>COMMISSION</h1>
      </div>

      <div className="fieldSetMainContainer mt-5">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Commission Type</th>
                    <th>Commission</th>
                    <th>Last Updated</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {commissions.length < 1 && (
                    <tr className="no-data-table-row">
                      <td colSpan={9}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p>No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {commissions?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{item?.KeyType}</td>
                          <td>
                            {item?.KeyType == "wafipay-commission" ? (
                              <span>
                                {item?.adminCommission.rate}
                                <strong>{" SLSH"}</strong>
                              </span>
                            ) : (
                              item?.adminCommission?.rate + "%"
                            )}
                          </td>
                          {/* {usersType === 'vendor' && <td>{item?.companyName}</td>} */}
                          <td>
                            {moment(item?.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          {/* <td>{item?.isActive ? "Yes" : "No"}</td> */}
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsEditing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Edit
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {isEditing && (
        <AddCommissionModal
          open={isEditing}
          setOpen={setIsEditing}
          onUpdate={updateCommission}
          updating={"Edit"}
          prevData={editingData}
        />
      )}
    </section>
  );
};

export default Commission;
