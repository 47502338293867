import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
import { useSelector, useDispatch } from "react-redux";
import { BaseURL, apiHeader } from "../Config/apiUrl";
import { Row, Col } from "react-bootstrap";
import { Get } from "../Axios/AxiosFunctions";
import moment from "moment";

const ViewAppointmentModal = ({ open, setOpen, data }) => {
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const user = useSelector((state) => state.authReducer.user);
  const [apptDate] = useState(data?.appointmentDate);
  const [apptDay] = useState(data?.appointmentDay);
  const [apptStartTime] = useState(data?.appointmentStartTime);
  const [apptEndTime] = useState(data?.appointmentEndTime);
  const [fees] = useState(data?.serviceCharges);
  const [commission] = useState(data?.commission);
  const [wafipayCommission, setWafipayCommission] = useState();
  const [tokenNumber] = useState(data?.tokenNumber);
  const [apptWith] = useState(data?.appointmentWith);
  const [serviceName] = useState(data?.serviceName);
  const apptmntCond = apptWith === "covid19";
  const [isGetting, setIsGetting] = useState(false);

  const getCommissions = async (pageNo, newLimit, status, from, to) => {
    setIsGetting(true);
    const wafipayUrl = BaseURL("config/commissions");

    const resData = await Get(wafipayUrl, accessToken);
    if (resData !== undefined) {
      const foundWafipayCommission = resData?.data?.data?.find(
        (item) => item?.KeyType == "wafipay-commission"
      );
      setWafipayCommission(foundWafipayCommission);
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getCommissions();
  }, []);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color pb-4">
          Appointment Details
        </span>
        <Row>
          <Col md={8}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">Date:</span>
              <span className="font-14">{moment(apptDate).format("ll")}</span>
            </div>
          </Col>
          <Col md={4}>
            <div>
              <span className="font-17 fw-bold">Day:</span>
              <p className="font-14">{apptDay}</p>
            </div>
          </Col>
          <Col md={8}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">Start Time:</span>
              <span className="font-14">{apptStartTime}</span>
            </div>
          </Col>
          <Col md={4}>
            <div>
              <span className="font-17 fw-bold">End Time:</span>
              <p className="font-14">{apptEndTime}</p>
            </div>
          </Col>
          {apptmntCond && (
            <Col md={apptmntCond && 8}>
              <div className="d-flex flex-column">
                <span className="font-17 fw-bold">Service Name:</span>
                <span className="font-14">{serviceName}</span>
              </div>
            </Col>
          )}
          <Col md={apptmntCond ? 4 : 8}>
            <div>
              <span className="font-17 fw-bold">Appointment Number:</span>
              <p className="font-14">{tokenNumber}</p>
            </div>
          </Col>
          {apptWith == "doctor" && (
            <Col md={apptWith == "doctor" ? 4 : 8}>
              <div>
                <span className="font-17 fw-bold">Doctor Name</span>
                <p className="font-14">{data?.doctorId?.displayName}</p>
              </div>
            </Col>
          )}
          <Col md={4}>
            <div>
              <span className="font-17 fw-bold">Charges:</span>
              <p className="font-14">
                {isGetting
                  ? ""
                  : fees - wafipayCommission?.adminCommission?.rate}{" "}
                <strong>SLSH</strong>
              </p>
            </div>
          </Col>
        </Row>
      </form>
    </ModalSkeleton>
  );
};

export default ViewAppointmentModal;
