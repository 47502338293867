import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import moment from "moment";

const DoctorScheduleModal = (props) => {
  const {
    appointmentDate: _appointmentDate,
    appointmentTime: _appointmentTime,
    consultationFee,
    handleYes,
    isLoading,
    isCovid,
    covid19Services,
    patientProfile,
    commissions,
  } = props;

  const [patientName, setPatientName] = useState(
    `${patientProfile?.firstName} ${patientProfile?.middleName} ${patientProfile?.lastName}`
  );
  const [patientNumber, setPatientNumber] = useState(
    `${patientProfile?.countryCode || "+1"} ${patientProfile?.phoneNumber}`
  );

  const [covid19Service, setCovid19Service] = useState("");
  const [covid19Price, setCovid19Price] = useState("");

  useEffect(() => {
    if (covid19Services?.length > 0) {
      setCovid19Service(covid19Services[0].serviceName);
      setCovid19Price(covid19Services[0].servicePrice);
    }
  }, []);

  return (
    <Modal
      className="modal-box"
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h4>Appointment Details</h4>
        <div className="modal-inp inp-gray">
          <input
            type="text"
            value={`Appointment Date: ${moment(_appointmentDate).format(
              "Do MMM YYYY"
            )}`}
            disabled
          />
        </div>
        <div className="modal-inp inp-gray">
          <input
            type="text"
            disabled
            value={`Appointment Time: ${_appointmentTime?.startTime} - ${_appointmentTime?.endTime}`}
          />
        </div>
        <div className="modal-inp">
          <input
            type="text"
            placeholder="Patient Name"
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
          />
        </div>
        <div className="modal-inp">
          <input
            type="text"
            placeholder="Patient Number"
            value={patientNumber}
            onChange={(e) => setPatientNumber(e.target.value)}
          />
        </div>

        {!isCovid && (
          <div className="modal-inp inp-gray">
            <input
              type="text"
              value={`Fees ${parseFloat(
                consultationFee +
                  (consultationFee * commissions?.commissionWithPatient) / 100 +
                  commissions?.wafiPayAmount
              ).toFixed(2)} SLSH`}
              disabled
            />
          </div>
        )}
        {isCovid && (
          <div className="mb-4">
            <select
              className="form-select"
              aria-label="Default select example"
              placeholder="Select Service...."
              // value={covid19Service}
              onChange={(e) => {
                setCovid19Service(e.target.value);
                setCovid19Price(() => {
                  const selectedService = covid19Services.find(
                    (service) => service.serviceName === e.target.value
                  );
                  return selectedService?.servicePrice;
                });
              }}
            >
              {covid19Services.map((e, i) => (
                <option value={e?.serviceName} key={i}>
                  {`Service: ${e?.serviceName} - Price: ${
                    e?.servicePrice +
                    (e?.servicePrice * commissions?.commissionWithPatient) /
                      100 +
                    commissions?.wafiPayAmount
                  } SLSH`}{" "}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="modal-btn">
          <MuiButton
            onClick={() =>
              handleYes(
                patientName,
                patientNumber,
                covid19Service,
                covid19Price +
                  (covid19Price * commissions?.commissionWithPatient) / 100 +
                  commissions?.wafiPayAmount
              )
            }
            variant="contained"
            color="primary"
            className="custom-btn rounded-lg radius-10"
          >
            {isLoading ? "Please Wait..." : "Confirm Appointment"}
          </MuiButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DoctorScheduleModal;
