import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import AreYouSureModal from "../components/AreYouSureModal";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../components/PaginationComponent/index";
// Mui Button

const Notifications = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [notifications, setNotifications] = useState([]);

  const getAllNotifications = async (pageNo, newLimit) => {
    setIsGetting(true);
    const url = BaseURL(`notifications/all?page=${pageNo}&limit=${newLimit}`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setLimit(responseData?.data?.newNotifications);
      setNotifications(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const deleteNotification = async () => {
    const url = BaseURL(`notifications/${deletingId}`);

    const responseData = await Delete(url, null, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Deleted successfully`);
      getAllNotifications(1, recordsLimit);
      setIsDeleting(false);
      return;
    }
  };

  useEffect(() => {
    getAllNotifications(1, recordsLimit);
  }, [pageNumber]);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllNotifications(page, recordsLimit);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>NOTIFICATIONS</h1>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Sender Name</th>
                    <th>Sender Email</th>
                    <th>Message</th>
                    {/* <th>Phone Number</th> */}
                    <th>Created At</th>
                    {/* <th>Active</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {notifications.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan="9">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {notifications?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (pageNumber - 1) * recordsLimit}</td>
                          <td>{item?.sender?.displayName}</td>
                          <td>{item?.sender?.email}</td>
                          <td>{item?.message}</td>
                          {/* <td>{item?.phone}</td> */}
                          {/* {usersType === 'vendor' && <td>{item?.companyName}</td>} */}
                          <td>
                            {moment(item.createdAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          {/* <td>{item?.isActive ? "Yes" : "No"}</td> */}
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsDeleting(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Col md={12} className="paginationContainer">
          {notifications?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>
      {isDeleting && (
        <AreYouSureModal
          open={isDeleting}
          setOpen={setIsDeleting}
          label={"Are You Sure?"}
          message={`Do you really want to delete this?`}
          handleYes={() => deleteNotification(false, deletingId)}
        />
      )}
    </section>
  );
};

export default Notifications;
