import React, { useState, useEffect } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AiOutlineMail } from "react-icons/ai";
import { MdOutlineLock } from "react-icons/md";
import { Post } from "../Axios/AxiosFunctions";
import { loginBg, underProducation } from "../constant/imagePath";
import { saveLoginUserData } from "../store/Actions/authAction";
import { apiUrl, BaseURL, apiHeader } from "../Config/apiUrl";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const handleChange = (e, setter) => {
    const { id, value } = e.target;
    setter(value);
  };

  async function handleLogin(e) {
    setIsLogging(true);
    e.preventDefault();
    const loginURL = BaseURL("users/admin-login");
    let params = {
      email: email,
      password: password,
      role: "admin",
    };

    const responseData = await Post(loginURL, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsLogging(false);
      dispatch(saveLoginUserData(responseData?.data));

      if (responseData?.data?.data?.user?.role == "admin") history.push("/");
      else history.push("/allusers");
    } else {
      setIsLogging(false);
    }
  }

  return (
    <div className="limiter">
      <div
        className="container-login100"
        // style={{ backgroundImage: `url(${loginBg})` }}
      >
        <div
          className="wrap-login100"
          style={{ border: "1px solid lightgrey" }}
        >
          <form className="login100-form validate-form">
            <span className="login100-form-title"> Login </span>

            <div
              className="wrap-input100 validate-input"
              data-validate="Username is reauired"
            >
              <span className="label-input100">Email</span>
              <input
                className="input100"
                type="text"
                name="username"
                placeholder="Type your email"
                value={email}
                onChange={(e) => {
                  handleChange(e, setEmail);
                }}
              />
              <span className="focus-input100">
                <AiOutlineMail size={23} />
              </span>
            </div>

            <div
              className="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <span className="label-input100">Password</span>
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Type your password"
                value={password}
                onChange={(e) => {
                  handleChange(e, setPassword);
                }}
              />
              <span className="focus-input100">
                <MdOutlineLock size={23} />
              </span>
            </div>

            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="custom-btn login100-form-btn"
                  onClick={handleLogin}
                  disabled={isLogging}
                >
                  {isLogging ? "Logging..." : "Login"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
