import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import { formRegEx, formRegExReplacer, imageUrl } from "../Config/apiUrl";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { toast } from "react-toastify";

const AddOrUpdatePageSectionModal = ({
  open,
  setOpen,
  onUpdate,
  isLoading,
  data,
}) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [description2, setDescription2] = useState("");
  const [url, setUrl] = useState("");
  const [secName, setSecName] = useState("");

  //   image states
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const emptyStates = () => {
    setTitle("");
    setSubtitle("");
    setDescription("");
    setDescription2("");
    setUrl("");
    setSelectedImage(null);
    setSelectedImageFile(null);
    setSecName("");
  };

  useEffect(() => {
    if (data) {
      data?.title && setTitle(data?.title);
      data?.subTitle && setSubtitle(data?.subTitle);
      data?.description && setDescription(data?.description);
      data?.description2 && setDescription2(data?.description2);
      data?.url && setUrl(data?.url);
      data?.image && setSelectedImage(data?.image);
      data?.secName && setSecName(data?.secName);
    }
    return () => {
      emptyStates();
    };
  }, [data]);

  //   handleSelectImage
  const handleSelectImage = (event, fileState, imagestate) => {
    fileState(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      imagestate(event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  // handleMakeData
  const handleMakeData = async (e) => {
    e.preventDefault();
    //   validate Image
    if (
      !data &&
      [
        "solution-in-one-app",
        "app-store-links",
        "explore-by-category",
        "latest-update-about-covid19",
        "social-link",
      ].includes(secName) &&
      !selectedImageFile
    ) {
      return toast.warn("Please select an image.");
    }

    // add params conditionally
    const params = {
      ...([
        "solution-in-one-app",
        "how-can-daweeye-help",
        "vision-and-mission-analysis-1",
        "vision-and-mission-analysis-2",
        "products-and-service",
        "business-strategies-1",
        "four-easy-steps-to-find-your-solution",
        "explore-by-category",
        "transmission-of-covid19",
        "latest-update-about-covid19",
        "working-for-your-better-health",
      ].includes(secName) && { title }),
      ...(["what-people-say-about-us"].includes(secName) && {
        subTitle: subtitle,
      }),
      ...([
        "solution-in-one-app",
        "how-can-daweeye-help",
        "what-people-say-about-us",
        "vision-and-mission-analysis-1",
        "vision-and-mission-analysis-2",
        "problem-worth-solving",
        "unique-selling-proposition",
        "business-strategies-2",
        "four-easy-steps-to-find-your-solution",
        "transmission-of-covid19",
        "latest-update-about-covid19",
        "working-for-your-better-health",
      ].includes(secName) && { description }),
      ...(["problem-worth-solving"].includes(secName) && {
        description2,
      }),
      ...(["app-store-links", "social-link"].includes(secName) && { url }),
      secName,
      ...(selectedImageFile && { image: selectedImageFile }),
    };

    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );
    await onUpdate(params, emptyStates);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color text-left">
          {data ? "Edit" : "Add"} Page Section
        </span>
        {/* title */}
        {[
          "solution-in-one-app",
          "how-can-daweeye-help",
          "vision-and-mission-analysis-1",
          "vision-and-mission-analysis-2",
          "products-and-service",
          "business-strategies-1",
          "four-easy-steps-to-find-your-solution",
          "explore-by-category",
          "transmission-of-covid19",
          "latest-update-about-covid19",
          "working-for-your-better-health",
        ].includes(secName) && (
          <div data-validate="Name is required" className="mb-3">
            <span className="label-input100">Title</span>
            <input
              className="add-evidence-modal-input full"
              type="text"
              name="specialization-text"
              placeholder="Title here"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        )}
        {/* subTitle */}
        {["what-people-say-about-us"].includes(secName) && (
          <div data-validate="Name is required" className="mb-3">
            <span className="label-input100">Sub-Title</span>
            <input
              className="add-evidence-modal-input full"
              type="text"
              name="specialization-text"
              placeholder="Subtitle here"
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value);
              }}
            />
          </div>
        )}
        {/* Description */}
        {[
          "solution-in-one-app",
          "how-can-daweeye-help",
          "what-people-say-about-us",
          "vision-and-mission-analysis-1",
          "vision-and-mission-analysis-2",
          "problem-worth-solving",
          "unique-selling-proposition",
          "business-strategies-2",
          "four-easy-steps-to-find-your-solution",
          "transmission-of-covid19",
          "latest-update-about-covid19",
          "working-for-your-better-health",
        ].includes(secName) && (
          <div data-validate="Description is required" className="mb-3">
            <span className="label-input100">Description</span>
            <input
              className="add-evidence-modal-input full"
              type="text"
              name="specialization-text"
              placeholder="Description here"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        )}
        {/* Description2 */}
        {["problem-worth-solving"].includes(secName) && (
          <div data-validate="Description is required" className="mb-3">
            <span className="label-input100">Long Description</span>
            <input
              className="add-evidence-modal-input full"
              type="text"
              name="specialization-text"
              placeholder="Description 2 here"
              value={description2}
              onChange={(e) => {
                setDescription2(e.target.value);
              }}
            />
          </div>
        )}
        {/* URL */}
        {["app-store-links", "social-link"].includes(secName) && (
          <div data-validate="Url is required" className="mb-3">
            <span className="label-input100">Url</span>
            <input
              className="add-evidence-modal-input full"
              type="text"
              name="specialization-text"
              placeholder="Url here"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
            />
          </div>
        )}
        {/* Image */}
        {[
          "solution-in-one-app",
          "app-store-links",
          "explore-by-category",
          "latest-update-about-covid19",
          "social-link",
        ].includes(secName) && (
          <>
            <div class="form-group" className="crud__ImagePicker">
              <input
                type="file"
                name="uploadfile"
                id="image"
                style={{
                  display: "none",
                }}
                onChange={(event) => {
                  handleSelectImage(
                    event,
                    setSelectedImageFile,
                    setSelectedImage
                  );
                }}
              />
              <label
                for="exampleInputEmail1"
                className="label-input100"
                style={{ margin: "10px 0" }}
              >
                Select Image
              </label>
              <label
                for="image"
                className="btn btn-primary btn-lg btn-block mb-3"
                style={{
                  backgroundColor: "green",
                }}
                onChange={(e) => {
                  console.log(e.target.files[0]);
                }}
              >
                {selectedImage ? "Change selection" : "Pick an Image"}
              </label>
            </div>

            {/* Shoe Image */}
            {selectedImage && (
              <div>
                <img
                  src={
                    selectedImageFile == null
                      ? `${imageUrl}${selectedImage}`
                      : selectedImage
                  }
                  className="crud-modal-image"
                />
              </div>
            )}
          </>
        )}

        {/* radio */}
        <FormControl component="fieldset" style={{ margin: "0 57px" }}>
          <RadioGroup
            row
            aria-label="status"
            name="controlled-radio-buttons-group"
            value={secName}
            onChange={(e) => setSecName(e.target.value)}
          >
            <FormControlLabel
              value="solution-in-one-app"
              control={<Radio />}
              label="Solution in one app"
            />
            <FormControlLabel
              value="how-can-daweeye-help"
              control={<Radio />}
              label="How can daweeye help"
            />
            <FormControlLabel
              value="our-services"
              control={<Radio />}
              label="Our Services"
            />
            <FormControlLabel
              value="app-store-links"
              control={<Radio />}
              label="App store links"
            />
            <FormControlLabel
              value="what-people-say-about-us"
              control={<Radio />}
              label="What people say about us"
            />
            <FormControlLabel
              value="vision-and-mission-analysis-1"
              control={<Radio />}
              label="Vision and mission analysis 1"
            />
            <FormControlLabel
              value="vision-and-mission-analysis-2"
              control={<Radio />}
              label="Vision and mission analysis 2"
            />
            <FormControlLabel
              value="problem-worth-solving"
              control={<Radio />}
              label="Problem worth solving"
            />
            <FormControlLabel
              value="products-and-service"
              control={<Radio />}
              label="Products and service"
            />
            <FormControlLabel
              value="unique-selling-proposition"
              control={<Radio />}
              label="Unique selling proposition"
            />
            <FormControlLabel
              value="business-strategies-1"
              control={<Radio />}
              label="Business strategies 1"
            />
            <FormControlLabel
              value="business-strategies-2"
              control={<Radio />}
              label="Business strategies 2"
            />
            <FormControlLabel
              value="four-easy-steps-to-find-your-solution"
              control={<Radio />}
              label="four easy steps to find your solution"
            />
            <FormControlLabel
              value="explore-by-category"
              control={<Radio />}
              label="explore by category"
            />
            <FormControlLabel
              value="transmission-of-covid19"
              control={<Radio />}
              label="Transmission of covid19"
            />
            <FormControlLabel
              value="latest-update-about-covid19"
              control={<Radio />}
              label="Latest update about covid19"
            />
            <FormControlLabel
              value="working-for-your-better-health"
              control={<Radio />}
              label="Working for your better health"
            />
            <FormControlLabel
              value="social-link"
              control={<Radio />}
              label="Social link"
            />
          </RadioGroup>
        </FormControl>

        {/* btn */}
        {secName !== "" && (
          <div class="container-login100-form-btn">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button
                disabled={isLoading}
                class="custom-btn login100-form-btn"
                onClick={handleMakeData}
              >
                {isLoading ? "Please Wait..." : !data ? "Add" : "Edit"}
              </button>
            </div>
          </div>
        )}
      </form>
    </ModalSkeleton>
  );
};

export default AddOrUpdatePageSectionModal;
