import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import AreYouSureModal from "../components/AreYouSureModal";
import ReasonModal from "../components/ReasonModal";
import { underProducation } from "../constant/imagePath";
import PaginationComponent from "../components/PaginationComponent/index";
import { Col, Container, Row } from "react-bootstrap";

// Mui Button

const Withdrawals = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [transactionId, setTransactionId] = useState("");
  const [withdrawType, setWithdrawType] = useState("withdraw");
  const [isReject, setIsReject] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isRejectAddReason, setIsRejectAddReason] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");

  const getAllWithDrawals = async (pageNo, newLimit, type) => {
    setIsGetting(true);
    const url = BaseURL(
      `users/withdrawals?status=${type}&page=${pageNo}&limit=${newLimit}`
    );

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      const totAmount = responseData?.data?.data?.reduce((data, next) => {
        return data + next?.amount;
      }, 0);
      const withdrawalAmount = responseData?.data?.data?.reduce(
        (data, next) => {
          const commission = (next?.amount * next?.commission) / 100;
          return data + next?.amount - commission;
        },
        0
      );
      setLimit(responseData?.data?.totalRecords);
      setWithdrawalData(responseData?.data?.data);
      setTotalAmount(totAmount);
      setTotalWithdrawal(withdrawalAmount);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const acceptOrRejectWithdrawRequest = async (reason) => {
    const url = BaseURL(`users/withdraw-action/${transactionId}`);

    const params = {
      accept: isAccept ? true : false,
      ...(isReject && { reason: reason }),
    };

    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      isAccept && setIsAccept(false);
      toast.success(`Withdraw request ${isAccept ? "accepted" : "rejected"}`);
      getAllWithDrawals(1, recordsLimit, withdrawType);
      return;
    }
  };

  useEffect(() => {
    getAllWithDrawals(1, recordsLimit, withdrawType);
  }, []);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllWithDrawals(page, recordsLimit);
  };

  const reasonFun = (inputVal) => {
    acceptOrRejectWithdrawRequest(inputVal);
    setIsRejectAddReason(false);
    setIsReject(false);
  };

  return (
    <>
      <div className="fieldSetHeaderContainer d-flex flex-column mt-5">
        <h1>WITHDRAW REQUEST</h1>
      </div>
      <div className="fieldSetMainContainer mt-5">
        <div className="place-add-btn-container mb-4">
          {/* <div className="d-flex align-items-center">
            <p className="m-0 me-3 fw-bold">Items Per Page:</p>
            <select
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPageNumber(1);
                getAllWithDrawals(1, e.target.value, withdrawType);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={10} className="pt-2">
                10
              </option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div> */}
          <div className="d-flex">
            <div className="d-flex align-items-center ms-3">
              <p className="m-0 me-3 fw-bold">Status:</p>
              <select
                value={withdrawType}
                onChange={(e) => {
                  setWithdrawType(e.target.value);
                  setPageNumber(1);
                  getAllWithDrawals(1, recordsLimit, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={"withdraw"}>Withdraw</option>
                <option value={"accepted"}>Accepted</option>
                <option value={"rejected"}>Rejected</option>
              </select>
            </div>
          </div>
        </div>
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Account No</th>
                    <th>Amount</th>
                    <th>Commission</th>
                    <th>Amount To Pay</th>
                    <th>Status</th>
                    <th>Description</th>
                    <th>Reason</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {withdrawalData.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan={9}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {withdrawalData?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>
                            {item?.doctorId
                              ? item?.doctorId?.email
                              : item?.covid19Id?.email}
                          </td>
                          <td>
                            {item?.doctorId
                              ? item?.doctorId?.displayName
                              : item?.covid19Id?.displayName}
                          </td>
                          <td>
                            {item?.doctorId
                              ? item?.doctorId?.userWallet?.accountNo
                              : item?.covid19Id?.userWallet?.accountNo}
                          </td>
                          <td>
                            {item?.amount} <strong>SLSH</strong>
                          </td>
                          <td>{item?.commission}%</td>
                          <td>
                            {/* {(item.amount * item?.commission) / 100} */}
                            {item.amount -
                              (item.amount * item?.commission) / 100}
                            <strong> SLSH</strong>
                          </td>
                          <td>{item?.status}</td>
                          <td>{item?.message}</td>
                          <td>{item?.reason || "-"}</td>
                          <td>
                            {item?.status === "withdraw" ? (
                              <ul className="d-flex">
                                <li className="page-item align-self-end mr-4">
                                  <Button
                                    className="update-btn me-2"
                                    onClick={() => {
                                      setIsAccept(true);
                                      setTransactionId(item._id);
                                    }}
                                  >
                                    Accept
                                  </Button>
                                </li>
                                <li className="page-item align-self-end ml-4">
                                  <Button
                                    className="update-btn me-2"
                                    onClick={() => {
                                      setIsReject(true);
                                      setTransactionId(item._id);
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </li>
                              </ul>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  {withdrawalData.length > 0 && (
                    <tr>
                      <td colSpan={4}></td>
                      <td colSpan={2}>
                        <div className="payment-amount">
                          Total:{" "}
                          <strong className="mx-1">
                            {" "}
                            {totalAmount ? totalAmount : 0} SLSH
                          </strong>
                        </div>
                      </td>
                      <td colSpan={5}>
                        {withdrawType == "accepted" && (
                          <div className="payment-amount">
                            Total:{" "}
                            <strong className="mx-1">
                              {" "}
                              {totalWithdrawal ? totalWithdrawal : 0} SLSH
                            </strong>
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <Col md={12} className="paginationContainer">
          {withdrawalData?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      {isAccept && (
        <AreYouSureModal
          open={isAccept}
          setOpen={setIsAccept}
          label={"Are You Sure?"}
          message={`Do you really want to accept this request?`}
          handleYes={() => acceptOrRejectWithdrawRequest()}
        />
      )}
      {isReject && (
        <AreYouSureModal
          open={isReject}
          setOpen={setIsReject}
          isRejectAddReason={isRejectAddReason}
          setIsRejectAddReason={setIsRejectAddReason}
          label={"Are You Sure?"}
          message={`Do you really want to reject this request?`}
          handleYes={() => setIsRejectAddReason(true)}
        />
      )}
      {isRejectAddReason && (
        <ReasonModal
          open={isRejectAddReason}
          setOpen={setIsRejectAddReason}
          label={"Reject Reason"}
          reasonFun={reasonFun}
        />
      )}
    </>
  );
};

export default Withdrawals;
