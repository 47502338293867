import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
// import { ImFileVideo } from "react-icons/im";
import { Row, Col } from "react-bootstrap";
// import Img1 from "../assets/images/caro6.png";
// import Img2 from "../assets/images/vendor_face.png";
// import PDF from "../assets/images/sample.pdf";
import moment from "moment";
import { imageUrl } from "../Config/apiUrl";

const ViewOrderDetails = ({ open, setOpen, data }) => {
  let [userName] = useState(data?.shipmentDetails?.name);
  let [email] = useState(data?.shipmentDetails?.email);
  let [phoneNumber] = useState(data?.shipmentDetails?.phoneNumber);
  let [shipmentAddress] = useState(data?.shipmentDetails?.address);
  let [shipmentNumber] = useState(data?.shipmentDetails?.shipmentNumber);
  let [zipCode] = useState(data?.shipmentDetails?.zipCode);
  let [orderedItems] = useState(data?.items);

  return (
    <ModalSkeleton open={open} setOpen={setOpen} type="orderDetailsModal">
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color pb-4">
          Order Details
        </span>
        <Row className="mt-3">
          <span className="font-17 fw-bold mb-2">Product Details:</span>
          <Col md={12}>
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody>
                  {orderedItems?.length > 0 &&
                    orderedItems?.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.productId?.name}</td>
                            <td>
                              <div className="category-icon-box">
                                {" "}
                                {item?.productId?.image && (
                                  <img
                                    src={imageUrl + item?.productId?.image}
                                    className="w-100 h-100"
                                  />
                                )}
                              </div>
                            </td>
                            <td>{item?.price} <strong>SLSH</strong></td>
                            <td>{item?.quantity}</td>
                            <td>{item?.totalPrice} <strong>SLSH</strong></td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <span className="font-17 fw-bold mb-2">Shipping Details:</span>
          <Col md={7}>
            <div>
              <span className="font-17 fw-bold">User Name:</span>
              <p className="font-14 word-break">{userName}</p>
            </div>
          </Col>
          <Col md={5}>
            <div>
              <span className="font-17 fw-bold">Email:</span>
              <p className="font-14 word-break">{email}</p>
            </div>
          </Col>
          <Col md={7}>
            <div>
              <span className="font-17 fw-bold">Phone Number:</span>
              <p className="font-14">{phoneNumber}</p>
            </div>
          </Col>
          <Col md={5}>
            <div>
              <span className="font-17 fw-bold">Shipping Number:</span>
              <p className="font-14">{shipmentNumber}</p>
            </div>
          </Col>
          <Col md={7}>
            <div>
              <span className="font-17 fw-bold">Shipping Address:</span>
              <p className="font-14 word-break" >{shipmentAddress}</p>
            </div>
          </Col>
          <Col md={5}>
            <div>
              <span className="font-17 fw-bold">Zip Code:</span>
              <p className="font-14">{zipCode}</p>
            </div>
          </Col>
        </Row>
      </form>
    </ModalSkeleton>
  );
};

export default ViewOrderDetails;
