import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
// import { ImFileVideo } from "react-icons/im";
import { Row, Col } from "react-bootstrap";
// import Img1 from "../assets/images/caro6.png";
// import Img2 from "../assets/images/vendor_face.png";
// import PDF from "../assets/images/sample.pdf";
import moment from "moment";
import { imageUrl } from "../Config/apiUrl";

const ViewAppointmentErrorModal = ({ open, setOpen, data }) => {
  const [name] = useState(data?.patientId?.displayName);
  const [completePhoneNumber] = useState(data?.patientId?.completePhoneNumber);
  const [appointmentWith] = useState(data?.appointmentWith);
  const [doctorEmail] = useState(data?.doctorId?.email);
  const [covid19Email] = useState(data?.covid19Id?.email);
  const [tokenNumber] = useState(data?.appointmentId?.tokenNumber);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color pb-4">
          Appointment Error Details
        </span>
        <Row>
          <Col md={7}>
            <div>
              <span className="font-17 fw-bold">Name:</span>
              <p className="font-14">{name}</p>
            </div>
          </Col>
          <Col md={5}>
            <div>
              <span className="font-17 fw-bold">Phone Number:</span>
              <p className="font-14">{completePhoneNumber}</p>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={7}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">AppointmentWith:</span>
              <span className="font-14">{appointmentWith}</span>
            </div>
          </Col>
          <Col md={5}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">
                {appointmentWith == "doctor" ? "Doctor" : "Lab"} Email:
              </span>
              <span className="font-14">
                {appointmentWith == "doctor" ? doctorEmail : covid19Email}
              </span>
            </div>
          </Col>
        </Row>
        {tokenNumber && (
          <Row className="mt-3">
            <Col md={7}>
              <div className="d-flex flex-column">
                <span className="font-17 fw-bold">Appointment Number:</span>
                <span className="font-14">{tokenNumber}</span>
              </div>
            </Col>
            {/* <Col md={5}>
            <div className="d-flex flex-column">
            <span className="font-17 fw-bold">Doctor Email:</span>
            <span className="font-14">{doctorEmail}</span>
            </div>
          </Col> */}
          </Row>
        )}
      </form>
    </ModalSkeleton>
  );
};

export default ViewAppointmentErrorModal;
