export const apiUrl = "https://daweeye-backend-demo-4597c6ea9ae4.herokuapp.com"; // live
// export const apiUrl = "https://3239-103-244-178-85.ap.ngrok.io"; // local
export const imageUrl = `${apiUrl}/api/images/`;
export const socketURL = `${apiUrl}`;
const moment = require("moment");

export const recordsLimit = 10;

export const BaseURL = (link) => {
  return `${apiUrl}/api/${link}`;
};

export const removeApplicationData = () => {
  if (window) {
    // NextJS is ServerSideRendering, therefore the window-check.
    localStorage.clear();
  }
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const urlToObject = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], "image.png", { type: blob.type });
    return file;
  } catch (err) {
    console.log("URL To Object", err);
  }
};

export const sorter = {
  // "sunday": 0, // << if sunday is first day of week
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const getDate = (dayINeed) => {
  const today = moment().isoWeekday();
  if (today <= dayINeed) {
    // then just give me this week's instance of that day
    return moment().isoWeekday(dayINeed).format();
  } else {
    // otherwise, give me next week's instance of that same day
    return moment().add(1, "weeks").isoWeekday(dayINeed).format();
  }
};

export const groupArrayOfObjects = (list, key) => {
  return list.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";
