import React, { useState } from "react";
import { toast } from "react-toastify";

import ModalSkeleton from "./ModalSkeleton";

const ReasonModal = ({
  open,
  setOpen,
  label,
  reasonFun,
  //   message,
  //   handleYes,
  isLoading,
}) => {
  const [reason, setReason] = useState("");

  const func = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <div>
        <form className="login100-form validate-form">
          <span className="login100-form-title are-you-sure-label">
            {label}
          </span>
          <input
            type="text"
            style={{
              border: "1px solid black",
              padding: 10,
              width: "100%",
              marginBottom: "10px",
            }}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <button
            // disabled={isLoading}
            className="login100-form-btn btn-color"
            onClick={(e) => {
              e.preventDefault();
              if (!reason || reason == "")
                return toast.warning("Please enter a reason for this action");

              reasonFun(reason);
            }}
          >
            {isLoading ? "Please Wait..." : " Submit"}
          </button>
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default ReasonModal;
