import { UpdateRounded } from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";

const AddCovid19ServicesModal = ({ open, setOpen, onUpdate, type, data }) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [serviceName, setServiceName] = useState(data?.serviceName);
  const [servicePrice, setServicePrice] = useState(data?.servicePrice);
  const [serviceId] = useState(data?._id);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title text-color text-left">
          {type == "Edit" ? "Edit" : "Add"} Covid19 Service
        </span>
        <div data-validate="Service Name is required">
          <span className="label-input100">Service Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="service-name"
            placeholder="Service Name"
            value={serviceName}
            onChange={(e) => {
              setServiceName(e.target.value);
            }}
          />
        </div>

        <div data-validate="Service Price is required" className="mt-3">
          <span className="label-input100">Service Price</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="service-price"
            placeholder="Service Price"
            value={servicePrice}
            onChange={(e) => {
              setServicePrice(e.target.value);
            }}
          />
        </div>

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isApiCall}
              class="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                const data = {
                  _id: serviceId,
                  serviceName,
                  servicePrice: +servicePrice,
                };
                type == "Add" && delete data._id;
                onUpdate(data);
              }}
            >
              {type == "Edit" ? "Edit" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddCovid19ServicesModal;
