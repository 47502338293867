import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { BiAddToQueue } from "react-icons/bi";

const AddImageComponent = ({
  index,
  item,
  onSubmit,
  initialState,
  isAddEmpty,
}) => {
  const inputRef = useRef(null);
  const [imageArray, setImageArray] = useState(initialState || [{}, {}, {}]);
  const [image, setImage] = useState({});

  useEffect(() => {
    setImageArray(initialState);
  }, [initialState]);

  function getImage() {
    inputRef.current.click();
  }

  const handleImageSubmit = (e) => {
    let newData;
    if (index == null) {
      newData = e.target.files[0];
      setImage(e.target.files[0]);
    } else {
      newData = imageArray.slice();
      newData.splice(index, 1, e.target.files[0]);
      setImageArray(newData);
    }
    onSubmit(newData);
  };

  const handleDeleteImage = () => {
    let newData;
    if (index == null) {
      newData = {};
      setImage({});
    } else {
      newData = imageArray.slice();
      newData.splice(index, 1, {});
      setImageArray(newData);
    }
    onSubmit(newData);
  };

  const handleAddNewImage = () => {
    let newData;
    if (index == null && isAddEmpty == false) {
      newData = {};
      setImage({});
    } else {
      newData = imageArray.slice();
      newData.push({});
      setImageArray(newData);
    }
    onSubmit(newData);
  };

  return (
    <>
      {isAddEmpty == true ? (
        <div
          className="custom-image-upload  mb-3 d-flex justify-content-center align-items-center"
          onClick={() => handleAddNewImage()}
        >
          <div className="text-center">
            <BiAddToQueue size={25} />
          </div>
        </div>
      ) : item?.name ? (
        <div
          className="custom-image-upload position-relative  mb-2"
          onClick={() => getImage()}
        >
          <IoMdClose
            size={15}
            className="position-absolute btn-color text-white"
            onClick={() => {
              handleDeleteImage();
            }}
            style={{ top: "5px", right: "5px", borderRadius: "3px" }}
          />
          <img src={URL.createObjectURL(item)} className="w-100 h-100" />
        </div>
      ) : (
        <div
          className="custom-image-upload  mb-3 d-flex justify-content-center align-items-center"
          onClick={() => getImage()}
        >
          <div className="text-center">
            <AiOutlineCamera size={25} />
            <p className="upload-image-title mt-2">Upload Image</p>
          </div>
        </div>
      )}
      <input
        type="file"
        className="d-none"
        ref={inputRef}
        onChange={(e) => {
          handleImageSubmit(e);
        }}
      />
    </>
  );
};

export default AddImageComponent;
