import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  SET_SOCKET,
  UPDATE_USER,
} from "./actionType";

export function saveLoginUserData(response) {
  return { type: LOGIN_SUCCESS, payload: response };
}

export function signOutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function setSocketConnection(response) {
  return {
    type: SET_SOCKET,
    payload: response,
  };
}

export function updateUser(response) {
  return {
    type: UPDATE_USER,
    payload: response,
  };
}
