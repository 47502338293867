import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
// import { ImFileVideo } from "react-icons/im";
import { Row, Col } from "react-bootstrap";
// import Img1 from "../assets/images/caro6.png";
// import Img2 from "../assets/images/vendor_face.png";
// import PDF from "../assets/images/sample.pdf";
import moment from "moment";
import { imageUrl } from "../Config/apiUrl";

const ViewCovidLabModal = ({ open, setOpen, data }) => {
  const [ownerName] = useState(data?.ownerName);
  const [labAddress] = useState(data?.labAddress);
  const [licenseNumber] = useState(data?.licenseNumber);
  const [labWorkingHours] = useState(data?.labWorkingHours);
  // const [labWorkingDays] = useState(data?.labWorkingDays);
  const [city] = useState(data?.city);
  const [shortBio] = useState(data?.shortBio);
  let [covid19Services] = useState(data?.covid19Services);
  let [photo] = useState(data?.photo);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color pb-4">
          Covid Lab Details
        </span>
        <Row>
          <Col md={8}>
            <div>
              <span className="font-17 fw-bold">Owner Name:</span>
              <p className="font-14">{ownerName}</p>
            </div>
          </Col>
          <Col md={4}>
            <div>
              <span className="font-17 fw-bold">Lab Address:</span>
              <p className="font-14">{labAddress}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">License Number:</span>
              <span className="font-14">{licenseNumber}</span>
            </div>
          </Col>
          <Col md={4}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">Work Hours:</span>
              <span className="font-14">{`${moment(
                labWorkingHours.startTime
              ).format("hh:mm A")} - ${moment(labWorkingHours.endTime).format(
                "hh:mm A"
              )}`}</span>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <span className="font-17 fw-bold mb-2">Covid19 Services:</span>
          <Col md={12}>
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Service Charges</th>
                  </tr>
                </thead>

                <tbody>
                  {covid19Services.length > 0 &&
                    covid19Services?.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{item?.serviceName}</td>
                            <td>
                              {item?.servicePrice} <strong>SLSH</strong>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={8}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">Description:</span>
              <span className="font-14">{shortBio}</span>
            </div>
          </Col>
          <Col md={4}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">City:</span>
              <span className="font-14">{city}</span>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <div className="">
              <span className="font-17 fw-bold">Lab Image:</span>
              <div style={{ width: "110px", height: "110px" }}>
                {" "}
                <img
                  src={imageUrl + photo}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </ModalSkeleton>
  );
};

export default ViewCovidLabModal;
