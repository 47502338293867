import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get, Post, Delete, Patch } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, imageUrl, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import UpdateCovidLabModal from "../components/UpdateCovidLabModal";
import ViewOrderDetails from "../components/ViewOrderDetails";
import { DatePicker } from "../components/DatePicker/index";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../components/PaginationComponent/index";
import { toast } from "react-toastify";

function Orders() {
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [orders, setOrders] = useState([]);
  const [isGetting, setIsGetting] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [orderStatus, setOrderStatus] = useState("all");
  const [editingData, setEditingData] = useState();
  const [viewOrderDetails, setViewOrderDetails] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [search, setSearch] = useState("");

  const getAllOrders = async (pageNo, newLimit, status, search, from, to) => {
    setIsGetting(true);
    const url =
      search && search != ""
        ? BaseURL(
            `order/get-by-pharmacy?status=${status}&search=${search}&page=${pageNo}&limit=${newLimit}`
          )
        : from && to
        ? BaseURL(
            `order/get-by-pharmacy?status=${status}&from=${from}&to=${to}&page=${pageNo}&limit=${newLimit}`
          )
        : BaseURL(
            `order/get-by-pharmacy?status=${status}&page=${pageNo}&limit=${newLimit}`
          );

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      const totAmount = responseData?.data?.data?.reduce((data, next) => {
        return data + next?.estimatedTotal;
      }, 0);
      setLimit(responseData?.data?.totalRecords);
      setOrders(responseData?.data?.data);
      setTotalAmount(totAmount);
    }

    setIsGetting(false);
  };

  const completeOrder = async () => {
    const url = BaseURL(`order/${editingData}`);

    setIsApiCall(true);

    const responseData = await Patch(url, {}, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success(`Order completed successfully!`);
      getAllOrders(1, recordsLimit, orderStatus);
      setIsCompleting(false);
      return;
    }
    setIsApiCall(false);
  };

  const deleteOrder = async () => {
    const url = BaseURL(`order/${orderId}`);

    setIsApiCall(true);

    const responseData = await Delete(url, null, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success(`Order deleted successfully!`);
      getAllOrders(1, recordsLimit, orderStatus);
      setIsDeleting(false);
      return;
    }
    setIsApiCall(false);
  };

  const cancelOrder = async () => {
    const url = BaseURL(`order/cancel/${orderId}`);

    setIsApiCall(true);

    const responseData = await Patch(url, {}, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success(`Order cancelled successfully!`);
      getAllOrders(1, recordsLimit, orderStatus);
      setIsCancelling(false);
      return;
    }
    setIsApiCall(false);
  };

  useEffect(() => {
    getAllOrders(1, recordsLimit, orderStatus);
  }, []);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllOrders(page, recordsLimit, orderStatus);
  };

  const onSelectDate = (date) => {
    const from = date[0];
    const to = date[1] ? date[1] : null;
    getAllOrders(1, recordsLimit, orderStatus, search, from, to);
  };

  const handleSearch = () => {
    getAllOrders(1, recordsLimit, orderStatus, search);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>ORDERS</h1>
      </div>

      {/* Add Section Button Container */}

      <div className="fieldSetMainContainer">
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center">
            <p className="m-0 me-3 fw-bold">Please Choose Order Type:</p>
            <select
              value={orderStatus}
              onChange={(e) => {
                setOrderStatus(e.target.value);
                setPageNumber(1);
                getAllOrders(1, recordsLimit, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="cancelled">Cancelled</option>
              <option value="completed">Completed</option>
            </select>
          </div>
          <div className="d-flex align-items-center" style={{ width: "35%" }}>
            <div class="input-group">
              <input
                type={search}
                className="form-control"
                placeholder="Order id & Phone number"
                aria-label=" username"
                aria-describedby="basic-addon2"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div class="input-group-append">
                <button
                  className="custom-btn login100-form-btn mx-2"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center">
            <DatePicker onUpdate={onSelectDate} />
          </div>
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 mt-5 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Order Id</th>
                    <th>Prescription</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {orders?.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan={8}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {orders?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (pageNumber - 1) * recordsLimit}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            <div className="category-icon-box">
                              {" "}
                              {item?.prescriptionImg ? (
                                <img
                                  src={imageUrl + item?.prescriptionImg}
                                  className="w-100 h-100"
                                />
                              ) : (
                                "Not provided"
                              )}
                            </div>
                          </td>
                          <td>
                            {item?.estimatedTotal} <strong>SLSH</strong>
                          </td>
                          <td>{item?.status}</td>
                          <td>
                            {moment(item.createdAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item"
                                onClick={() => {
                                  setViewOrderDetails(item);
                                  setIsViewing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Details
                                </Button>
                              </li>
                              {item.status == "active" && (
                                <li
                                  className="page-item align-self-end"
                                  onClick={() => {
                                    setEditingData(item?._id);
                                    setIsCompleting(true);
                                    setIsApiCall(true);
                                  }}
                                >
                                  <Button className="update-btn me-2">
                                    Complete
                                  </Button>
                                </li>
                              )}
                              {item?.status === "active" && (
                                <li
                                  className="page-item"
                                  onClick={() => {
                                    setOrderId(item?._id);
                                    setIsCancelling(true);
                                  }}
                                >
                                  <Button className="delete-btn me-2">
                                    Cancel
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  {orders.length > 0 && (
                    <tr>
                      <td colSpan={3}></td>
                      <td colSpan={1}>
                        <div className="payment-amount">
                          Total Amount:{" "}
                          <strong className="">
                            {" "}
                            {totalAmount ? totalAmount : 0} SLSH
                          </strong>
                        </div>
                      </td>
                      <td colSpan={3} />
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
         <Col md={12} className="paginationContainer">
          {orders?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      {isCompleting && (
        <AreYouSureModal
          open={isCompleting}
          setOpen={setIsCompleting}
          label={"Are You Sure?"}
          message={`Do you really want to complete this order?`}
          handleYes={() => completeOrder()}
        />
      )}
      {isCancelling && (
        <AreYouSureModal
          open={isCancelling}
          setOpen={setIsCancelling}
          label={"Are You Sure?"}
          message={`Do you really want to cancel this order?`}
          handleYes={() => cancelOrder()}
        />
      )}
      {isDeleting && (
        <AreYouSureModal
          open={isDeleting}
          setOpen={setIsDeleting}
          label={"Are You Sure?"}
          message={`Do you really want to delete this order?`}
          handleYes={() => deleteOrder()}
        />
      )}
      {isViewing && (
        <ViewOrderDetails
          open={isViewing}
          setOpen={setIsViewing}
          data={viewOrderDetails}
        />
      )}
    </section>
  );
}

export default Orders;
