import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { BsTelephone, BsGenderAmbiguous, BsPlus } from "react-icons/bs";
import { CgMathEqual } from "react-icons/cg";
import moment from "moment";
import { BaseURL } from "../Config/apiUrl";

import {
  MdOutlineAccountBalance,
  MdEmail,
  MdLocationCity,
} from "react-icons/md";
import { FaBirthdayCake } from "react-icons/fa";
import { imageUrl, URL } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const Profile = () => {
  const history = useHistory();
  const [isGetting, setIsGetting] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [profileData, setProfileData] = useState(useLocation()?.state.item);
  const [schedule, setSchedule] = useState([]);
  let [newText, setnewText] = useState("about");
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const doctorOrCovidId = useLocation()?.state.item._id;
  const patientId = useLocation()?.state.patientId;
  const commissions = {
    commissionWithDoctor:
      useLocation()?.state.commissions[0]?.adminCommission?.rate,
    commissionWithCovid19:
      useLocation()?.state.commissions[1]?.adminCommission?.rate,
    commissionWithPatient:
      useLocation()?.state.commissions[2]?.adminCommission?.rate,
    wafiPayAmount: useLocation()?.state.commissions[3]?.adminCommission?.rate,
  };

  const [currentProfileId, setCurrentProfileId] = useState(null);

  let about = ` Lorem ipsum dolor sit amet, consectetur adipiscing
    elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis
    nostrud exercitation ullamco laboris nisi ut aliquip
    ex ea commodo consequat. Duis aute irure dolor in
    reprehenderit in voluptate velit esse cillum dolore eu
    fugiat nulla pariatur. Excepteur sint occaecat
    cupidatat non proident, sunt in culpa qui officia
    deserunt mollit anim id est laborum.`;

  const getUserProfile = async () => {
    setIsGetting(true);
    const url = BaseURL(`users/profile/${doctorOrCovidId}`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      //   setFinalScheduleFunc(responseData?.data?.schedule);
      //   setUserSchedule(responseData?.data?.schedule);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>{profileData.role == "doctor" ? "DOCTOR" : "COVID19"} DETAILS</h1>
      </div>
      <Container fluid className="dashboard">
        <Row className="">
          <Row>
            <Col lg={9} className="mt-5">
              <Row>
                <Col lg={6}>
                  {/* <div className=""> */}
                  <div className="doctor-detail-section1 ">
                    <div className="doctor-detail-imgBox">
                      <img
                        src={`${imageUrl}${
                          profileData?.photo == "default.jpg"
                            ? "/test/"
                            : profileData?.photo
                        }`}
                        onError={(e) => {
                          e.target.onerror = null;
                        }}
                      />
                    </div>
                    <div className="ms-3">
                      <h6>
                        {profileData?.firstName} {profileData?.lastName}
                      </h6>
                      <p>
                        {profileData?.doctorSpecialization?.length > 0
                          ? profileData?.doctorSpecialization[0]
                          : "Nil"}
                        <span
                          style={{
                            color: "#EF464B",
                            fontSize: "14px",
                            marginLeft: "10px",
                          }}
                        >
                          {/* {doctor?.pediatrist} */}
                          {/* 14 years experience */}
                        </span>
                      </p>

                      <p>
                        <LocationOnIcon
                          style={{ color: "#EF464B", fontSize: "14px" }}
                        />
                        {profileData?.role == "doctor"
                          ? profileData?.hospitalAddress
                            ? profileData?.hospitalAddress
                            : "Nil"
                          : profileData?.labAddress
                          ? profileData?.labAddress
                          : "Nil"}
                      </p>
                    </div>
                  </div>
                  {/* </div> */}
                </Col>
                <Col lg={3}>
                  <div className="profile-info-container  doctor-detail-sectionss">
                    <p>
                      <BsTelephone className="doctordetail-icon" />{" "}
                      <span>{`${profileData?.countryCode || "+252"} ${
                        profileData?.phoneNumber
                      }`}</span>
                    </p>
                    <p>
                      <MdOutlineAccountBalance className="doctordetail-icon" />{" "}
                      <span>{profileData?.country || "Nil"}</span>
                    </p>
                    <p>
                      <BsGenderAmbiguous className="doctordetail-icon" />{" "}
                      <span>{profileData?.gender || "Nil"}</span>
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className=" doctor-detail-sectionss">
                    <p>
                      <MdEmail className="doctordetail-icon" />{" "}
                      <span>{profileData?.email}</span>
                    </p>
                    <p>
                      <MdLocationCity className="doctordetail-icon" />{" "}
                      <span>{profileData?.city || "Nil"}</span>
                    </p>
                    <p>
                      <FaBirthdayCake className="doctordetail-icon" />{" "}
                      <span>
                        {moment(profileData?.dob).format("Do MMM YYYY")}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={2}></Col>
                {/* {profileData.role == "doctor" && ( */}
                {profileData.role == "doctor" && (
                  <>
                    <Col lg={5} className="mt-5">
                      <div className="doctorDetail-experience-card">
                        <h5 className="gray-color">Experience</h5>
                        <h4>{profileData?.totalExp || "Nil"} years</h4>
                      </div>
                    </Col>
                    <Col lg={5} className="mt-5">
                      <div className="doctorDetail-fees-card">
                        <div>
                          <h6 className="gray-color">Consultation Fee</h6>
                          <h5>
                            {parseFloat(profileData?.consultationFee).toFixed(
                              2
                            )}{" "}
                            SLSH
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col lg={2}></Col>
                <Col lg={10} className="mt-5">
                  <div className="doctor-detail-tabs-card">
                    {/* Tabs */}
                    <div className="doctor-detail-tabs-header">
                      <button
                        onClick={() => setnewText("about")}
                        className={`${newText === "about" ? "ActiveTab" : ""}`}
                      >
                        About
                      </button>
                      {profileData.role == "doctor" && (
                        <>
                          <button
                            onClick={() => setnewText("education")}
                            className={`${
                              newText === "education" ? "ActiveTab" : ""
                            }`}
                          >
                            Education
                          </button>
                          <button
                            onClick={() => setnewText("specialization")}
                            className={`${
                              newText === "specialization" ? "ActiveTab" : ""
                            }`}
                          >
                            Specialization
                          </button>
                        </>
                      )}
                      {/* <button
                          onClick={() => setnewText("services")}
                          className={`${
                            newText === "services" ? "ActiveTab" : ""
                          }`}
                        >
                          Services
                        </button> */}
                    </div>
                    {/* Tabs respective content */}
                    <div className="doctor-detail-tabs-text">
                      <p>
                        {newText === "about" &&
                          (profileData?.shortBio || about)}

                        {newText === "education" &&
                          profileData?.education?.length > 0 &&
                          profileData?.education.join(", ")}

                        {newText === "specialization" &&
                          profileData?.doctorSpecialization?.length > 0 &&
                          profileData?.doctorSpecialization.join(", ")}

                        {/* {newText === "services" && services} */}
                      </p>
                    </div>
                  </div>
                  <Row className="mb-4">
                    <Col>
                      {user?._id !== currentProfileId && (
                        <div className="MidLabBtn text-center ">
                          <button
                            className="custom-btn text-white"
                            onClick={() => {
                              history.push({
                                pathname: `/schedule`,
                                state: {
                                  schedule,
                                  consultationFee: profileData?.consultationFee,
                                  profileData,
                                  patientId,
                                  commissions,
                                },
                              });
                            }}
                          >
                            Book Appointment
                          </button>
                        </div>
                      )}

                      {user?._id == currentProfileId && (
                        <div className="MidLabBtn text-center">
                          <button
                            className="active"
                            onClick={() => history.goBack()}
                          >
                            Go Back
                          </button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
