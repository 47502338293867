import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import MuiButton from "@mui/material/Button";
import { BaseURL, apiHeader, imageUrl, recordsLimit } from "../Config/apiUrl";
import { IoMdAddCircleOutline } from "react-icons/io";
import AddProductModal from "../components/AddProductModal";
import UpdateProductModal from "../components/UpdateProductModal";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../components/PaginationComponent/index";
// Mui Button

const Products = () => {
  const history = useHistory();

  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState({});
  const [deletingId, setDeletingId] = useState("");
  const [productsData, setProductsData] = useState([]);
  const [categories, setCategories] = useState();
  const [search, setSearch] = useState("");

  // loading states
  const [isAddingApiCall, setIsAddingApiCall] = useState(false);
  const [isEditingApiCall, setIsEditingApiCall] = useState(false);
  const [isDeletingApiCall, setIsDeletingApiCall] = useState(false);

  // getAllProducts
  const getAllProducts = async (pageNo, newLimit, search) => {
    setIsGetting(true);
    const url = search
      ? BaseURL(
          `product/get-by-admin?search=${search}&page=${pageNo}&limit=${newLimit}`
        )
      : BaseURL(`product/get-by-admin?page=${pageNo}&limit=${newLimit}`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setLimit(responseData?.data?.totalRecords);
      setProductsData(responseData?.data?.data);
    }
    setIsGetting(false);
  };

  const getAllCategories = async () => {
    const url = BaseURL(`category/`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setCategories(responseData?.data?.data);
    }
  };

  const addProduct = async (name, catId, flavor, size, price, stock, icon) => {
    const url = BaseURL(`product/add`);
    const params = new FormData();
    params.append("name", name);
    params.append("catId", catId);
    params.append("flavor", flavor);
    params.append("size", size);
    params.append("price", price);
    params.append("stock", stock);
    icon.name && params.append("image", icon);

    setIsAddingApiCall(true);
    const responseData = await Post(url, params, apiHeader(accessToken, true));
    setIsAddingApiCall(false);
    if (responseData !== undefined) {
      setIsAdding(false);
      setProductsData((p) => [responseData?.data?.data, ...p]);
      toast.success("Product Added Successfully!");
    }
  };

  // update category
  const updateProduct = async (
    name,
    catId,
    flavor,
    size,
    price,
    stock,
    icon
  ) => {
    const url = BaseURL(`product/${editingData?.id}`);
    const params = new FormData();
    params.append("name", name);
    params.append("catId", catId);
    params.append("flavor", flavor);
    params.append("size", size);
    params.append("price", price);
    params.append("stock", stock);
    icon.name && params.append("image", icon);

    setIsEditingApiCall(true);
    const responseData = await Patch(url, params, apiHeader(accessToken, true));
    setIsEditingApiCall(false);
    if (responseData !== undefined) {
      setIsEditing(false);
      const newData = [...productsData];
      newData.splice(
        newData.findIndex((val) => val?.id == editingData?.id),
        1,
        responseData?.data?.data
      );
      setProductsData(newData);
      toast.success("Product Updated Successfully!");
    }
  };

  // delete category
  const deleteProduct = async () => {
    const url = BaseURL(`product/${deletingId}`);

    setIsDeletingApiCall(true);
    const responseData = await Delete(url, null, apiHeader(accessToken));
    setIsDeletingApiCall(false);
    if (responseData !== undefined) {
      setIsDeleting(false);
      const newData = [...productsData];
      newData.splice(
        newData.findIndex((val) => val?.id == deletingId),
        1
      );
      setProductsData(newData);
      toast.success("Product Deleted Successfully!");
    }
  };

  const handleSearch = () => {
    getAllProducts(1, recordsLimit, search);
  };

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllProducts(page, recordsLimit);
  };

  useEffect(() => {
    getAllProducts(1, recordsLimit);
    getAllCategories();
  }, []);

  return (
    <section className="reported-ads pb-4">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>PRODUCTS</h1>
      </div>

      <div className="fieldSetMainContainer">
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center" style={{ width: "35%" }}>
            <div class="input-group">
              <input
                type={search}
                className="form-control"
                placeholder="Name"
                aria-label=" username"
                aria-describedby="basic-addon2"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div class="input-group-append">
                <button
                  className="custom-btn login100-form-btn mx-2"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <MuiButton
              onClick={() => setIsAdding(true)}
              variant="contained"
              color="primary"
              className="custom-btn rounded-lg radius-8"
              startIcon={<IoMdAddCircleOutline />}
            >
              Add
            </MuiButton>
          </div>
        </div>
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <>
            <div className="col-md-12 table_recent">
              <div className="table table-responsive">
                <table id="dataTable" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Stock</th>
                      <th>Price</th>
                      {/* <th>Last Updated</th> */}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsData.length < 1 && (
                      <tr className="no-data-table-row">
                        <td colSpan={6}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                    {productsData?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>
                              {index + 1 + (pageNumber - 1) * recordsLimit}
                            </td>
                            <td>
                              <div className="category-icon-box">
                                {" "}
                                <img
                                  src={imageUrl + item?.image}
                                  className="w-100 h-100"
                                />
                              </div>
                            </td>
                            <td>{item?.name}</td>
                            <td>{item?.stock}</td>
                            <td>
                              {item?.price} <strong>SLSH</strong>
                            </td>
                            {/* <td>{item?.type}</td> */}
                            {/* <td> */}
                            {/* <ul className="list-unstyled">
                                {item?.subCat.map((subData, index) => {
                                  return (
                                    <li>{`${++index}) ${
                                      subData?.sub_catName
                                    }`}</li>
                                  );
                                })}
                              </ul> */}
                            {/* </td> */}
                            {/* <td>
                              {moment(item?.updatedAt).format(
                                "ddd, DD MMM YYYY hh:mm A"
                              )}
                            </td> */}

                            <td>
                              <ul className="d-flex list-unstyled justify-content-center">
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={() => {
                                    setEditingData(item);
                                    setIsEditing(true);
                                  }}
                                >
                                  <Button className="update-btn">Edit</Button>
                                </li>
                                <li
                                  className="page-item align-self-end ml-4"
                                  onClick={() => {
                                    setDeletingId(item?.id);
                                    setIsDeleting(true);
                                  }}
                                >
                                  <Button className="delete-btn">Delete</Button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        <Col md={12} className="paginationContainer">
          {productsData?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      {/* AreYouSureModal */}
      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message="Do you really want to delete this product?"
        handleYes={() => deleteProduct()}
        isLoading={isDeletingApiCall}
      />
      {isAdding && (
        <AddProductModal
          open={isAdding}
          setOpen={setIsAdding}
          onUpdate={addProduct}
          isLoading={isAddingApiCall}
          categories={categories}
        />
      )}

      {isEditing && (
        <UpdateProductModal
          open={isEditing}
          setOpen={setIsEditing}
          onUpdate={updateProduct}
          data={editingData}
          isLoading={isEditingApiCall}
          categories={categories}
        />
      )}
    </section>
  );
};

export default Products;
