import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
import { IoMdClose } from "react-icons/io";
import { imageUrl } from "../Config/apiUrl";
import fallbackIcon from "../assets/images/fallbackicon.png";
import AddImageComponent from "./AddImageComponent";
import { urlToObject } from "../Config/apiUrl";
import { Row, Col } from "react-bootstrap";

const UpdatePharmacy = ({ open, setOpen, onUpdate, isLoading, data }) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [firstName, setFirstName] = useState(data?.firstName);
  const [lastName, setLastName] = useState(data?.lastName);
  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber);
  const [countryCode, setCountryCode] = useState(data?.countryCode);
  const [city, setCity] = useState(data?.city);
  const [address, setAddress] = useState(data?.address);
  const [shortBio, setShortBio] = useState(data?.shortBio);
  const [icon, setIcon] = useState(data?.photo);
  const [loading, setLoading] = useState(true);
  const iconInput = useRef();

  const getUrlObject = async () => {
    const _img = await urlToObject(`${imageUrl}${icon}`);
    setIcon(_img);
    setLoading(false);
  };

  useEffect(() => {
    getUrlObject();
  }, []);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color text-left">
          Edit Pharmacy
        </span>
        <div data-validate="First name is required" className="mb-3">
          <span className="label-input100">First Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>

        <div data-validate="Last name is required" className="mb-3">
          <span className="label-input100">Last Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>

        <div data-validate="Email is required" className="mb-3">
          <span className="label-input100">Email</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div data-validate="Country code is required" className="mb-3">
          <span className="label-input100">Country Code</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Country Code"
            value={countryCode}
            onChange={(e) => {
              setCountryCode(e.target.value);
            }}
          />
        </div>

        <div data-validate="Phone number required" className="mb-3">
          <span className="label-input100">Phone Number</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div>

        <div data-validate="Phone number required" className="mb-3">
          <span className="label-input100">Address</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Phone Number"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div>

        <div data-validate="Phone number required" className="mb-3">
          <span className="label-input100">City</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="City"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>

        <div data-validate="Description is required" className="mb-3">
          <span className="label-input100">Description</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Description"
            value={shortBio}
            onChange={(e) => {
              setShortBio(e.target.value);
            }}
          />
        </div>

        <div className="validate-input mt-4" data-validate="Icon is required">
          <span className="add-evidence-modal-label">Edit Image</span>
          <div
            className="d-flex flex-wrap mt-3"
            style={{ width: "120px", height: "100px" }}
          >
            {!loading && (
              <AddImageComponent
                index={null}
                item={icon}
                onSubmit={(data) => setIcon(data)}
                initialState={icon}
              />
            )}
          </div>
        </div>

        <input
          ref={iconInput}
          className="d-none"
          type="file"
          onChange={(e) => setIcon(e.target.files[0])}
        />

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsApiCall(true);
                let completePhoneNumber = `${countryCode}-${phoneNumber}`;
                onUpdate(
                  firstName,
                  lastName,
                  email,
                  countryCode,
                  phoneNumber,
                  completePhoneNumber,
                  address,
                  city,
                  shortBio,
                  icon,
                  setIsApiCall
                );
              }}
            >
              {isApiCall ? "Editing..." : "Edit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default UpdatePharmacy;
