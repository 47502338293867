import React, { useEffect, useState } from "react";
import Quill from "react-quill";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MuiButton from "@mui/material/Button";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import AddImageComponent from "../components/AddImageComponent";
import { BaseURL, apiHeader, urlToObject, imageUrl } from "../Config/apiUrl";
import { toast } from "react-toastify";
import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";

const AboutCms = () => {
  const pageData = useLocation()?.state.item;
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageName] = useState(pageData?.pageName);
  const [pageId] = useState(pageData?._id);
  // Home Section States Start //

  const [sec1Heading, setSec1Heading] = useState("");
  const [sec1Description, setSec1Description] = useState("");
  const [sec1Image, setSec1Image] = useState({});

  const [sec2Heading, setSec2Heading] = useState("");
  const [sec2Description, setSec2Description] = useState("");
  const [sec2Image, setSec2Image] = useState({});

  const [sec3Image, setSec3Image] = useState({});
  const [sec3Heading, setSec3Heading] = useState("");
  const [sec3Description, setSec3Description] = useState("");

  const [sec4Heading, setSec4Heading] = useState("");
  const [sec4Description, setSec4Description] = useState("");
  const [sec4Description2, setSec4Description2] = useState("");
  const [sec4Image, setSec4Image] = useState({});

  const [sec5Heading1, setSec5Heading1] = useState("");
  const [sec5Description1, setSec5Description1] = useState("");
  const [sec5Heading2, setSec5Heading2] = useState("");
  const [sec5Description2, setSec5Description2] = useState("");
  const [sec5Heading3, setSec5Heading3] = useState("");
  const [sec5Description3, setSec5Description3] = useState("");
  const [sec5Image, setSec5Image] = useState({});

  const [sec6Heading1, setSec6Heading1] = useState("");
  const [sec6Heading2, setSec6Heading2] = useState("");
  const [sec6Description2, setSec6Description2] = useState("");
  const [sec6Heading3, setSec6Heading3] = useState("");
  const [sec6Description3, setSec6Description3] = useState("");
  const [sec6Heading4, setSec6Heading4] = useState("");
  const [sec6Description4, setSec6Description4] = useState("");
  const [sec6Heading5, setSec6Heading5] = useState("");
  const [sec6Description5, setSec6Description5] = useState("");
  const [sec6Heading6, setSec6Heading6] = useState("");
  const [sec6Description6, setSec6Description6] = useState("");
  const [sec6Heading7, setSec6Heading7] = useState("");
  const [sec6Description7, setSec6Description7] = useState("");
  const [sec6Image, setSec6Image] = useState({});
  const [sec6Image2, setSec6Image2] = useState({});
  const [sec6Image3, setSec6Image3] = useState({});
  const [sec7Heading1, setSec7Heading1] = useState("");
  const [sec7Description1, setSec7Description1] = useState("");
  const [sec7Heading2, setSec7Heading2] = useState("");
  const [sec7Description2, setSec7Description2] = useState("");
  // Home Section States End //

  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      setSec1Heading(response?.data?.data?.sec1Heading);
      setSec1Description(response?.data?.data?.sec1Description);

      setSec2Heading(response?.data?.data?.sec2Heading);
      setSec2Description(response?.data?.data?.sec2Description);

      setSec3Heading(response?.data?.data?.sec3Heading);
      setSec3Description(response?.data?.data?.sec3Description);

      setSec4Heading(response?.data?.data?.sec4Heading);
      setSec4Description(response?.data?.data?.sec4Description);
      setSec4Description2(response?.data?.data?.sec4Description2);

      setSec5Heading1(response?.data?.data?.sec5Heading1);
      setSec5Description1(response?.data?.data?.sec5Description1);
      setSec5Heading2(response?.data?.data?.sec5Heading2);
      setSec5Description2(response?.data?.data?.sec5Description2);
      setSec5Heading3(response?.data?.data?.sec5Heading3);
      setSec5Description3(response?.data?.data?.sec5Description3);

      setSec6Heading1(response?.data?.data?.sec6Heading1);
      setSec6Heading2(response?.data?.data?.sec6Heading2);
      setSec6Description2(response?.data?.data?.sec6Description2);
      setSec6Heading3(response?.data?.data?.sec6Heading3);
      setSec6Description3(response?.data?.data?.sec6Description3);
      setSec6Heading4(response?.data?.data?.sec6Heading4);
      setSec6Description4(response?.data?.data?.sec6Description4);
      setSec6Heading5(response?.data?.data?.sec6Heading5);
      setSec6Description5(response?.data?.data?.sec6Description5);
      setSec6Heading6(response?.data?.data?.sec6Heading6);
      setSec6Description6(response?.data?.data?.sec6Description6);
      setSec6Heading7(response?.data?.data?.sec6Heading7);
      setSec6Description7(response?.data?.data?.sec6Description7);

      setSec7Heading1(response?.data?.data?.sec7Heading1);
      setSec7Description1(response?.data?.data?.sec7Description1);
      setSec7Heading2(response?.data?.data?.sec7Heading2);
      setSec7Description2(response?.data?.data?.sec7Description2);

      //   Single Image
      const _section1Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec1Image}`
      );
      setSec1Image(_section1Image);

      //   Single Image\
      const _section2Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec2Image}`
      );
      setSec2Image(_section2Image);

      //   Single Image\
      const _section3Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec3Image}`
      );
      setSec3Image(_section3Image);

      //   Single Image\
      const _section4Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec4Image}`
      );
      setSec4Image(_section4Image);

      //   Single Image\
      const _section5Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec5Image}`
      );
      setSec5Image(_section5Image);

      //   Single Image\
      const _section6Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec6Image}`
      );
      setSec6Image(_section6Image);

      //   Single Image\
      const _section6Image2 = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec6Image2}`
      );
      setSec6Image2(_section6Image2);

      //   Single Image\
      const _section6Image3 = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec6Image3}`
      );
      setSec6Image3(_section6Image3);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2800);
    return () => clearTimeout(timeOut);
  }, []);

  //   handleSubmit
  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName,

      sec1Heading,
      sec1Description,
      sec1Image,

      sec2Heading,
      sec2Description,
      sec2Image,

      sec3Heading,
      sec3Description,
      sec3Image,

      sec4Heading,
      sec4Description,
      sec4Description2,
      sec4Image,

      sec5Heading1,
      sec5Description1,
      sec5Heading2,
      sec5Description2,
      sec5Heading3,
      sec5Description3,
      sec5Image,

      sec6Heading1,
      sec6Heading2,
      sec6Description2,
      sec6Heading3,
      sec6Description3,
      sec6Heading4,
      sec6Description4,
      sec6Heading5,
      sec6Description5,
      sec6Heading6,
      sec6Description6,
      sec6Heading7,
      sec6Description7,
      sec6Image,
      sec6Image2,
      sec6Image3,

      sec7Heading1,
      sec7Description1,
      sec7Heading2,
      sec7Description2,
    };

    const formData = new FormData();
    for (let key in params) {
      params[key] && formData.append(key, params[key]);
    }

    const url = BaseURL("cms/page/update");
    setIsApiCall(true);
    const response = await Post(url, formData, apiHeader(accessToken, true));
    setIsApiCall(false);

    if (response !== undefined) {
      toast.success("Page data updated successfully");
      getData();
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <section className="about-section">
        <div className="aboutpage_heading">
          <h1>ABOUT US PAGE DETAILS</h1>
        </div>

        {isLoading && <BeforeLoadContentLoader />}

        <div className="aboutSection1">
          <p className="mb-0 mt-3">Section1 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec1Heading}
            onChange={(e) => setSec1Heading(e.target.value)}
          />
          <p className="mb-0 mt-3">Section1 description</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec1Description}
            onChange={(e) => setSec1Description(e.target.value)}
          ></textarea>
          <p className="mb-3 mt-3">Section1 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec1Image}
              onSubmit={(data) => setSec1Image(data)}
              initialState={sec1Image}
            />
          </div>
        </div>
        {/* Section 2 */}
        <div className="aboutSection2">
          <p className="mb-0 mt-3">Section2 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec2Heading}
            onChange={(e) => setSec2Heading(e.target.value)}
          />
          <p className="mb-0 mt-3"> section2 description</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec2Description}
            onChange={(e) => setSec2Description(e.target.value)}
          ></textarea>
          <p className="mb-3 mt-3">Section2 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec2Image}
              onSubmit={(data) => setSec2Image(data)}
              initialState={sec2Image}
            />
          </div>
        </div>
        {/* Section 3 */}
        <div className="aboutSection3">
          <p className="mb-3 mt-3">Section3 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec3Image}
              onSubmit={(data) => setSec3Image(data)}
              initialState={sec3Image}
            />
          </div>
          <p className="mb-0 mt-3">Section3 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec3Heading}
            onChange={(e) => setSec3Heading(e.target.value)}
          />
          <p className="mb-0 mt-3">section3 description</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec3Description}
            onChange={(e) => setSec3Description(e.target.value)}
          ></textarea>
        </div>
        {/* Section 4 */}
        <div className="aboutSection4">
          <p className="mb-0 mt-3">Section4 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec4Heading}
            onChange={(e) => setSec4Heading(e.target.value)}
          />
          <p className="mb-0 mt-3">Section4 description</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec4Description}
            onChange={(e) => setSec4Description(e.target.value)}
          ></textarea>

          <p className="mb-0 mt-3">Section4 description2</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec4Description2}
            onChange={(e) => setSec4Description2(e.target.value)}
          ></textarea>

          <p className="mb-3 mt-3">Section4 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec4Image}
              onSubmit={(data) => setSec4Image(data)}
              initialState={sec4Image}
            />
          </div>
        </div>
        {/* Section 5 */}

        <div className="aboutSection5">
          <p className="mb-0 mt-3">Section5 heading1</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec5Heading1}
            onChange={(e) => setSec5Heading1(e.target.value)}
          />
          <p className="mb-0 mt-3">Section5 description1</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec5Description1}
            onChange={(e) => setSec5Description1(e.target.value)}
          ></textarea>
          <p className="mb-0 mt-3">Section5 heading2</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec5Heading2}
            onChange={(e) => setSec5Heading2(e.target.value)}
          />
          <p className="mb-0 mt-3">Section5 description2</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec5Description2}
            onChange={(e) => setSec5Description2(e.target.value)}
          ></textarea>
          <p className="mb-0 mt-3">Section5 heading3</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec5Heading3}
            onChange={(e) => setSec5Heading3(e.target.value)}
          />
          <p className="mb-0 mt-3">Section5 description3</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec5Description3}
            onChange={(e) => setSec5Description3(e.target.value)}
          ></textarea>
          <p className="mb-3 mt-3">Section5 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec5Image}
              onSubmit={(data) => setSec5Image(data)}
              initialState={sec5Image}
            />
          </div>
        </div>
        <div className="aboutSection6">
          <p className="mb-0 mt-3">Section6 heading1</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec6Heading1}
            onChange={(e) => setSec6Heading1(e.target.value)}
          />
          <p className="mb-0 mt-3">Section6 heading2</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec6Heading2}
            onChange={(e) => setSec6Heading2(e.target.value)}
          />
          <p className="mb-0 mt-3">Section6 description2</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec6Description2}
            onChange={(e) => setSec6Description2(e.target.value)}
          ></textarea>
          <p className="mb-0 mt-3">Section6 heading3</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec6Heading3}
            onChange={(e) => setSec6Heading3(e.target.value)}
          />
          <p className="mb-0 mt-3">Section6 description3</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec6Description3}
            onChange={(e) => setSec6Description3(e.target.value)}
          ></textarea>
          <p className="mb-0 mt-3">Section6 heading4</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec6Heading4}
            onChange={(e) => setSec6Heading4(e.target.value)}
          />
          <p className="mb-0 mt-3">Section6 description4</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec6Description4}
            onChange={(e) => setSec6Description4(e.target.value)}
          ></textarea>
          <p className="mb-0 mt-3">Section6 heading5</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec6Heading5}
            onChange={(e) => setSec6Heading5(e.target.value)}
          />
          <p className="mb-0 mt-3">Section6 description5</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec6Description5}
            onChange={(e) => setSec6Description5(e.target.value)}
          ></textarea>
          <p className="mb-0 mt-3">Section6 heading6</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec6Heading6}
            onChange={(e) => setSec6Heading6(e.target.value)}
          />
          <p className="mb-0 mt-3">Section6 description6</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec6Description6}
            onChange={(e) => setSec6Description6(e.target.value)}
          ></textarea>
          <p className="mb-0 mt-3">Section6 heading7</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec6Heading7}
            onChange={(e) => setSec6Heading7(e.target.value)}
          />
          <p className="mb-0 mt-3">Section6 description7</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec6Description7}
            onChange={(e) => setSec6Description7(e.target.value)}
          ></textarea>
          <p className="mb-3 mt-3">Section6 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec6Image}
              onSubmit={(data) => setSec6Image(data)}
              initialState={sec6Image}
            />
          </div>
          <p className="mb-3 mt-3">Section6 Image2</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec6Image2}
              onSubmit={(data) => setSec6Image2(data)}
              initialState={sec6Image2}
            />
          </div>
          <p className="mb-3 mt-3">Section6 Image3</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec6Image3}
              onSubmit={(data) => setSec6Image3(data)}
              initialState={sec6Image3}
            />
          </div>
        </div>
        <div className="aboutSection7">
          <p className="mb-0 mt-3">Section7 heading1</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec7Heading1}
            onChange={(e) => setSec7Heading1(e.target.value)}
          />
          <p className="mb-0 mt-3">Section7 description1</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec7Description1}
            onChange={(e) => setSec7Description1(e.target.value)}
          ></textarea>
          <p className="mb-0 mt-3">Section7 heading2</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec7Heading2}
            onChange={(e) => setSec7Heading2(e.target.value)}
          />
          <p className="mb-0 mt-3">Section7 description2</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec7Description2}
            onChange={(e) => setSec7Description2(e.target.value)}
          ></textarea>
        </div>
        <div className="home-cms-btn">
          <MuiButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className="mt-3 custom-btn rounded-lg radius-8 ms-4"
            disabled={isApiCall}
          >
            {isApiCall ? "Updating..." : "Update"}
          </MuiButton>
        </div>
      </section>
    </>
  );
};

export default AboutCms;
