import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { DatePicker } from "../components/DatePicker/index";
import { updateUser } from "../store/Actions/authAction";
import PaginationComponent from "../components/PaginationComponent/index";

const numeral = require("numeral");

// Mui Button

const Payments = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const user = useSelector((state) => state.authReducer.user);
  const [isGetting, setIsGetting] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("all");
  const [transactionData, setTransactionData] = useState([]);
  const [wafipayCommission, setWafipayCommission] = useState();
  const [search, setSearch] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [amountInWallet, setAmountInWallet] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");
  const dispatch = useDispatch();

  const getAllPayments = async (pageNo, newLimit, status, from, to) => {
    setIsGetting(true);
    const url =
      from && to
        ? BaseURL(
            `users/transactions?status=${status}&from=${from}&to=${to}&page=${pageNo}&limit=${newLimit}`
          )
        : BaseURL(
            `users/transactions?status=${status}&page=${pageNo}&limit=${newLimit}`
          );

    const withdrawUrl = BaseURL(`users/withdrawals?status=accepted`);

    const wafipayUrl = BaseURL("config/commissions");

    const responseData = await Get(url, accessToken);
    const withdrawalData = await Get(withdrawUrl, accessToken);
    const resData = await Get(wafipayUrl, accessToken);
    if (responseData !== undefined) {
      const foundWafipayCommission = resData?.data?.data?.find(
        (item) => item?.KeyType == "wafipay-commission"
      );
      const totAmount = responseData?.data?.data?.reduce((data, next) => {
        return data + next?.amount;
      }, 0);
      const amountInWallet = responseData?.data?.data?.reduce(
        (data, next) =>
          data + next?.amount - foundWafipayCommission?.adminCommission?.rate,
        0
      );
      const withdrawalAmount = withdrawalData?.data?.data?.reduce(
        (data, next) => {
          const commission = (next?.amount * next?.commission) / 100;
          return data + next?.amount - commission;
        },
        0
      );
      setLimit(responseData?.data?.totalRecords);
      setWafipayCommission(foundWafipayCommission);
      setTransactionData(responseData?.data?.data);
      setTotalAmount(totAmount);
      setAmountInWallet(amountInWallet);
      setTotalWithdrawal(withdrawalAmount);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const getUser = async () => {
    const url = BaseURL(`users/me`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      dispatch(updateUser(responseData?.data?.data?.user));
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getAllPayments(1, recordsLimit, transactionStatus);
  }, []);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllPayments(page, recordsLimit);
  };

  const onSelectDate = (date) => {
    const from = date[0];
    const to = date[1] ? date[1] : null;
    getAllPayments(1, recordsLimit, transactionStatus, from, to);
  };

  return (
    <>
      <div className="fieldSetHeaderContainer d-flex flex-column mt-5">
        <h1>PAYMENT HISTORY</h1>
      </div>
      <div className="fieldSetHeaderContainer d-flex flex-column mt-5">
        <h2>
          Total Earnings:{" "}
          <span>
            {user.userWallet.totAmount < 1 ? 0 : user.userWallet.totAmount} SLSH
          </span>
        </h2>
        <h2>
          Total Withdrawal Accepted:{" "}
          <span>{totalWithdrawal ? totalWithdrawal : 0} SLSh</span>
        </h2>
        <h2>
          Available Balance:{" "}
          <span>
            {user.userWallet.availableBalance < 1
              ? 0
              : user.userWallet.totAmount - totalWithdrawal}{" "}
            SLSh
          </span>
        </h2>
      </div>
      <div className="fieldSetMainContainer mt-5">
        <div className="place-add-btn-container mb-4">
          {/* <div className="d-flex align-items-center">
            <p className="m-0 me-3 fw-bold">Items Per Page:</p>
            <select
              value={recordsLimit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPageNumber(1);
                getAllPayments(1, e.target.value, transactionStatus);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={10} className="pt-2">
                10
              </option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div> */}
          <div className="d-flex align-items-center">
            <DatePicker onUpdate={onSelectDate} />
          </div>
          <div className="d-flex">
            <div className="d-flex align-items-center ms-3">
              <p className="m-0 me-3 fw-bold">Status:</p>
              <select
                value={transactionStatus}
                onChange={(e) => {
                  setTransactionStatus(e.target.value);
                  setPageNumber(1);
                  getAllPayments(1, limit, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={"all"}>All</option>
                <option value={"deposit"}>Complete</option>
                <option value={"refund"}>Refund</option>
              </select>
            </div>
          </div>
        </div>
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Date</th>
                    <th>Patient</th>
                    <th>Amount</th>
                    <th>Wafipay Amount</th>
                    <th>Amount Added To Wallet</th>
                    {/* <th>Processing Fee</th>
                    <th>Net Payment</th> */}
                    <th>Description</th>
                    <th>Status</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>

                <tbody>
                  {transactionData.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan={9}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {transactionData?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>
                            {moment(item.createdAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>{item?.patientId?.displayName}</td>
                          <td>
                            {item?.amount} <strong>SLSH</strong>
                          </td>
                          <td>
                            {wafipayCommission?.adminCommission?.rate}{" "}
                            <strong>SLSH</strong>
                          </td>
                          <td>
                            {item.amount -
                            wafipayCommission?.adminCommission?.rate
                              ? item.amount -
                                wafipayCommission?.adminCommission?.rate
                              : 0}{" "}
                            <strong>SLSH</strong>
                          </td>
                          <td>{item?.message}</td>
                          <td>
                            {item?.status == "deposit"
                              ? "Completed"
                              : "Refunded"}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  {transactionData.length > 0 && (
                    <tr>
                      <td colSpan={3}></td>
                      <td colSpan={1}>
                        <div className="payment-amount">
                          Total:{" "}
                          <strong className="">
                            {" "}
                            {totalAmount ? totalAmount : 0} SLSH
                          </strong>
                        </div>
                      </td>
                      <td colSpan={1}></td>
                      <td colSpan={3}>
                        <div className="payment-amount">
                          Total:{" "}
                          <strong className="">
                            {" "}
                            {amountInWallet ? amountInWallet : 0} SLSH
                          </strong>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <Col md={12} className="paginationContainer">
          {transactionData?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>
      {/* <Pagination
        maxLastLength={limit}
        array={transactionData}
        HandlepageChange={HandlepageChange}
        pageNumber={pageNumber}
      /> */}
    </>
  );
};

export default Payments;
