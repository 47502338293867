import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
// import { ImFileVideo } from "react-icons/im";
import { Row, Col } from "react-bootstrap";
// import Img1 from "../assets/images/caro6.png";
// import Img2 from "../assets/images/vendor_face.png";
// import PDF from "../assets/images/sample.pdf";
import moment from "moment";
import { imageUrl } from "../Config/apiUrl";

const ViewPharmacy = ({ open, setOpen, data }) => {
  const [address] = useState(data?.address);
  const [city] = useState(data?.city);
  const [shortBio] = useState(data?.shortBio);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color pb-4">
          Pharmacy Details
        </span>
        <Row>
          <Col md={8}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">Address:</span>
              <span className="font-14">{address}</span>
            </div>
          </Col>
          <Col md={4}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">City:</span>
              <span className="font-14">{city}</span>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <div className="d-flex flex-column">
              <span className="font-17 fw-bold">Description:</span>
              <span className="font-14">{shortBio}</span>
            </div>
          </Col>
        </Row>
      </form>
    </ModalSkeleton>
  );
};

export default ViewPharmacy;
