import React, { useState } from "react";
import { Input } from "./Input/Input";
import ModalSkeleton from "./ModalSkeleton";

const AddUserModal = ({ open, setOpen, type, onUpdate }) => {
  const [role, setRole] = useState("patient");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCountryCode] = useState("SO+252");
  const [contact, setContact] = useState("");
  const [isApiCall, setIsApiCall] = useState(false);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          Add{" "}
          {`${
            type == "User" ? role.charAt(0).toUpperCase() + role.slice(1) : type
          }`}
        </span>

        {type == "User" && (
          <div className="d-flex align-items-center mb-3">
            <p className="m-0 me-3 fw-bold">Select Role:</p>
            <select
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value="doctor">Doctor</option>
              {/* <option value="covid19">Covid19</option> */}
              <option value="patient">Patient</option>
              {/* <option value="pharmacy">Pharmacy</option> */}
            </select>
          </div>
        )}

        <div data-validate="First Name is required" className="">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="firstname"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>
        <div data-validate="Middle Name is required" className="mt-3">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="middleName"
            placeholder="Middle Name"
            value={middleName}
            onChange={(e) => {
              setMiddleName(e.target.value);
            }}
          />
        </div>
        <div data-validate="Last Name is required" className="mt-3">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="lastname"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        <div data-validate="Email is required" className="mt-3">
          <input
            className="add-evidence-modal-input full"
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div data-validate="Email is required" className="mt-3">
          {/* <input
            className="add-evidence-modal-input full"
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          /> */}

          <Input
            state={password}
            setState={setPassword}
            placeholder="Password"
            type="password"
          />
        </div>

        <div data-validate="Country code is required" className="d-flex mt-3">
          <div className="col-md-3">
            <input
              className="add-evidence-modal-input full"
              type="text"
              name="country-code"
              placeholder="Country Code"
              disabled="true"
              value={countryCode}
              onChange={(e) => {
                setCountryCode(e.target.value);
              }}
            />
          </div>

          <div data-validate="Contact is required" className="mx-1 col-md-9">
            <input
              className="add-evidence-modal-input full"
              type="number"
              name="contact"
              placeholder="63xxxxx"
              value={contact}
              onChange={(e) => {
                setContact(e.target.value);
              }}
            />
          </div>
        </div>

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isApiCall}
              className="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                onUpdate(
                  {
                    firstName,
                    lastName,
                    middleName,
                    email,
                    phoneNumber: contact,
                    countryCode: "+252",
                    password,
                    passwordConfirm: password,
                    role: type == "Admin" ? "manager" : role,
                  },
                  setIsApiCall
                );
              }}
            >
              {isApiCall ? "Adding..." : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddUserModal;
