import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
import { FaImage } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import AddImageComponent from "../components/AddImageComponent";

const AddSpecializationModal = ({ open, setOpen, onUpdate, isLoading }) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [name, setName] = useState();
  const [icon, setIcon] = useState();
  const iconInput = useRef();

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color text-left">
          Add Specialization
        </span>
        <div data-validate="Name is required" className="mb-3">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="validate-input mt-4" data-validate="Image is required">
          <span className="add-evidence-modal-label">Add Icon</span>
          <div
            className="d-flex flex-wrap mt-5"
            style={{ width: "50px", height: "50px" }}
          >
            <AddImageComponent
              index={null}
              item={icon}
              onSubmit={(data) => setIcon(data)}
              initialState={icon}
            />
          </div>
        </div>

        <input
          ref={iconInput}
          className="d-none"
          type="file"
          onChange={(e) => setIcon(e.target.files[0])}
        />

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsApiCall(true);
                onUpdate(name.trim(), icon);
              }}
            >
              {isApiCall ? "Adding..." : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddSpecializationModal;
