import React from "react";

import ModalSkeleton from "./ModalSkeleton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import { BaseURL, validateEmail } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";

const AreYouSureModal = ({
  open,
  setOpen,
  label,
  message,
  handleYes,
  isLoading,
}) => {
  const func = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <div>
        <form className="login100-form validate-form">
          <span className="login100-form-title are-you-sure-label">
            {label}
          </span>
          <p>{message}</p>

          {/* No */}
          <div className="container-login100-form-btn">
            <div className="wrap-login100-form-btn">
              <div className="login100-form-bgbtn"></div>
              <button
                className="login100-form-btn btn-color"
                onClick={(e) => func(e)}
                disabled={isLoading}
              >
                No
              </button>
            </div>
          </div>

          {/* Yes */}
          <div className="container-login100-form-btn-delete">
            <div className="wrap-login100-form-btn">
              <div className="login100-form-bgbtn"></div>
              <button
                disabled={isLoading}
                className="login100-form-btn btn-color"
                onClick={(e) => {
                  e.preventDefault();
                  handleYes();
                }}
              >
                {isLoading ? "Please Wait..." : "Yes"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default AreYouSureModal;
