import "./App.css";
import "./assets/Styles/style.css";
import "./assets/Styles/sideBar.css";
import "./assets/Styles/loginScreen.css";
import "./assets/Styles/AddCategory.css";
import "./assets/Styles/AddAndEditCategoryModal.css";
import "./assets/Styles/Pagination.css";
import "./assets/Styles/mainDashboard.css";
import "./assets/Styles/Users.css";
import "./assets/Styles/AddEvidenceModal.css";
import "./assets/Styles/chatScreen.css";
import "./assets/Styles/ScheduleScreen.css";
import "./assets/Styles/Profile.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";

import { Fragment, useEffect, useRef } from "react";
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import SideNavbar from "./components/SideNavbar";
import { removeApplicationData, socketURL } from "./Config/apiUrl";
import Login from "./Screens/Login";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { Component } from "react";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  // const socket = useRef(io(socketURL, { transports: ['websocket'] }))

  useEffect(() => {}, []);
  return (
    <Beforeunload onBeforeunload={removeApplicationData}>
      <ToastContainer />
      <Router>
        <Fragment>
          <div className="row">
            {isLogin ? <SideNavbar /> : <Redirect to="/login" />}
          </div>
          <Route exact path="/login">
            <Login />
          </Route>
        </Fragment>
      </Router>
    </Beforeunload>
  );
}

export default App;
