import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import ModalSkeleton from "./ModalSkeleton";
import { FaImage } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Button } from "react-bootstrap";
import AddImageComponent from "./AddImageComponent";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader, imageUrl } from "../Config/apiUrl";
import Pagination from "./Pagination";
import TableRowsLoader from "./TableRowsLoader";

const ViewDoctorAndCovidLab = ({
  open,
  setOpen,
  onUpdate,
  isLoading,
  cities,
  specializations,
  patientId,
}) => {
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [accountType] = useState(true);
  const [appointmentWith, setAppointmentWith] = useState("doctor");
  const [usersData, setUsersData] = useState([]);
  const [selectedCity, setSelectedCity] = useState(cities[0]?.name);
  const [selectedSpecialization, setSelectedSpecialization] = useState([
    { label: specializations[0]?.name, value: specializations[0]?.name },
  ]);
  const [commissions, setCommissions] = useState([]);
  const history = useHistory();

  const mappedSpecializationName = specializations.map((specialization) => {
    return {
      label: specialization?.name,
      value: specialization?.name,
    };
  });

  const getAllUsers = async (pageNo, newLimit, newUserType, newAccountType) => {
    setIsGetting(true);
    const sendSpecialization = selectedSpecialization.map((el) => {
      if (el.value.includes("&")) el.value = el.value.replace("&", "and");
      return el.value;
    });
    const url =
      newUserType == "covid19"
        ? BaseURL(
            `users/get-by-admin?role=${newUserType}&isActive=${newAccountType}&page=${pageNo}&limit=${newLimit}`
          )
        : BaseURL(
            `users/get-by-admin?role=${newUserType}&isActive=${newAccountType}&city=${selectedCity}&specialization=${JSON.stringify(
              sendSpecialization
            )}&page=${pageNo}&limit=${newLimit}`
          );

    const commissionUrl = BaseURL("config/commissions");

    const responseData = await Get(url, accessToken);
    const commissionData = await Get(commissionUrl, accessToken);
    if (responseData !== undefined) {
      setUsersData(responseData?.data?.data);
      setCommissions(commissionData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getAllUsers(newPageNumber, limit, appointmentWith, accountType);
  };

  useEffect(() => {
    getAllUsers(1, limit, appointmentWith, accountType);
  }, [pageNumber, appointmentWith, selectedCity, selectedSpecialization]);

  const appointmentSelect = appointmentWith == "doctor" ? "Doctors" : "Covid19";

  return (
    <ModalSkeleton open={open} setOpen={setOpen} type="doctorAndCovidLabModal">
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color text-left">
          {appointmentSelect} List
        </span>

        <div data-validate="Category is required" className="mb-3">
          <span className="add-evidence-modal-label">Appointment With: </span>
          <select
            value={appointmentWith}
            onChange={(e) => {
              setAppointmentWith(e.target.value);
            }}
            className="px-3 py-2 custom-selection"
          >
            <option value="doctor">Doctor</option>
            <option value="covid19">Covid19</option>
          </select>
        </div>
        {appointmentWith == "doctor" && (
          <>
            <div data-validate="Category is required" className="mb-3">
              <span className="add-evidence-modal-label">City: </span>
              <select
                value={selectedCity}
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                {cities.map((city) => {
                  return <option value={city?.name}>{city?.name}</option>;
                })}
              </select>
            </div>

            <div data-validate="Category is required" className="mb-3">
              <span className="add-evidence-modal-label">Specialization: </span>
              <ReactSelect
                value={selectedSpecialization}
                onChange={(e) => {
                  setSelectedSpecialization(e);
                }}
                isMulti={true}
                options={mappedSpecializationName}
              />
              {/* <select
                value={selectedSpecialization}
                onChange={(e) => {
                  setSelectedSpecialization(e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                {specializations.map((specialization) => {
                  return (
                    <option value={specialization?.name}>
                      {specialization?.name}
                    </option>
                  );
                })}
              </select> */}
            </div>
          </>
        )}
        <div className="fieldSetMainContainer">
          {/* Add Section Button Container */}
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Items Per Page:</p>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  getAllUsers(1, e.target.value, appointmentWith, accountType);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={10} className="pt-2">
                  10
                </option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
          </div>

          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Image</th>
                      <th>Full Name</th>
                      <th>City</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {usersData.length < 1 && (
                      <tr className="no-data-table-row">
                        {" "}
                        <td colSpan={appointmentWith === "doctor" ? 10 : 9}>
                          {" "}
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}

                    {usersData?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>{index + 1}</td>
                            <td>
                              {
                                <div className="category-icon-box">
                                  {" "}
                                  <img
                                    src={imageUrl + item?.photo}
                                    className="w-100 h-100"
                                  />
                                </div>
                              }
                            </td>
                            <td>{item?.displayName}</td>
                            <td>{item?.city}</td>
                            <td>
                              <ul className="d-flex">
                                <li
                                  className="page-item"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/profile/${item?._id}`,
                                      state: { item, patientId, commissions },
                                    });
                                    setOpen(false);
                                  }}
                                >
                                  <Button className="update-btn me-2">
                                    View
                                  </Button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <Pagination
            maxLastLength={limit}
            array={usersData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          />
        </div>

        {/* {isViewing && (
          <ViewSchedule
            open={isViewing}
            setOpen={setIsViewing}
            data={viewUserDetails}
            appointmentWith={appointmentWith}
          />
        )} */}

        {/* btn */}
        {/* <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div> */}
        {/* <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsApiCall(true);
                onUpdate();
              }}
            >
              {isApiCall ? "Booking..." : "Book"}
            </button> */}
        {/* </div> */}
        {/* </div> */}
      </form>
    </ModalSkeleton>
  );
};

export default ViewDoctorAndCovidLab;
