import React, { useState, useEffect, useRef, createRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Avatar } from "@mui/material";
import EmptyChat from "../assets/images/empty-chat.png";
import { useSelector } from "react-redux";
import moment from "moment";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";
import { Get, Post } from "../Axios/AxiosFunctions";
import { BaseURL, imageUrl, apiHeader, apiUrl } from "../Config/apiUrl";
import { v4 as uuidv4 } from "uuid";
import { io } from "socket.io-client";

function OtherMessage({ message, time, user }) {
  return (
    <div className="left-message">
      <div>
        <Avatar src={`${user?.avatar}`} />
      </div>
      <div>
        <p className="message">
          {message}
          <span className="arrow" />
        </p>
        <span className="time">{moment(time).format("hh:mm a")}</span>
      </div>
    </div>
  );
}

function MyMessage({ message, time }) {
  return (
    <div className="right-message">
      <div>
        <p className="message">
          {message}
          <span className="arrow" />
        </p>
        <span className="time">{moment(time).format("hh:mm a")}</span>
      </div>
    </div>
  );
}

function ChatList({ data, role, onClick }) {
  let undread =
    role === "user" ? data?.user1UnreadCount : data?.user2UnreadCount;
  return (
    <div className="chat-list" onClick={() => onClick(data?.id)}>
      <Container className="h-100">
        <Row className="h-100">
          <Col lg={3}>
            <div className="d-flex a-center h-100">
              <Avatar
                src={`${imageUrl}${
                  role == "user" ? data?.user1.photo : data?.user2?.photo
                }`}
                sx={{ width: 50, height: 50 }}
              />
            </div>
          </Col>
          <Col lg={7}>
            <div className="username">
              <h6>
                {role == "user"
                  ? data?.user1?.displayName
                  : data?.user2?.displayName}
              </h6>
              <span>{data?.lastMessage?.text}</span>
            </div>
          </Col>
          <Col lg={2}>
            <div className="time-box">
              <span>
                {moment(data?.lastMessage?.createdAt).format("hh:mma")}
              </span>
              {undread > 0 && <span className="remaining-msgs">{undread}</span>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function ChatTimeline({ time }) {
  return (
    <Row className="chat-timeline">
      <Col span={5}>
        <p className="left-border" />
      </Col>
      <Col span={2} className="text-center">
        <span className="time">{moment(time).format("DD MMM YYYY")}</span>
      </Col>
      <Col span={5}>
        <p className="right-border" />
      </Col>
    </Row>
  );
}

function Chat() {
  const socket = useRef(null);
  const chatsRef = createRef();
  const [messageText, setMessageText] = useState("");
  const [rooms, setRooms] = useState([]);
  const { access_token: accessToken, user } = useSelector(
    (state) => state.authReducer
  );
  const role = user?.UserId?.role;
  const [roomData, setRoomData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [leftUser, setLefttUser] = useState(null);
  const userId = user?._id;

  function scrollToBottom() {
    chatsRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  async function getRooms() {
    const url = BaseURL(`chat/chat-rooms`);

    const response = await Get(url, accessToken);
    if (response !== undefined) {
      setRooms(response?.data?.data);
    }
  }
  async function getRoomData(id) {
    const url = BaseURL(`chat/get-messages?roomId=${id}`);
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      if (response.data.data.length > 0) {
        setLefttUser(response.data.data[0].user._id);
      }
      setMessages(response?.data?.data?.reverse());
      let newRooms = [...rooms];
      newRooms.filter((item, index) => {
        if (item?.id === id) {
          if (role === "user") newRooms[index].user1UnreadCount = 0;
          else newRooms[index].user2UnreadCount = 0;
        }
      });

      setRooms([...newRooms]);
      scrollToBottom();
    }
  }

  useEffect(() => {
    getRooms();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // async function onSend() {
  //   if (messageText === "") {
  //     return toast.error("Please write the message", {
  //       position: "top-center",
  //     });
  //   }

  //   let newMsg = {
  //     _id: uuidv4(),
  //     text: messageText,
  //     createdAt: moment().format(),
  //     user: {
  //       _id: userId,
  //       name: role === "company" ? user?.contactPerson : user?.userName,
  //       avatar: `${imageUrl}${user?.photo}`,
  //     },
  //   };
  //   setMessages((prev) => [...prev, newMsg]);
  //   let msgTo =
  //     role == "company" ? roomData?.craftman?._id : roomData.company?._id;
  //   socket.current.emit("msg", newMsg, msgTo, roomData?.id, role);
  //   setMessageText("");
  //   scrollToBottom();
  // }

  //  Socket Connection
  // useEffect(() => {
  //   socket.current = io(apiUrl, { transports: ["websocket"] });
  //   socket.current.emit("join", userId, role);
  // }, []);

  // useEffect(() => {
  //   if (roomData !== null) {
  //     socket.current.emit("chatJoin", userId, roomData?.id, role);
  //     socket.current.emit("mark-as-read", roomData?.id, role);
  //     socket.current.on("msg", (msg, socketRoomId) => {
  //       if (roomData?.id === socketRoomId && msg?.user?._id !== userId) {
  //         setMessages((prev) => [...prev, msg]);

  //         socket.current.emit("mark-as-read", roomData?.id, role);
  //       }
  //     });
  //   }
  // }, [roomData]);

  return (
    <section className="chat-section">
      <Container>
        <Row>
          <Col xl={4} lg={4} md={4} sm={4}>
            <div className="chats-left-box">
              <div className="chat-header">
                <h5>CHATS</h5>
              </div>
              <div className="chats-left-box-messages">
                {rooms?.length === 0 ? (
                  <div className="empty-chat-list">
                    <span>No engaged chats</span>
                  </div>
                ) : (
                  rooms?.map((item) => {
                    return (
                      <ChatList
                        data={item}
                        role={user?.UserId?.role}
                        onClick={() => {
                          setRoomData(item);
                          getRoomData(item._id);
                        }}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </Col>
          <Col xl={8} lg={8} md={8} sm={8}>
            {!roomData ? (
              <div className="chats-right-box">
                <div className="center-empty-icon">
                  <h3>Welcome to chat</h3>
                  <img src={EmptyChat} />
                  <div></div>
                </div>
              </div>
            ) : (
              <div className="chats-right-box">
                <div className="chat-header">
                  <Avatar
                    src={`${imageUrl}${
                      role !== "user"
                        ? roomData?.user1?.photo
                        : roomData?.user2?.photo
                    }`}
                    sx={{ width: 50, height: 50 }}
                  />
                  <div className="ms-3">
                    <h6>
                      {role !== "user"
                        ? roomData?.user1?.displayName
                        : roomData?.user2?.displayName}
                    </h6>
                  </div>
                </div>

                <div className="chats-container" ref={chatsRef}>
                  <Container>
                    <Row>
                      {messages?.map((item, index) => {
                        let prevMessage = index > 0 && messages[index - 1];

                        return (
                          <>
                            {index === 0 && (
                              <ChatTimeline time={item?.createdAt} />
                            )}
                            {index > 0 &&
                              moment(prevMessage?.createdAt).format(
                                "DD MMM YYYY"
                              ) !==
                                moment(item?.createdAt).format(
                                  "DD MMM YYYY"
                                ) && <ChatTimeline time={item?.createdAt} />}
                            {item?.user?._id == leftUser ? (
                              <Col lg={12} key={item?._id}>
                                <MyMessage
                                  message={item?.text}
                                  time={item?.createdAt}
                                  user={item?.user}
                                />
                              </Col>
                            ) : (
                              <Col lg={12} key={item?._id}>
                                <OtherMessage
                                  message={item?.text}
                                  time={item?.createdAt}
                                  user={item?.user}
                                />
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </Container>
                </div>
                {/* <div className="chat-footer">
                  <Row>
                    <Col lg={11} md={10} sm={11}>
                      <input
                        placeholder="Type your message"
                        className="message-input"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                      />
                    </Col>
                    <Col lg={1} md={2} sm={1}>
                      <div className="d-flex a-center h-100">
                        <button className="send-btn" onClick={() => onSend()}>
                          <FiSend className="icon" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div> */}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default Chat;
