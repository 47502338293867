import React, { useEffect, useState } from "react";
import Quill from "react-quill";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MuiButton from "@mui/material/Button";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import AddImageComponent from "../components/AddImageComponent";
import { BaseURL, apiHeader, urlToObject, imageUrl } from "../Config/apiUrl";
import { toast } from "react-toastify";
import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";

const Covid19Cms = () => {
  const pageData = useLocation()?.state.item;
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageName] = useState(pageData?.pageName);
  const [pageId] = useState(pageData?._id);
  // Home Section States Start //

  const [sec1Heading, setSec1Heading] = useState("");
  const [sec1Description, setSec1Description] = useState("");
  const [sec1Image, setSec1Image] = useState({});

  const [sec2Heading, setSec2Heading] = useState("");
  const [sec2Description, setSec2Description] = useState("");
  const [sec2Image, setSec2Image] = useState({});

  const [sec3Heading, setSec3Heading] = useState("");

  const [sec4Heading, setSec4Heading] = useState("");
  const [sec4Description, setSec4Description] = useState("");
  const [sec4Image, setSec4Image] = useState({});

  const [sec5Heading, setSec5Heading] = useState("");

  const [sec6Heading, setSec6Heading] = useState("");
  const [sec6Description, setSec6Description] = useState("");
  const [sec6Image, setSec6Image] = useState({});

  // Home Section States End //

  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      setSec1Heading(response?.data?.data?.sec1Heading);
      setSec1Description(response?.data?.data?.sec1Description);

      setSec2Heading(response?.data?.data?.sec2Heading);
      setSec2Description(response?.data?.data?.sec2Description);

      setSec3Heading(response?.data?.data?.sec3Heading);

      setSec4Heading(response?.data?.data?.sec4Heading);
      setSec4Description(response?.data?.data?.sec4Description);

      setSec5Heading(response?.data?.data?.sec5Heading);

      setSec6Heading(response?.data?.data?.sec6Heading);
      setSec6Description(response?.data?.data?.sec6Description);

      //   Single Image
      const _section1Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec1Image}`
      );
      setSec1Image(_section1Image);

      //   Single Image\
      const _section2Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec2Image}`
      );
      setSec2Image(_section2Image);

      //   Single Image\
      const _section4Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec4Image}`
      );
      setSec4Image(_section4Image);

      //   Single Image\
      const _section6Image = await urlToObject(
        `${imageUrl}${response?.data?.data?.sec6Image}`
      );
      setSec6Image(_section6Image);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2800);
    return () => clearTimeout(timeOut);
  }, []);

  //   handleSubmit
  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName,

      sec1Heading,
      sec1Description,
      sec1Image,

      sec2Heading,
      sec2Description,
      sec2Image,

      sec3Heading,

      sec4Heading,
      sec4Description,
      sec4Image,

      sec5Heading,

      sec6Heading,
      sec6Description,
      sec6Image,
    };

    const formData = new FormData();
    for (let key in params) {
      params[key] && formData.append(key, params[key]);
    }

    const url = BaseURL("cms/page/update");
    setIsApiCall(true);
    const response = await Post(url, formData, apiHeader(accessToken, true));
    setIsApiCall(false);

    if (response !== undefined) {
      toast.success("Page data updated successfully");
      getData();
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <section className="home-section">
        <div className="homepage_heading">
          <h1>{pageName.toUpperCase()} PAGE DETAILS</h1>
        </div>

        {isLoading && <BeforeLoadContentLoader />}

        <div className="homeSection1">
          <p className="mb-0 mt-3">Section1 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec1Heading}
            onChange={(e) => setSec1Heading(e.target.value)}
          />
          <p className="mb-0 mt-3">Section1 description</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec1Description}
            onChange={(e) => setSec1Description(e.target.value)}
          ></textarea>
          <p className="mb-3 mt-3">Section1 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec1Image}
              onSubmit={(data) => setSec1Image(data)}
              initialState={sec1Image}
            />
          </div>
        </div>
        <div className="homeSection2">
          <p className="mb-0 mt-3">Section2 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec2Heading}
            onChange={(e) => setSec2Heading(e.target.value)}
          />
          <p className="mb-0 mt-3"> section2 description</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec2Description}
            onChange={(e) => setSec2Description(e.target.value)}
          ></textarea>
          <p className="mb-3 mt-3">Section2 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec2Image}
              onSubmit={(data) => setSec2Image(data)}
              initialState={sec2Image}
            />
          </div>
        </div>

        <div className="homeSection3">
          <p className="mb-0 mt-3">Section3 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec3Heading}
            onChange={(e) => setSec3Heading(e.target.value)}
          />
        </div>

        <div className="homeSection4">
          <p className="mb-0 mt-3">Section4 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec4Heading}
            onChange={(e) => setSec4Heading(e.target.value)}
          />
          <p className="mb-0 mt-3">Section4 description</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec4Description}
            onChange={(e) => setSec4Description(e.target.value)}
          ></textarea>
          <p className="mb-3 mt-3">Section4 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec4Image}
              onSubmit={(data) => setSec4Image(data)}
              initialState={sec4Image}
            />
          </div>
        </div>

        <div className="homeSection5">
          <p className="mb-0 mt-3">Section5 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec5Heading}
            onChange={(e) => setSec5Heading(e.target.value)}
          />
        </div>

        <div className="homeSection6">
          <p className="mb-0 mt-3">Section6 heading</p>
          <input
            className="admin_Input_cms"
            type="text"
            name="section1 heading"
            value={sec6Heading}
            onChange={(e) => setSec6Heading(e.target.value)}
          />
          <p className="mb-0 mt-3">Section6 description</p>
          <textarea
            className="admin_Input_cms"
            rows="4"
            cols="50"
            value={sec6Description}
            onChange={(e) => setSec6Description(e.target.value)}
          ></textarea>
          <p className="mb-3 mt-3">Section6 Image</p>
          <div className="section1-img-box">
            <AddImageComponent
              index={null}
              item={sec6Image}
              onSubmit={(data) => setSec6Image(data)}
              initialState={sec6Image}
            />
          </div>
        </div>
        <div className="home-cms-btn mt-3">
          <MuiButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className="mt-3 custom-btn rounded-lg radius-8 ms-4"
            disabled={isApiCall}
          >
            {isApiCall ? "Updating..." : "Update"}
          </MuiButton>
        </div>
      </section>
    </>
  );
};

export default Covid19Cms;
