import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
import { FaImage } from "react-icons/fa";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";

const AddCategoryModal = ({ open, setOpen, onUpdate, updating, prevData }) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [name, setName] = useState(prevData?.catName);
  const [icon, setIcon] = useState();
  // const [description, setDescription] = useState();
  const iconInput = useRef();

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title text-color text-left">
          {prevData ? "Edit" : "Add"} Category
        </span>
        <div data-validate="Category Name is required">
          {updating && <span className="label-input100">Name</span>}
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="category-name"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="validate-input mt-4" data-validate="Image is required">
          <span className="add-evidence-modal-label">Add Image</span>
          <div className="d-flex flex-wrap mt-3">
            {icon?.name ? (
              <div className="add-evidence-modal-image-box position-relative mr-2 mb-2">
                <IoMdClose
                  size={15}
                  className="position-absolute btn-color text-white"
                  onClick={() => setIcon({})}
                  style={{ top: "5px", right: "5px", borderRadius: "3px" }}
                />
                <img src={URL.createObjectURL(icon)} className="w-100 h-100" />
              </div>
            ) : (
              <div
                className="add-evidence-modal-image-box d-flex align-items-center justify-content-center mr-2 mb-2"
                onClick={() => {
                  iconInput.current.click();
                }}
              >
                <FaImage className="add-evidence-modal-image-box-center-icon" />
              </div>
            )}
          </div>
        </div>

        <input
          ref={iconInput}
          className="d-none"
          type="file"
          onChange={(e) => setIcon(e.target.files[0])}
        />

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isApiCall}
              class="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                if (!name) return toast.warning("Category name is required");
                setIsApiCall(true);
                onUpdate(name, icon, setIsApiCall);
              }}
            >
              {updating
                ? isApiCall
                  ? "Editing..."
                  : "Edit"
                : isApiCall
                ? "Adding..."
                : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddCategoryModal;
