import { UpdateRounded } from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";

const AddCityModal = ({ open, setOpen, onUpdate, updating, prevData }) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [cityName, setCityName] = useState(prevData?.name);
  const [cityId, setCityId] = useState(prevData?._id);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title text-color text-left">
          {`${updating ? "Edit" : "Add"}`} City
        </span>
        <div data-validate="City Name is required">
          {updating && <span className="label-input100">Name</span>}
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="city-name"
            placeholder="Name"
            value={cityName}
            onChange={(e) => {
              setCityName(e.target.value);
            }}
          />
        </div>

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isApiCall}
              class="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                updating
                  ? onUpdate(cityName.trim(), cityId, setIsApiCall)
                  : onUpdate(cityName.trim(), setIsApiCall);
              }}
            >
              {updating
                ? isApiCall
                  ? "Editing..."
                  : "Edit"
                : isApiCall
                ? "Adding..."
                : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddCityModal;
