import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";
import MuiButton from "@mui/material/Button";
import {
  BaseURL,
  apiHeader,
  imageUrl,
  groupArrayOfObjects,
} from "../Config/apiUrl";
import { IoMdAddCircleOutline } from "react-icons/io";
import UpdatePageSectionModal from "../components/AddOrUpdatePageSectionModal";
import { toast } from "react-toastify";

// Mui Button

const PageSections = () => {
  const history = useHistory();

  const [limit, setLimit] = useState(1000);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isAddOrUpdate, setIsAddOrUpdate] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [deletingId, setDeletingId] = useState("");
  const [pageSectionData, setPageSectionData] = useState([]);

  // loading states
  const [isAddingApiCall, setIsAddingApiCall] = useState(false);
  const [isEditingApiCall, setIsEditingApiCall] = useState(false);
  const [isDeletingApiCall, setIsDeletingApiCall] = useState(false);

  // getAllPageSections
  const getAllPageSections = async (pageNo, newLimit) => {
    setIsGetting(true);
    const url = BaseURL(`page/?page=${pageNo}&limit=${newLimit}`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      let crudArray = responseData?.data?.data;
      let groupedArray = groupArrayOfObjects(crudArray, "secName");

      let solutionInOneApp = groupedArray["solution-in-one-app"];
      let howCanDaweeyeHelp = groupedArray["how-can-daweeye-help"];
      let appStoreLinks = groupedArray["app-store-links"];
      let whatPeopleSayAboutUs = groupedArray["what-people-say-about-us"];
      let visionAndMissionAnalysis1 =
        groupedArray["vision-and-mission-analysis-1"];
      let visionAndMissionAnalysis2 =
        groupedArray["vision-and-mission-analysis-2"];
      let problemWorthSolving = groupedArray["problem-worth-solving"];
      let productsAndService = groupedArray["products-and-service"];
      let uniqueSellingProposition = groupedArray["unique-selling-proposition"];
      let businessStrategies1 = groupedArray["business-strategies-1"];
      let businessStrategies2 = groupedArray["business-strategies-2"];
      let fourEasyStepsToFindYourSolution =
        groupedArray["four-easy-steps-to-find-your-solution"];
      let exploreByCategory = groupedArray["explore-by-category"];
      let transmissionOfCovid19 = groupedArray["transmission-of-covid19"];
      let latestUpdateAboutCovid19 =
        groupedArray["latest-update-about-covid19"];
      let workingForYourBetterHealth =
        groupedArray["working-for-your-better-health"];
      let socialLink = groupedArray["social-link"];

      setPageSectionData([
        ...solutionInOneApp,
        ...howCanDaweeyeHelp,
        ...appStoreLinks,
        ...whatPeopleSayAboutUs,
        ...visionAndMissionAnalysis1,
        ...visionAndMissionAnalysis2,
        ...problemWorthSolving,
        ...productsAndService,
        ...uniqueSellingProposition,
        ...businessStrategies1,
        ...businessStrategies2,
        ...fourEasyStepsToFindYourSolution,
        ...exploreByCategory,
        ...transmissionOfCovid19,
        ...latestUpdateAboutCovid19,
        ...workingForYourBetterHealth,
        ...socialLink,
      ]);
      // setPageSectionData(responseData?.data?.data);
    }
    setIsGetting(false);
  };

  // update category
  const addPageSection = async (title, description, socialUrl, icon) => {
    const url = BaseURL(`page/${editingData?._id}`);
    const params = new FormData();
    title && params.append("title", title);
    description && params.append("description", description);
    socialUrl && params.append("url", socialUrl);
    icon && icon.name && params.append("icon", icon);

    setIsEditingApiCall(true);
    const responseData = await Patch(url, params, apiHeader(accessToken, true));
    setIsEditingApiCall(false);
    if (responseData !== undefined) {
      setIsAddOrUpdate(false);
      const newData = [...pageSectionData];
      newData.splice(
        newData.findIndex((val) => val._id == editingData?._id),
        1,
        responseData?.data?.data
      );
      setPageSectionData(newData);
      toast.success("Page Section Updated Successfully!");
    }
  };

  // update category
  const updatePageSection = async (params, emptyStates) => {
    const formData = new FormData();
    for (let key in params) formData.append(key, params[key]);

    const url = editingData
      ? BaseURL(`page/${editingData?._id}`)
      : BaseURL("page");

    const fn = editingData ? Patch : Post;

    setIsEditingApiCall(true);
    const response = await fn(url, formData, apiHeader(accessToken, true));
    setIsEditingApiCall(false);

    if (response !== undefined) {
      setIsAddOrUpdate(false);
      toast.success(`Item ${editingData ? "updated" : "added"} successfully.`);
      emptyStates();

      //   if we added the item
      if (!editingData) setPageSectionData((p) => [response?.data?.data, ...p]);

      //   if we updated
      if (editingData) {
        const dataCopy = [...pageSectionData];
        const index = dataCopy.findIndex((e) => e?._id === editingData?._id);
        dataCopy[index] = response?.data?.data;
        setPageSectionData(dataCopy);
      }

      setEditingData(null);
    }
  };

  // delete category
  const deleteItemHandler = async () => {
    const url = BaseURL(`page/${deletingId}`);

    setIsDeletingApiCall(true);
    const responseData = await Delete(url, null, apiHeader(accessToken));
    setIsDeletingApiCall(false);
    if (responseData !== undefined) {
      setIsDeleting(false);
      toast.success("Item Deleted Successfully!");
      setPageSectionData((p) => p.filter((item) => item._id !== deletingId));
    }
  };

  useEffect(() => {
    getAllPageSections(1, limit);
  }, [pageNumber]);

  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getAllPageSections(newPageNumber, limit);
  };

  return (
    <section className="reported-ads pb-4">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>PAGE SECTIONS</h1>
      </div>

      <div className="fieldSetMainContainer mt-3">
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center">
            <p className="m-0 me-3 fw-bold">Items Per Page:</p>
            <select
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPageNumber(1);
                HandlepageChange(1, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={10} className="pt-2">
                10
              </option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div>

          <div className="d-flex align-items-center">
            <MuiButton
              onClick={() => {
                setEditingData(null);
                setIsAddOrUpdate(true);
              }}
              variant="contained"
              color="primary"
              className="custom-btn rounded-lg radius-8 ms-4"
              startIcon={<IoMdAddCircleOutline />}
            >
              Add
            </MuiButton>
          </div>
        </div>
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <>
            <div className="col-md-12 table_recent">
              <div className="table table-responsive">
                <table>
                  {/* <thead className="invoice-head">
                    <tr>
                      <th>S.No</th>
                      <th>Title</th>
                      <th>Sub Title</th>
                      <th>Description</th>
                      <th>Rating</th>
                      <th>Image</th>
                      <th>Cover Image</th>
                      <th>Section Name</th>
                      <th>Social Links</th>
                      <th>Actions</th>
                    </tr>
                  </thead> */}
                  <>
                    {pageSectionData?.map((e, i) => (
                      <div key={i} className="table-main-row">
                        {e?.secName !== pageSectionData[i - 1]?.secName && (
                          <h2 className="t-t-c mb-3">
                            {e?.secName?.replaceAll("-", " ")}
                          </h2>
                        )}

                        <thead
                          className={`${
                            e?.secName !== pageSectionData[i - 1]?.secName
                              ? "invoice-head"
                              : "invoice-head custom-class"
                          }`}
                        >
                          <tr>
                            <th>S.NO</th>
                            <th>Section Name</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Long Description</th>
                            <th>Subtitle</th>
                            <th>Icon</th>
                            <th>Url</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody className="w-100">
                          <tr className="invoice-row c-p" key={e?._id}>
                            <td>{i}</td>
                            <td>{e?.secName || "-"}</td>
                            <td>{e?.title || "-"}</td>
                            <td>
                              {e?.description ? (
                                <ShowMoreShowLessText
                                  text={e?.description}
                                  visibility={10}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {e?.description2 ? (
                                <ShowMoreShowLessText
                                  text={e?.description2}
                                  visibility={20}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {e?.subTitle ? (
                                <ShowMoreShowLessText
                                  text={e?.subTitle}
                                  visibility={20}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {e?.image ? (
                                <div className="category-icon-box">
                                  <img
                                    src={imageUrl + e?.image}
                                    className="w-100 h-100"
                                  />
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>

                            <td>{e?.url || "-"}</td>

                            <td>
                              <ul className="d-flex list-unstyled justify-content-center">
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={() => {
                                    setEditingData(e);
                                    setIsAddOrUpdate(true);
                                  }}
                                >
                                  <Button className="update-btn">Edit</Button>
                                </li>
                                <li
                                  className="page-item align-self-end ml-4"
                                  onClick={() => {
                                    setDeletingId(e?._id);
                                    setIsDeleting(true);
                                  }}
                                >
                                  <Button className="delete-btn">Delete</Button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>

                        {e?.secName !== pageSectionData[i + 1]?.secName && (
                          <div
                            style={{
                              marginBottom: "50px",
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </>
                </table>
              </div>
            </div>
          </>
        )}

        {/* <Pagination
          maxLastLength={limit}
          array={pageSectionData}
          HandlepageChange={HandlepageChange}
          pageNumber={pageNumber}
        /> */}
      </div>

      {/* AreYouSureModal */}
      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message="Do you really want to delete this Item?"
        handleYes={() => deleteItemHandler()}
        isLoading={isDeletingApiCall}
      />

      {isAddOrUpdate && (
        <UpdatePageSectionModal
          open={isAddOrUpdate}
          setOpen={setIsAddOrUpdate}
          onUpdate={updatePageSection}
          data={editingData}
          isLoading={isEditingApiCall}
        />
      )}
    </section>
  );
};

export default PageSections;
