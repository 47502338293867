import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import { Col, Container, Row } from "react-bootstrap";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import AddUserModal from "../components/AddUserModal";
import ViewDoctorAndCovidLab from "../components/ViewDoctorAndCovidLab";
import { toast } from "react-toastify";
import PaginationComponent from "../components/PaginationComponent/index";

// Mui Button

const Users = () => {
  const history = useHistory();
  const [usersType, setUsersType] = useState("doctor");
  const [accountType, setAccountType] = useState(true);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const currentUser = useSelector((state) => state.authReducer.user);
  const [isGetting, setIsGetting] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isAddingAppointment, setIsAddingAppointment] = useState(false);
  const [isAddingApiCall, setIsAddingApiCall] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [activatingId, setActivatingId] = useState("");
  const [deactivatingId, setDeactivatingId] = useState("");
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [search, setSearch] = useState("");
  const [cities, setCities] = useState([]);
  const [specializations, setSpecializations] = useState([]);

  const getAllUsers = async (
    pageNo,
    newLimit,
    newUserType,
    newAccountType,
    search
  ) => {
    setIsGetting(true);
    const url = search
      ? BaseURL(
          `users/get-by-admin?role=${newUserType}&isActive=${newAccountType}&search=${search}&page=${pageNo}&limit=${newLimit}`
        )
      : BaseURL(
          `users/get-by-admin?role=${newUserType}&isActive=${newAccountType}&page=${pageNo}&limit=${newLimit}`
        );

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setLimit(responseData?.data?.totalRecords);
      setUsersData(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const addUser = async (data, setIsApiCall) => {
    if (data?.password.length < 8)
      return toast.warn("Password must be at least 8 characters long.");

    setIsApiCall(true);
    const url = BaseURL(`users/admin-signup`);
    let params = {
      ...data,
    };

    const responseData = await Post(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success("User Added Successfully");
      setIsAddingUser(false);
      setUsersType(data.role);
      getAllUsers(1, recordsLimit, data.role, accountType);
      return;
    }
    setIsApiCall(false);
  };

  const updateUserStatus = async (status, id) => {
    const url = BaseURL(`users/approveOrDisApprove/${id}`);
    let params = {
      isActive: status,
    };

    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      status === true && toast.success(`${usersType} activated successfully`);
      status === false &&
        toast.success(`${usersType} deactivated successfully`);
      setIsActivating(false);
      setIsDeactivating(false);
      getAllUsers(1, recordsLimit, usersType, accountType);
      return;
    }
    getAllUsers(1, recordsLimit, usersType, accountType);
  };

  const bookAppointment = async (data) => {
    const url = BaseURL(`appointment/book`);

    const responseData = await Post(url, data, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Appointment booked successfully`);
      setIsAddingAppointment(false);
      getAllUsers(1, recordsLimit, usersType, accountType);
      return;
    }
    getAllUsers(1, recordsLimit, usersType, accountType);
  };

  const getAllCities = async () => {
    const url = BaseURL(`city?noPagination=true`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setCities(responseData?.data?.data);
    } else {
      console.log("error");
    }
  };

  const getAllSpecializations = async () => {
    const url = BaseURL(`specialization?noPagination=true`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setSpecializations(responseData?.data?.data);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getAllUsers(1, recordsLimit, usersType, accountType);
    getAllCities();
    getAllSpecializations();
  }, []);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllUsers(page, recordsLimit, usersType, accountType);
  };

  const handleSearch = () => {
    getAllUsers(1, recordsLimit, usersType, accountType, search);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>USERS</h1>
      </div>

      <div className="fieldSetMainContainer">
        {/* Add Section Button Container */}
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center">
            <p className="m-0 me-3 fw-bold">Please Choose User Type:</p>
            <select
              value={usersType}
              onChange={(e) => {
                setUsersType(e.target.value);
                setPageNumber(1);
                getAllUsers(1, recordsLimit, e.target.value, accountType);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value="doctor" className="pt-2">
                Doctor
              </option>
              <option value="patient">Patient</option>
            </select>
          </div>

          <div className="d-flex align-items-center ms-3">
            <p className="m-0 me-3 fw-bold">Status:</p>
            <select
              value={accountType}
              onChange={(e) => {
                setAccountType(e.target.value);
                setPageNumber(1);
                getAllUsers(1, recordsLimit, usersType, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={true} className="pt-2">
                Active
              </option>
              <option value={false}>Deactive</option>
            </select>
          </div>
        </div>

        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center">
            <div class="input-group">
              <input
                type={search}
                className="form-control"
                placeholder="Email & Phonenumber"
                aria-label=" username"
                aria-describedby="basic-addon2"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div class="input-group-append">
                <button
                  className="custom-btn login100-form-btn"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {currentUser.role != "manager" && (
            <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => setIsAddingUser(true)}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div>
          )}
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    {/* <th>City</th> */}
                    <th>Phone Number</th>
                    {usersType === "vendor" && <th>Company Name</th>}
                    {/* <th>Last Updated</th> */}
                    <th>Active</th>
                    <th>Verified</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {usersData.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan={usersType === "vendor" ? 10 : 9}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {usersData?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (pageNumber - 1) * recordsLimit}</td>
                          <td>{item?.firstName}</td>
                          <td>{item?.lastName}</td>
                          <td>{item?.email}</td>
                          {/* <td>{item?.city}</td> */}
                          <td>{`${item?.countryCode}${item?.phoneNumber}`}</td>
                          {/* {usersType === 'vendor' && <td>{item?.companyName}</td>} */}
                          {/* <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td> */}
                          <td>{item?.isActive ? "Yes" : "No"}</td>
                          <td>{item?.isVerified ? "Yes" : "No"}</td>
                          <td>
                            <ul className="appointmentUL px-2">
                              {item?.isActive && item?.isVerified ? (
                                <>
                                  {currentUser.role == "admin" && (
                                    <li
                                      className="page-item "
                                      onClick={() => {
                                        setDeactivatingId(item?._id);
                                        setIsDeactivating(true);
                                      }}
                                    >
                                      <Button className="delete-btn">
                                        Deactivate
                                      </Button>
                                    </li>
                                  )}
                                  {usersType == "patient" && item.isVerified && (
                                    <li>
                                      <MuiButton
                                        onClick={() => {
                                          setIsAddingAppointment(true);
                                          setPatientId(item._id);
                                        }}
                                        variant="contained"
                                        color="primary"
                                        className="custom-btn rounded-lg radius-8"
                                        startIcon={<IoMdAddCircleOutline />}
                                      >
                                        Book Appointment
                                      </MuiButton>
                                    </li>
                                  )}
                                </>
                              ) : currentUser.role == "admin" &&
                                item.isVerified ? (
                                <li
                                  className="page-item "
                                  onClick={() => {
                                    setActivatingId(item?._id);
                                    setIsActivating(true);
                                  }}
                                >
                                  <Button className="update-btn">
                                    Activate
                                  </Button>
                                </li>
                              ) : (
                                "-"
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Col md={12} className="paginationContainer">
          {usersData?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      {isAddingUser && (
        <AddUserModal
          open={isAddingUser}
          setOpen={setIsAddingUser}
          onUpdate={addUser}
          type={"User"}
        />
      )}

      {isAddingAppointment && (
        <ViewDoctorAndCovidLab
          open={isAddingAppointment}
          setOpen={setIsAddingAppointment}
          onUpdate={bookAppointment}
          isLoading={isAddingApiCall}
          patientId={patientId}
          cities={cities}
          specializations={specializations}
        />
      )}

      {isActivating && (
        <AreYouSureModal
          open={isActivating}
          setOpen={setIsActivating}
          label={"Are You Sure?"}
          message={`Do you really want to activate this ${usersType}?`}
          handleYes={() => updateUserStatus(true, activatingId)}
        />
      )}

      {isDeactivating && (
        <AreYouSureModal
          open={isDeactivating}
          setOpen={setIsDeactivating}
          label={"Are You Sure?"}
          message={`Do you really want to deactivate this ${usersType}?`}
          handleYes={() => updateUserStatus(false, deactivatingId)}
        />
      )}
    </section>
  );
};

export default Users;
