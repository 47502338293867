import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import { useHistory } from "react-router";
import { BaseURL, apiHeader } from "../Config/apiUrl";
import { toast } from "react-toastify";

const TermsAndCondition = () => {
  const history = useHistory();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);
  const [termsId, setTermsId] = useState();

  const [terms, setTerms] = useState([]);

  const getAllTerms = async () => {
    setIsGetting(true);
    const url = BaseURL(`terms`);

    const responseData = await Get(url);
    if (responseData !== undefined) {
      setTerms(responseData?.data?.data.content);
      setTermsId(responseData?.data?.data?._id);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const updateTerms = async (data, termId) => {
    const url = BaseURL(`terms/${termId}`);
    let params = {
      content: data,
    };

    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Updated successfully`);
      getAllTerms();
      return;
    }
    getAllTerms();
  };

  useEffect(() => {
    getAllTerms();
  }, []);

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>TERMS & CONDITION</h1>
      </div>

      <div className="fieldSetMainContainer">
        <div className="place-add-btn-container mb-4">
          <div className="d-flex">
            <div className="d-flex align-items-center"></div>
          </div>
        </div>
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <ReactQuill
            value={terms}
            onChange={(e) => {
              setTerms(e);
            }}
          />
        )}
      <MuiButton
        onClick={() => updateTerms(terms, termsId)}
        variant="contained"
        color="primary"
        className="mt-3 custom-btn rounded-lg radius-8 ms-4"
      >
        Update
      </MuiButton>
      </div>
    </section>
  );
};

export default TermsAndCondition;
