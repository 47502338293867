import React, { useEffect, useState } from "react";
import classes from "./datepicker.module.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
// import enLocale from "date-fns/locale/en-US";

export const DatePicker = ({ onUpdate }) => {
  const [value, setValue] = useState([null, null]);

  useEffect(async () => {
    await onUpdate(value);
  }, [value]);

  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
    //   <style jsx>{`
    //       .PrivatePickersSlideTransition-root {
    //         min-height: 256px !important;
    //         // min-height: 220px !important;
    //       }
    //       .MuiDateRangePickerDay-rangeIntervalDayHighlight {
    //         background-color: #EDF0F7;
    //       }
    //       .Mui-selected {
    //         background-color: #4D55E5 !important;
    //       }
    //       .MuiTypography-subtitle1 {
    //         font-style: normal;
    //         font-family: "inter-600";
    //         font-size: 16px;
    //         line-height: 24px;
    //         color: #222222;
    //       }
    //       .MuiDateRangePickerDay-day {
    //         font-style: normal;
    //         font-family: "inter-600";
    //         font-size: 14px;
    //         line-height: 16px;
    //         color: #222222;
    //       }
    //       .Mui-disabled {
    //         color: #B5B5B5;
    //       }
    //       .mehru span.MuiTypography-caption {
    //         position: relative;
    //         font-size: 0;
    //       }
    //       .mehru span.MuiTypography-caption:before {
    //         font-family: 'inter-500';
    //         font-size: 14px;
    //         line-height: 20px;
    //         color: #717171;
    //         position: absolute;
    //         left: 15px;
    //       }
    //       .mehru span.MuiTypography-caption:nth-child(1):before {
    //         content: 'Sun'
    //       }
    //       .mehru span.MuiTypography-caption:nth-child(2):before {
    //         content: 'Mon'
    //       }
    //       .mehru span.MuiTypography-caption:nth-child(3):before {
    //         content: 'Tue'
    //       }
    //       .mehru span.MuiTypography-caption:nth-child(4):before {
    //         content: 'Wed'
    //       }
    //       .mehru span.MuiTypography-caption:nth-child(5):before {
    //         content: 'Thu'
    //       }
    //       .mehru span.MuiTypography-caption:nth-child(6):before {
    //         content: 'Fri'
    //       }
    //       .mehru span.MuiTypography-caption:nth-child(7):before {
    //         content: 'Sat'
    //       }
    //   `}</style>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={{ start: "From", end: "To" }}
    >
      <DateRangePicker
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};
