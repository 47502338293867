import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import AddCityModal from "../components/AddCityModal";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../components/PaginationComponent/index";

// Mui Button

const City = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddingCity, setIsAddingCity] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [statusType, setStatusType] = useState(true);
  const [cityId, setCityId] = useState("");
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [
    isActivatingOrDeactivatingApiCall,
    setIsActivatingOrDeactivatingApiCall,
  ] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [cities, setCities] = useState([]);

  const getAllCities = async (pageNo, newLimit, status) => {
    setIsGetting(true);
    const url = BaseURL(
      `city?page=${pageNo}&limit=${newLimit}&status=${status}`
    );

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setLimit(responseData?.data?.totalRecords);
      setCities(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const addCity = async (data, setIsApiCall) => {
    setIsApiCall(true);
    const url = BaseURL(`city`);
    let params = {
      name: data,
    };

    const responseData = await Post(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success("Added successfully");
      setIsAddingCity(false);
      setCities((p) => [responseData?.data?.doc1, ...p]);
      // getAllCities(1, limit, statusType);
      return;
    }
    setIsApiCall(false);
  };

  const updateCity = async (data, cityId, setIsApiCall) => {
    setIsApiCall(true);
    const url = BaseURL(`city/${cityId}`);
    let params = {
      name: data,
    };

    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      const newData = [...cities];
      newData.splice(
        newData.findIndex((val) => val._id == cityId),
        1,
        responseData?.data?.doc
      );
      setCities(newData);
      toast.success(`Updated successfully`);
      setIsEditing(false);
      return;
    }
  };

  const activeOrDeactiveCity = async (type) => {
    const url = BaseURL(`city/active-deactive/${cityId}`);

    const params = {
      ...(type == "activate" ? { status: true } : { status: false }),
    };
    setIsActivatingOrDeactivatingApiCall(true);
    setIsActivating(false);
    setIsDeactivating(false);
    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsActivatingOrDeactivatingApiCall(false);
      const newData = [...cities];
      newData.splice(
        newData.findIndex((val) => val._id == cityId),
        1,
        responseData?.data?.doc
      );
      setCities(newData);
      toast.success(
        `City ${type == "activate" ? "Activated" : "Deactivated"} Successfully!`
      );
    }
  };

  const deleteCity = async () => {
    const url = BaseURL(`city/${deletingId}`);

    const responseData = await Delete(url, null, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Deleted successfully`);
      getAllCities(1, recordsLimit, statusType);
      setIsDeleting(false);
      return;
    }
  };

  useEffect(() => {
    getAllCities(1, recordsLimit, statusType);
  }, []);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllCities(page, recordsLimit);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>CITY</h1>
      </div>

      <div className="fieldSetMainContainer">
        {/* Add Section Button Container */}
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center ms-3">
            <p className="m-0 me-3 fw-bold">Status:</p>
            <select
              value={statusType}
              onChange={(e) => {
                setStatusType(e.target.value);
                setPageNumber(1);
                getAllCities(1, recordsLimit, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={true} className="pt-2">
                Active
              </option>
              <option value={false}>Deactive</option>
            </select>
          </div>
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => setIsAddingCity(true)}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div>
          </div>
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Name</th>
                    <th>Last Updated</th>
                    {/* <th>Active</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {cities.length < 1 && (
                    <tr className="no-data-table-row">
                      {/* {/* {" "} */}
                      <td colSpan={9}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {cities?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (pageNumber - 1) * recordsLimit}</td>
                          <td>{item?.name}</td>
                          {/* {usersType === 'vendor' && <td>{item?.companyName}</td>} */}
                          <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          {/* <td>{item?.isActive ? "Yes" : "No"}</td> */}
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsEditing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Edit
                                </Button>
                              </li>
                              {item?.isActive ? (
                                <li
                                  className="page-item align-self-end ml-4"
                                  onClick={() => {
                                    setCityId(item?._id);
                                    setIsDeactivating(true);
                                  }}
                                >
                                  <Button className="delete-btn">
                                    Deactive
                                  </Button>
                                </li>
                              ) : (
                                <li
                                  className="page-item align-self-end ml-4"
                                  onClick={() => {
                                    setCityId(item?._id);
                                    setIsActivating(true);
                                  }}
                                >
                                  <Button className="delete-btn">Active</Button>
                                </li>
                              )}
                              {/* <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsDeleting(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li> */}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Col md={12} className="paginationContainer">
          {cities?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message={`Do you really want to delete this city?`}
        handleYes={() => deleteCity()}
      />

      {isActivating && (
        <AreYouSureModal
          open={isActivating}
          setOpen={setIsActivating}
          label={"Are You Sure?"}
          message="Do you really want to activate this city?"
          handleYes={() => activeOrDeactiveCity("activate")}
          isLoading={isActivatingOrDeactivatingApiCall}
        />
      )}

      {isDeactivating && (
        <AreYouSureModal
          open={isDeactivating}
          setOpen={setIsDeactivating}
          label={"Are You Sure?"}
          message="Do you really want to deactivate this city?"
          handleYes={() => activeOrDeactiveCity("deactivate")}
          isLoading={isActivatingOrDeactivatingApiCall}
        />
      )}

      {isAddingCity && (
        <AddCityModal
          open={isAddingCity}
          setOpen={setIsAddingCity}
          onUpdate={addCity}
          type={"Add"}
        />
      )}
      {isEditing && (
        <AddCityModal
          open={isEditing}
          setOpen={setIsEditing}
          onUpdate={updateCity}
          updating={"Edit"}
          prevData={editingData}
        />
      )}
    </section>
  );
};

export default City;
