import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import MuiButton from "@mui/material/Button";
import { BaseURL, apiHeader, imageUrl, recordsLimit } from "../Config/apiUrl";
import { IoMdAddCircleOutline } from "react-icons/io";
import AddSpecializationModal from "../components/AddSpecializationModal";
import UpdateSpecializationModal from "../components/UpdateSpecializationModal";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../components/PaginationComponent/index";
// Mui Button

const Specialization = () => {
  const history = useHistory();

  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [statusType, setStatusType] = useState(true);
  const [isActivating, setIsActivating] = useState(false);
  const [specializationId, setSpecializationId] = useState("");
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState({});
  const [deletingId, setDeletingId] = useState("");
  const [specializationData, setSpecializationData] = useState([]);

  // loading states
  const [isAddingApiCall, setIsAddingApiCall] = useState(false);
  const [isEditingApiCall, setIsEditingApiCall] = useState(false);
  const [isDeletingApiCall, setIsDeletingApiCall] = useState(false);
  const [
    isActivatingOrDeactivatingApiCall,
    setIsActivatingOrDeactivatingApiCall,
  ] = useState(false);

  // getAllSpecializations
  const getAllSpecializations = async (pageNo, newLimit, status) => {
    setIsGetting(true);
    const url = BaseURL(
      `specialization/?page=${pageNo}&limit=${newLimit}&status=${status}`
    );

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setLimit(responseData?.data?.totalRecords);
      setSpecializationData(responseData?.data?.data);
    }
    setIsGetting(false);
  };

  const addSpecialization = async (name, icon) => {
    const url = BaseURL(`specialization/`);
    const params = new FormData();
    params.append("name", name);
    icon.name && params.append("icon", icon);

    setIsAddingApiCall(true);
    const responseData = await Post(url, params, apiHeader(accessToken, true));
    setIsAddingApiCall(false);
    if (responseData !== undefined) {
      setIsAdding(false);
      setSpecializationData((p) => [responseData?.data?.data, ...p]);
      toast.success("Specialization Added Successfully!");
    }
  };

  // update category
  const updateSpecialization = async (name, icon) => {
    const url = BaseURL(`specialization/${editingData?._id}`);
    const params = new FormData();
    params.append("name", name);
    icon.name && params.append("icon", icon);
    params.append("id", editingData?._id);

    setIsEditingApiCall(true);
    const responseData = await Patch(url, params, apiHeader(accessToken, true));
    setIsEditingApiCall(false);
    if (responseData !== undefined) {
      setIsEditing(false);
      const newData = [...specializationData];
      newData.splice(
        newData.findIndex((val) => val._id == editingData?._id),
        1,
        responseData?.data?.data
      );
      setSpecializationData(newData);
      toast.success("Specialization Updated Successfully!");
    }
  };

  // active or deactive specialization
  const activeOrDeactiveSpecialization = async (type) => {
    const url = BaseURL(`specialization/active-deactive/${specializationId}`);

    const params = {
      ...(type == "activate" ? { status: true } : { status: false }),
    };
    setIsActivatingOrDeactivatingApiCall(true);
    setIsActivating(false);
    setIsDeactivating(false);
    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsActivatingOrDeactivatingApiCall(false);
      const newData = [...specializationData];
      newData.splice(
        newData.findIndex((val) => val._id == specializationId),
        1,
        responseData?.data?.data
      );
      setSpecializationData(newData);
      toast.success(
        `Specialization ${
          type == "activate" ? "Activated" : "Deactivated"
        } Successfully!`
      );
    }
  };

  // delete category
  const deleteSpecialization = async () => {
    const url = BaseURL(`specialization/${deletingId}`);

    setIsDeletingApiCall(true);
    const responseData = await Delete(url, null, apiHeader(accessToken));
    setIsDeletingApiCall(false);
    if (responseData !== undefined) {
      setIsDeleting(false);
      toast.success("Specialization Deleted Successfully!");
      setSpecializationData((p) => p.filter((item) => item._id !== deletingId));
    }
  };

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllSpecializations(page, recordsLimit, statusType);
  };

  useEffect(() => {
    getAllSpecializations(1, recordsLimit, statusType);
  }, []);

  return (
    <section className="reported-ads pb-4">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>SPECIALIZATION</h1>
      </div>

      <div className="fieldSetMainContainer">
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center ms-3">
            <p className="m-0 me-3 fw-bold">Status:</p>
            <select
              value={statusType}
              onChange={(e) => {
                setStatusType(e.target.value);
                setPageNumber(1);
                getAllSpecializations(1, recordsLimit, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={true} className="pt-2">
                Active
              </option>
              <option value={false}>Deactive</option>
            </select>
          </div>
          <div className="d-flex align-items-center">
            <MuiButton
              onClick={() => setIsAdding(true)}
              variant="contained"
              color="primary"
              className="custom-btn rounded-lg radius-8"
              startIcon={<IoMdAddCircleOutline />}
            >
              Add
            </MuiButton>
          </div>
        </div>
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <>
            <div className="col-md-12 table_recent">
              <div className="table table-responsive">
                <table id="dataTable" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Icon</th>
                      <th>Name</th>
                      <th>Last Updated</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {specializationData.length < 1 && (
                      <tr className="no-data-table-row">
                        <td colSpan={6}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                    {specializationData?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>
                              {index + 1 + (pageNumber - 1) * recordsLimit}
                            </td>
                            <td>
                              <div className="category-icon-box">
                                {" "}
                                <img
                                  src={imageUrl + item?.icon}
                                  className="w-100 h-100"
                                />
                              </div>
                            </td>
                            <td>{item?.name}</td>
                            {/* <td>{item?.type}</td> */}
                            {/* <td> */}
                            {/* <ul className="list-unstyled">
                                {item?.subCat.map((subData, index) => {
                                  return (
                                    <li>{`${++index}) ${
                                      subData?.sub_catName
                                    }`}</li>
                                  );
                                })}
                              </ul> */}
                            {/* </td> */}
                            <td>
                              {moment(item?.updatedAt).format(
                                "ddd, DD MMM YYYY hh:mm A"
                              )}
                            </td>

                            <td>
                              <ul className="d-flex list-unstyled justify-content-center">
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={() => {
                                    setEditingData(item);
                                    setIsEditing(true);
                                  }}
                                >
                                  <Button className="update-btn">Edit</Button>
                                </li>
                                {item?.isActive ? (
                                  <li
                                    className="page-item align-self-end ml-4"
                                    onClick={() => {
                                      setSpecializationId(item?._id);
                                      setIsDeactivating(true);
                                    }}
                                  >
                                    <Button className="delete-btn">
                                      Deactive
                                    </Button>
                                  </li>
                                ) : (
                                  <li
                                    className="page-item align-self-end ml-4"
                                    onClick={() => {
                                      setSpecializationId(item?._id);
                                      setIsActivating(true);
                                    }}
                                  >
                                    <Button className="delete-btn">
                                      Active
                                    </Button>
                                  </li>
                                )}
                              </ul>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        <Col md={12} className="paginationContainer">
          {specializationData?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      {/* AreYouSureModal */}
      {/* <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message="Do you really want to delete this specialization?"
        handleYes={() => deleteSpecialization()}
        isLoading={isDeletingApiCall}
      /> */}
      {isAdding && (
        <AddSpecializationModal
          open={isAdding}
          setOpen={setIsAdding}
          onUpdate={addSpecialization}
          isLoading={isAddingApiCall}
        />
      )}

      {isActivating && (
        <AreYouSureModal
          open={isActivating}
          setOpen={setIsActivating}
          label={"Are You Sure?"}
          message="Do you really want to activate this specialization?"
          handleYes={() => activeOrDeactiveSpecialization("activate")}
          isLoading={isActivatingOrDeactivatingApiCall}
        />
      )}

      {isDeactivating && (
        <AreYouSureModal
          open={isDeactivating}
          setOpen={setIsDeactivating}
          label={"Are You Sure?"}
          message="Do you really want to deactivate this specialization?"
          handleYes={() => activeOrDeactiveSpecialization("deactivate")}
          isLoading={isActivatingOrDeactivatingApiCall}
        />
      )}

      {isEditing && (
        <UpdateSpecializationModal
          open={isEditing}
          setOpen={setIsEditing}
          onUpdate={updateSpecialization}
          data={editingData}
          isLoading={isEditingApiCall}
        />
      )}
    </section>
  );
};

export default Specialization;
