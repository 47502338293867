import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get, Post, Delete, Patch } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, imageUrl } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import UpdateCovidLabModal from "../components/UpdateCovidLabModal";
import ViewCovidLabModal from "../components/ViewCovidLabModal";
// Mui Button
import { toast } from "react-toastify";

function CovidLab() {
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [covidLabData, setCovidLabData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState();
  const [viewCovidLabData, setViewCovidLabData] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false);
  // const [deletingId, setDeletingId] = useState("");

  const getCovidLab = async (pageNo, newLimit) => {
    setIsGetting(true);
    const url = BaseURL(`users/get-by-admin?role=covid19&isActive=true`);
    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setCovidLabData(responseData?.data?.data);
    }

    setIsGetting(false);
  };

  const updateCovidLab = async (
    firstName,
    lastName,
    email,
    countryCode,
    phoneNumber,
    completePhoneNumber,
    covid19Services,
    workingHoursStart,
    workingHoursEnd,
    ownerName,
    licenseNumber,
    labAddress,
    workingHours,
    city,
    shortBio,
    icon,
    setIsApiCall
  ) => {
    const url = BaseURL(`users/${editingData?._id}`);
    const params = new FormData();
    params.append("role", "covid19");
    params.append("firstName", firstName);
    params.append("lastName", lastName);
    params.append("email", email);
    params.append("countryCode", countryCode);
    params.append("phoneNumber", phoneNumber);
    params.append("completePhoneNumber", completePhoneNumber);
    for (const key in covid19Services) {
      params.append("covid19Services", JSON.stringify(covid19Services[key]));
    }
    params.append("ownerName", ownerName);
    params.append("licenseNumber", licenseNumber);
    params.append("labAddress", labAddress);
    params.append("startTime", workingHoursStart);
    params.append("endTime", workingHoursEnd);
    params.append("city", city);
    params.append("shortBio", shortBio);
    icon && icon.name && params.append("photo", icon);

    setIsApiCall(true);

    const responseData = await Patch(url, params, apiHeader(accessToken, true));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success(`Covid lab updated successfully!`);
      getCovidLab(1, limit);
      setIsEditing(false);
      return;
    }
    setIsApiCall(false);
  };

  useEffect(() => {
    getCovidLab(1, limit);
  }, [pageNumber]);

  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getCovidLab(newPageNumber, limit);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>COVID LAB</h1>
      </div>

      {/* Add Section Button Container */}

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 mt-5 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Image</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th>Last Updated</th>
                    <th>Active</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {covidLabData?.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan={8}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {covidLabData?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="category-icon-box">
                              {" "}
                              <img
                                src={imageUrl + item?.photo}
                                className="w-100 h-100"
                              />
                            </div>
                          </td>
                          <td>{item?.firstName}</td>
                          <td>{item?.lastName}</td>
                          <td>{item?.email}</td>
                          <td>{`${item.countryCode} ${item?.phoneNumber}`}</td>
                          <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>{item?.isActive ? "Yes" : "No"}</td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item"
                                onClick={() => {
                                  setViewCovidLabData(item);
                                  setIsViewing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Details
                                </Button>
                              </li>
                              <li
                                className="page-item align-self-end"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsEditing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Edit
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* <Pagination
          maxLastLength={limit}
          array={covidLabData}
          HandlepageChange={HandlepageChange}
          pageNumber={pageNumber}
        /> */}
      </div>

      {isEditing && (
        <UpdateCovidLabModal
          open={isEditing}
          setOpen={setIsEditing}
          onUpdate={updateCovidLab}
          data={editingData}
        />
      )}
      {isViewing && (
        <ViewCovidLabModal
          open={isViewing}
          setOpen={setIsViewing}
          data={viewCovidLabData}
        />
      )}
    </section>
  );
}

export default CovidLab;
