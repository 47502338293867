import React from "react";

const BodyLoader = () => {
  return (
    <div className="body-loader">
      <div
        className="spinner-grow"
        style={{
          width: "3rem",
          height: "3rem",
        }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
      <p className="loader-text">Please Wait...</p>
    </div>
  );
};

export default BodyLoader;
