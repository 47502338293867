import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import AreYouSureModal from "../components/AreYouSureModal";

// Mui Button

const ContactUs = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [contacts, setContacts] = useState([]);

  const getAllContacts = async (pageNo, newLimit) => {
    setIsGetting(true);
    const url = BaseURL(`contact-us?page=${pageNo}&limit=${newLimit}`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setContacts(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const deleteContact = async () => {
    const url = BaseURL(`contact-us/${deletingId}`);

    const responseData = await Delete(url, null, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Deleted successfully`);
      getAllContacts(1, limit);
      setIsDeleting(false);
      return;
    }
  };

  useEffect(() => {
    getAllContacts(1, limit);
  }, [pageNumber]);

  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getAllContacts(newPageNumber, limit);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>CONTACT US</h1>
      </div>

      <div className="fieldSetMainContainer">
        {/* Add Section Button Container */}
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center">
            <p className="m-0 me-3 fw-bold">Items Per Page:</p>
            <select
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPageNumber(1);
                getAllContacts(1, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={10} className="pt-2">
                10
              </option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Created At</th>
                    {/* <th>Active</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                {contacts.length < 1 && (
                      <tr className="no-data-table-row">
                      {" "}
                      <td colSpan="9">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {contacts?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{item?.firstName}</td>
                          <td>{item?.lastName}</td>
                          <td>{item?.email}</td>
                          <td>{item?.phone}</td>
                          {/* {usersType === 'vendor' && <td>{item?.companyName}</td>} */}
                          <td>
                            {moment(item.createdAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          {/* <td>{item?.isActive ? "Yes" : "No"}</td> */}
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsDeleting(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Pagination
          maxLastLength={limit}
          array={contacts}
          HandlepageChange={HandlepageChange}
          pageNumber={pageNumber}
        />
      </div>
      {isDeleting && (
        <AreYouSureModal
          open={isDeleting}
          setOpen={setIsDeleting}
          label={"Are You Sure?"}
          message={`Do you really want to delete this?`}
          handleYes={() => deleteContact(false, deletingId)}
        />
      )}
    </section>
  );
};

export default ContactUs;
