import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import MuiButton from "@mui/material/Button";
import TimeRange from "react-time-range";
import moment from "moment";
import { IoMdAddCircleOutline } from "react-icons/io";
import { imageUrl } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import AddImageComponent from "./AddImageComponent";
import { urlToObject } from "../Config/apiUrl";
import AddCovid19ServicesModal from "./AddCovid19ServicesModal";
import AreYouSureModal from "../components/AreYouSureModal";

const UpdateCovidLabModal = ({ open, setOpen, onUpdate, isLoading, data }) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [firstName, setFirstName] = useState(data?.firstName);
  const [lastName, setLastName] = useState(data?.lastName);
  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber);
  const [countryCode, setCountryCode] = useState(data?.countryCode);
  const [ownerName, setOwnerName] = useState(data?.ownerName);
  const [licenseNumber, setLicenseNumber] = useState(data?.licenseNumber);
  const [labAddress, setLabAddress] = useState(data?.labAddress);
  const [city, setCity] = useState(data?.city);
  const [labWorkingHours] = useState(data?.labWorkingHours);
  const [workingHoursStart, setWorkingHoursStart] = useState(
    data?.labWorkingHours?.startTime
  );
  const [workingHoursEnd, setWorkingHoursEnd] = useState(
    data?.labWorkingHours?.startTime
  );
  const [shortBio, setShortBio] = useState(data?.shortBio);
  let [covid19Services, setCovid19Services] = useState(data?.covid19Services);
  const [isAddingCovidService, setIsAddingCovidService] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingData, setDeletingData] = useState();
  const [icon, setIcon] = useState(data?.photo);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [loading, setLoading] = useState(true);
  const iconInput = useRef();

  const setWorkingHours = (hours) => {
    setWorkingHoursStart(moment(hours.startTime));
    setWorkingHoursEnd(moment(hours.endTime));
  };

  const getUrlObject = async () => {
    const _img = await urlToObject(`${imageUrl}${icon}`);
    setIcon(_img);
    setLoading(false);
  };

  const addCovid19Service = async (covidService) => {
    let refObj = [...covid19Services];

    refObj.push(covidService);
    setCovid19Services(refObj);
    setIsAddingCovidService(false);
  };

  const setCovidServices = async (covidService) => {
    let refObj = [...covid19Services];

    refObj.splice(
      refObj.findIndex((val) => val?._id == covidService?._id),
      1,
      covidService
    );
    setCovid19Services(refObj);
    setIsEditing(false);
  };

  const deleteCovidService = async (covidService) => {
    let refObj = [...covid19Services];

    refObj.splice(
      refObj.findIndex((val) => val?._id == covidService?._id),
      1
    );
    setCovid19Services(refObj);
    setIsDeleting(false);
  };

  const handleSetTiming = (e) => {
    setWorkingHoursStart(e.startTime);
    setWorkingHoursEnd(e.endTime);
  };

  useEffect(() => {
    getUrlObject();
    setWorkingHours(labWorkingHours);
  }, []);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color text-left">
          Edit Covid Lab
        </span>
        <div data-validate="First name is required" className="mb-3">
          <span className="label-input100">First Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>

        <div data-validate="Last name is required" className="mb-3">
          <span className="label-input100">Last Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>

        <div data-validate="Email is required" className="mb-3">
          <span className="label-input100">Email</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div data-validate="Country code is required" className="mb-3">
          <span className="label-input100">Country Code</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Country Code"
            value={countryCode}
            onChange={(e) => {
              setCountryCode(e.target.value);
            }}
          />
        </div>

        <div data-validate="Phone number required" className="mb-3">
          <span className="label-input100">Phone Number</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div>

        <div data-validate="Owner name is required" className="mb-3">
          <span className="label-input100">Owner Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Owner Name"
            value={ownerName}
            onChange={(e) => {
              setOwnerName(e.target.value);
            }}
          />
        </div>

        <div data-validate="License number is required" className="mb-3">
          <span className="label-input100">License Number</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="License Number"
            value={licenseNumber}
            onChange={(e) => {
              setLicenseNumber(e.target.value);
            }}
          />
        </div>

        <div data-validate="Lab adddress is required" className="mb-3">
          <span className="label-input100">Lab Address</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Lab Address"
            value={labAddress}
            onChange={(e) => {
              setLabAddress(e.target.value);
            }}
          />
        </div>

        <div data-validate="City is required" className="mb-3">
          <span className="label-input100">City</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="City"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>

        <Row className="mb-2">
          <Col md={12}>
            <span className="label-input100">Lab Working Hours:</span>
            <TimeRange
              startMoment={workingHoursStart}
              endMoment={workingHoursEnd}
              onChange={(e) => handleSetTiming(e)}
            />
          </Col>
        </Row>

        <div data-validate="Description is required" className="mb-3">
          <span className="label-input100">Description</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Description"
            value={shortBio}
            onChange={(e) => {
              setShortBio(e.target.value);
            }}
          />
        </div>

        <Row>
          <Col md={12} className="mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <span className="font-17 fw-bold mb-2">Covid19 Services:</span>
              <MuiButton
                onClick={() => setIsAddingCovidService(true)}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div>
          </Col>
          <Col md={12}>
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Service Charges</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {covid19Services?.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan={8}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">Services Not Added Yet!</p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {covid19Services.length > 0 &&
                    covid19Services?.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{item?.serviceName}</td>
                            <td>{item?.servicePrice} <strong>SLSH</strong></td>
                            <td>
                              <ul className="d-flex">
                                <li
                                  className="page-item align-self-end"
                                  onClick={() => {
                                    setEditingData(item);
                                    setIsEditing(true);
                                  }}
                                >
                                  <Button className="update-btn me-2">
                                    Edit
                                  </Button>
                                </li>
                                <li
                                  className="page-item align-self-end"
                                  onClick={() => {
                                    setDeletingData(item);
                                    setIsDeleting(true);
                                  }}
                                >
                                  <Button className="update-btn me-2">
                                    Delete
                                  </Button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>

        <div className="validate-input mt-4" data-validate="Icon is required">
          <span className="add-evidence-modal-label">Edit Image</span>
          <div
            className="d-flex flex-wrap mt-3"
            style={{ width: "190px", height: "100px" }}
          >
            {!loading && (
              <AddImageComponent
                index={null}
                item={icon}
                onSubmit={(data) => setIcon(data)}
                initialState={icon}
              />
            )}
          </div>
        </div>

        <input
          ref={iconInput}
          className="d-none"
          type="file"
          onChange={(e) => setIcon(e.target.files[0])}
        />

        <AreYouSureModal
          open={isDeleting}
          setOpen={setIsDeleting}
          label={"Are You Sure?"}
          message={`Do you really want to delete this service?`}
          handleYes={() => deleteCovidService(deletingData)}
        />

        {isEditing && (
          <AddCovid19ServicesModal
            open={isEditing}
            setOpen={setIsEditing}
            onUpdate={setCovidServices}
            type={"Edit"}
            data={editingData}
          />
        )}

        {isAddingCovidService && (
          <AddCovid19ServicesModal
            open={isAddingCovidService}
            setOpen={setIsAddingCovidService}
            onUpdate={addCovid19Service}
            type={"Add"}
          />
        )}

        {/* btn */}
        <div className="container-login100-form-btn">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsApiCall(true);
                let workingHours = `${workingHoursStart}-${workingHoursEnd}`;
                let completePhoneNumber = `${countryCode}-${phoneNumber}`;
                onUpdate(
                  firstName,
                  lastName,
                  email,
                  countryCode,
                  phoneNumber,
                  completePhoneNumber,
                  covid19Services,
                  workingHoursStart,
                  workingHoursEnd,
                  ownerName,
                  licenseNumber,
                  labAddress,
                  workingHours,
                  city,
                  shortBio,
                  icon,
                  setIsApiCall
                );
              }}
            >
              {isApiCall ? "Editing..." : "Edit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default UpdateCovidLabModal;
