import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import { useHistory } from "react-router";
import { BaseURL, apiHeader } from "../Config/apiUrl";
import { toast } from "react-toastify";

const Support = () => {
  const history = useHistory();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);
  const [supportId, setSupportId] = useState();

  const [support, setSupport] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [footerDesc, setFooterDesc] = useState("");

  const getSupport = async () => {
    setIsGetting(true);
    const url = BaseURL(`support`);

    const responseData = await Get(url);
    if (responseData !== undefined) {
      setSupport(responseData?.data?.data?.text);
      setPhoneNumber(responseData?.data?.data?.contact);
      setEmail(responseData?.data?.data?.email);
      setFooterDesc(responseData?.data?.data?.footerDesc);

      setSupportId(responseData?.data?.data?._id);

      console.log(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const updateSupport = async (data, contact, email, footerDesc, supportId) => {
    const url = BaseURL(`support/${supportId}`);
    let params = {
      text: data,
      email: email,
      contact: contact,
      footerDesc,
    };

    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Updated successfully`);
      getSupport();
      return;
    }
    getSupport();
  };

  useEffect(() => {
    getSupport();
  }, []);

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>SUPPORT</h1>
      </div>

      <div className="fieldSetMainContainer">
        <div className="place-add-btn-container mb-4">
          <div className="d-flex">
            <div className="d-flex align-items-center"></div>
          </div>
        </div>
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <>
            <div className="mt-4">
              <p>Support Description</p>{" "}
              <ReactQuill
                value={support}
                onChange={(e) => {
                  setSupport(e);
                }}
              />
            </div>
            <div className="mt-4">
              <p>
                <b>Footer Description</b>
              </p>

              <input
                type="text"
                className="supportInput"
                value={footerDesc}
                onChange={(e) => {
                  setFooterDesc(e.target.value);
                }}
              />
            </div>
            <div className="mt-4">
              <p>
                <b>Support Contact</b>
              </p>
              <input
                type="text"
                className="supportInput"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </div>
            <div className="mt-4">
              <p>
                <b>Support Email</b>
              </p>

              <input
                type="text"
                className="supportInput"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </>
        )}
        <MuiButton
          onClick={() =>
            updateSupport(support, phoneNumber, email, footerDesc, supportId)
          }
          variant="contained"
          color="primary"
          className="mt-3 custom-btn rounded-lg radius-8 ms-4"
        >
          Update
        </MuiButton>
      </div>
    </section>
  );
};

export default Support;
