import React, { useEffect, useState, useRef } from "react";
import ModalSkeleton from "./ModalSkeleton";
import { IoMdClose } from "react-icons/io";
import { imageUrl } from "../Config/apiUrl";
import fallbackIcon from "../assets/images/fallbackicon.png";
import AddImageComponent from "../components/AddImageComponent";
import { urlToObject } from "../Config/apiUrl";

const UpdateSpecializationModal = ({
  open,
  setOpen,
  onUpdate,
  isLoading,
  data,
}) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [name, setName] = useState(data?.name);
  const [icon, setIcon] = useState(data?.icon);
  const [loading, setLoading] = useState(true);
  const iconInput = useRef();

  useEffect(async () => {
    const _section1Image = await urlToObject(`${imageUrl}${data.icon}`);
    setIcon(_section1Image);
    setLoading(false);
  }, []);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color text-left">
          Edit Specialization
        </span>
        <div data-validate="Name is required" className="mb-3">
          <span className="label-input100">Name</span>
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="validate-input mt-4" data-validate="Icon is required">
          <span className="add-evidence-modal-label">Edit Icon</span>
          <div
            className="d-flex flex-wrap mt-5"
            style={{ width: "50px", height: "50px" }}
          >
            {!loading && (
              <AddImageComponent
                index={null}
                item={icon}
                onSubmit={(data) => setIcon(data)}
                initialState={icon}
              />
            )}
          </div>
        </div>

        <input
          ref={iconInput}
          className="d-none"
          type="file"
          onChange={(e) => setIcon(e.target.files[0])}
        />

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsApiCall(true);
                onUpdate(name.trim(), icon);
                // setIsApiCall(false);
              }}
            >
              {isApiCall ? "Editing..." : "Edit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default UpdateSpecializationModal;
