import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader, imageUrl, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import AddCategoryModal from "../components/AddCategoryModal";
import { toast } from "react-toastify";
import UpdateCategoryModal from "../components/UpdateCategoryModal";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../components/PaginationComponent/index";

// Mui Button

const Category = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [statusType, setStatusType] = useState(true);
  const [deletingId, setDeletingId] = useState("");
  const [isActivating, setIsActivating] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);

  const [
    isActivatingOrDeactivatingApiCall,
    setIsActivatingOrDeactivatingApiCall,
  ] = useState(false);

  const getAllCategories = async (pageNo, newLimit, status) => {
    setIsGetting(true);
    const url = BaseURL(
      `category?status=${status}&page=${pageNo}&limit=${newLimit}`
    );

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setLimit(responseData?.data?.totalRecords);
      setCategories(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const addCategory = async (name, icon, setIsApiCall) => {
    setIsApiCall(true);
    const url = BaseURL(`category`);

    const params = new FormData();
    params.append("catName", name);
    icon && icon.name && params.append("image", icon);

    const responseData = await Post(url, params, apiHeader(accessToken, true));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success("Added successfully");
      setIsAddingCategory(false);
      getAllCategories(1, recordsLimit, statusType);
      return;
    }
    setIsApiCall(false);
  };

  const updateCategory = async (name, icon, setIsApiCall) => {
    setIsApiCall(true);
    const url = BaseURL(`category/${editingData._id}`);

    const params = new FormData();
    params.append("catName", name);
    icon.name && params.append("image", icon);

    const responseData = await Patch(url, params, apiHeader(accessToken, true));
    if (responseData !== undefined) {
      toast.success(`Updated successfully`);
      setIsEditing(false);
      getAllCategories(1, recordsLimit, statusType);
      return;
    }
    getAllCategories(1, recordsLimit, statusType);
  };

  const deleteCategory = async () => {
    const url = BaseURL(`category/${deletingId}`);

    const responseData = await Delete(url, null, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Deleted successfully`);
      getAllCategories(1, recordsLimit, statusType);
      setIsDeleting(false);
      return;
    }
  };

  // active or deactive specialization
  const activeOrDeactiveCategory = async (type) => {
    const url = BaseURL(`category/active-deactive/${categoryId}`);

    const params = {
      ...(type == "activate" ? { status: true } : { status: false }),
    };
    setIsActivatingOrDeactivatingApiCall(true);
    setIsActivating(false);
    setIsDeactivating(false);
    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsActivatingOrDeactivatingApiCall(false);
      const newData = [...categories];
      newData.splice(
        newData.findIndex((val) => val._id == categoryId),
        1,
        responseData?.data?.data
      );
      setCategories(newData);
      toast.success(
        `Category ${
          type == "activate" ? "Activated" : "Deactivated"
        } Successfully!`
      );
    }
  };

  useEffect(() => {
    getAllCategories(1, recordsLimit, statusType);
  }, []);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllCategories(page, recordsLimit, statusType);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>CATEGORY</h1>
      </div>

      <div className="fieldSetMainContainer">
        {/* Add Section Button Container */}
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center ms-3">
            <p className="m-0 me-3 fw-bold">Status:</p>
            <select
              value={statusType}
              onChange={(e) => {
                setStatusType(e.target.value);
                setPageNumber(1);
                getAllCategories(1, recordsLimit, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={true} className="pt-2">
                Active
              </option>
              <option value={false}>Deactive</option>
            </select>
          </div>
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => setIsAddingCategory(true)}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div>
          </div>
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Last Updated</th>
                    {/* <th>Active</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {categories.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan="9">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {categories?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (pageNumber - 1) * recordsLimit}</td>
                          <td>
                            <div className="category-icon-box">
                              {" "}
                              <img
                                src={imageUrl + item?.image}
                                className="w-100 h-100"
                              />
                            </div>
                          </td>
                          <td>{item?.catName}</td>
                          {/* {usersType === 'vendor' && <td>{item?.companyName}</td>} */}
                          <td>
                            {moment(item?.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          {/* <td>{item?.isActive ? "Yes" : "No"}</td> */}
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsEditing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Edit
                                </Button>
                              </li>
                              {item?.isActive ? (
                                <li
                                  className="page-item align-self-end ml-4"
                                  onClick={() => {
                                    setCategoryId(item?._id);
                                    setIsDeactivating(true);
                                  }}
                                >
                                  <Button className="delete-btn">
                                    Deactive
                                  </Button>
                                </li>
                              ) : (
                                <li
                                  className="page-item align-self-end ml-4"
                                  onClick={() => {
                                    setCategoryId(item?._id);
                                    setIsActivating(true);
                                  }}
                                >
                                  <Button className="delete-btn">Active</Button>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Col md={12} className="paginationContainer">
          {categories?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message={`Do you really want to delete this category?`}
        handleYes={() => deleteCategory()}
      />

      {isAddingCategory && (
        <AddCategoryModal
          open={isAddingCategory}
          setOpen={setIsAddingCategory}
          onUpdate={addCategory}
          type={"Add"}
        />
      )}
      {isEditing && (
        <UpdateCategoryModal
          open={isEditing}
          setOpen={setIsEditing}
          onUpdate={updateCategory}
          updating={"Edit"}
          data={editingData}
        />
      )}

      {isActivating && (
        <AreYouSureModal
          open={isActivating}
          setOpen={setIsActivating}
          label={"Are You Sure?"}
          message="Do you really want to activate this category?"
          handleYes={() => activeOrDeactiveCategory("activate")}
          isLoading={isActivatingOrDeactivatingApiCall}
        />
      )}

      {isDeactivating && (
        <AreYouSureModal
          open={isDeactivating}
          setOpen={setIsDeactivating}
          label={"Are You Sure?"}
          message="Do you really want to deactivate this category?"
          handleYes={() => activeOrDeactiveCategory("deactivate")}
          isLoading={isActivatingOrDeactivatingApiCall}
        />
      )}
    </section>
  );
};

export default Category;
