import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideNavbar from "../components/SideNavbar";
import { apiHeader, sorter, getDate, BaseURL } from "../Config/apiUrl";
import moment from "moment";
import DoctorScheduleModal from "../components/DoctorScheduleModal";
import { Post, Get } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

const DoctorSchedule = () => {
  const history = useHistory();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [modalShow, setModalShow] = useState(false);
  const [isGetting, setIsGetting] = useState(false);
  const [userSchedule, setUserSchedule] = useState([]);

  const doctorOrCovidId = useLocation()?.state.profileData._id;
  const patientId = useLocation()?.state.patientId;
  const [patientProfile, setPatientProfile] = useState();

  const profileData = useLocation()?.state.profileData;

  const commissions = useLocation()?.state.commissions;

  const consultationFee = profileData?.consultationFee;

  const [finalSchedule, setFinalSchedule] = useState([]);

  const [isBookingAppointment, setIsBookingAppointment] = useState(false);

  const [appointmentModalData, setAppointmentModalData] = useState({
    appointmentDate: null,
    appointmentTime: null,
    scheduleId: null,
  });

  const setFinalScheduleFunc = (scheduleArr) => {
    let _s = [...schedule];
    schedule.map((item, index) => {
      scheduleArr.map((innerItem, innerIndex) => {
        if (item.day.toLowerCase() == innerItem.day.toLowerCase()) {
          _s.splice(index, 1, innerItem);
        }
      });
    });
    setFinalSchedule(_s);
  };

  const getUserProfile = async (userId) => {
    setIsGetting(true);
    const url = BaseURL(`users/profile/${userId}`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      if (responseData?.data?.data?.role == "patient") {
        setPatientProfile(responseData?.data?.data);
      } else {
        setFinalScheduleFunc(responseData?.data?.schedule);
        setUserSchedule(responseData?.data?.schedule);
      }
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getUserProfile(doctorOrCovidId);
    getUserProfile(patientId);
  }, []);

  // handleDoctorScheduler
  const handleDoctorScheduler = async (
    patientName,
    patientNumber,
    serviceName,
    serviceCharges
  ) => {
    if (!patientName || !patientNumber) {
      return toast.error("Please fill all the fields");
    }

    if (profileData?.role == "covid19" && (!serviceName || !serviceCharges))
      return toast.error("Please fill all the fields");

    const url = BaseURL("appointment/book");
    const params = {
      ...(profileData?.role == "doctor" && { doctorId: profileData?._id }),
      ...(profileData?.role == "covid19" && { covid19Id: profileData?._id }),
      scheduleId: appointmentModalData?.scheduleId,
      appointmentWith: profileData?.role,
      appointmentDay: moment(appointmentModalData.appointmentDate).format(
        "dddd"
      ),
      timeId: appointmentModalData?.appointmentTime?._id,
      patientId,
      patientName: patientName,
      patientNumber: patientNumber,
      ...(profileData?.role == "doctor" && { serviceCharges: consultationFee }),
      ...(profileData?.role == "covid19" && { serviceName: serviceName }),
      ...(profileData?.role == "covid19" && { serviceCharges: serviceCharges }),
    };

    setIsBookingAppointment(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setIsBookingAppointment(false);

    if (response !== undefined) {
      setModalShow(false);
      toast.success("Appointment booked successfully!");
      history.push("/appointments");
    }
  };

  return (
    <>
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>{profileData?.role.toUpperCase()} SCHEDULE</h1>
      </div>
      <Container fluid className="dashboard">
        <Row className="my-4">
          <Row>
            <Col lg={12}>
              <Row>
                {finalSchedule?.map((e, i) => {
                  return (
                    <Col className="schedule g-0" key={i}>
                      <button type="button" className="schedule-btn-blue">
                        {e?.day}
                      </button>

                      {/* schedule */}
                      {e?.timings?.map((sch, i2) => {
                        return (
                          <button
                            key={i2}
                            type="button"
                            className="schedule-btn-pink"
                            onClick={() => {
                              const dayNum = moment(
                                moment().day(e?.day).format()
                              ).day();
                              const apptmentDate = moment(
                                getDate(dayNum)
                              ).format("YYYY-MM-DD");
                              setAppointmentModalData({
                                appointmentDate: apptmentDate,
                                appointmentTime: sch,
                                scheduleId: e?._id,
                              });
                              setModalShow(true);
                            }}
                          >
                            {sch?.startTime} - {sch?.endTime}
                          </button>
                        );
                      })}
                    </Col>
                  );
                })}
              </Row>
            </Col>
            {/* end map */}
            {modalShow && (
              <DoctorScheduleModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                appointmentDate={appointmentModalData?.appointmentDate}
                appointmentTime={appointmentModalData?.appointmentTime}
                consultationFee={consultationFee}
                handleYes={handleDoctorScheduler}
                isLoading={isBookingAppointment}
                isCovid={profileData?.role == "covid19"}
                covid19Services={profileData?.covid19Services}
                patientProfile={patientProfile}
                commissions={commissions}
              />
            )}
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default DoctorSchedule;

let schedule = [
  {
    day: "Saturday",
    timings: [],
  },
  {
    day: "Sunday",
    timings: [],
  },
  {
    day: "Monday",
    timings: [],
  },
  {
    day: "Tuesday",
    timings: [],
  },
  {
    day: "Wednesday",
    timings: [],
  },
  {
    day: "Thursday",
    timings: [],
  },
  {
    day: "Friday",
    timings: [],
  },
];

// let schedule = [
//   {
//     isAvailable: true,
//     _id: "620a58364ccbf8301c9f083f",
//     day: "Monday",
//     timings: [
//       {
//         _id: "620a58364ccbf8301c9f0840",
//         startTime: "02:00 PM",
//         endTime: "05:00 PM",
//       },
//       {
//         _id: "620a58364ccbf8301c9f0840",
//         startTime: "02:00 PM",
//         endTime: "05:00 PM",
//       },
//       {
//         _id: "620a58364ccbf8301c9f0840",
//         startTime: "02:00 PM",
//         endTime: "05:00 PM",
//       },
//       {
//         _id: "620a58364ccbf8301c9f0840",
//         startTime: "02:00 PM",
//         endTime: "05:00 PM",
//       },
//       {
//         _id: "620a58364ccbf8301c9f0840",
//         startTime: "02:00 PM",
//         endTime: "05:00 PM",
//       },
//     ],
//     numberOfPatients: 8,
//     scheduleFor: "doctor",
//     doctorId: "61f92722a9987b478cfd7af0",
//     createdAt: "2022-02-14T13:25:10.317Z",
//     updatedAt: "2022-02-14T13:25:10.317Z",
//     __v: 0,
//   },
//   {
//     isAvailable: true,
//     _id: "620a58364ccbf8301c9f083f",
//     day: "Tuesday",
//     // timings: [
//     //   {
//     //     _id: "620a58364ccbf8301c9f0840",
//     //     startTime: "02:00 PM",
//     //     endTime: "05:00 PM",
//     //   },
//     //   {
//     //     _id: "620a58364ccbf8301c9f0840",
//     //     startTime: "02:00 PM",
//     //     endTime: "05:00 PM",
//     //   },
//     //   {
//     //     _id: "620a58364ccbf8301c9f0840",
//     //     startTime: "02:00 PM",
//     //     endTime: "05:00 PM",
//     //   },
//     //   {
//     //     _id: "620a58364ccbf8301c9f0840",
//     //     startTime: "02:00 PM",
//     //     endTime: "05:00 PM",
//     //   },
//     //   {
//     //     _id: "620a58364ccbf8301c9f0840",
//     //     startTime: "02:00 PM",
//     //     endTime: "05:00 PM",
//     //   },
//     //   {
//     //     _id: "620a58364ccbf8301c9f0840",
//     //     startTime: "02:00 PM",
//     //     endTime: "05:00 PM",
//     //   },
//     // ],
//     numberOfPatients: 8,
//     scheduleFor: "doctor",
//     doctorId: "61f92722a9987b478cfd7af0",
//     createdAt: "2022-02-14T13:25:10.317Z",
//     updatedAt: "2022-02-14T13:25:10.317Z",
//     __v: 0,
//   },
//   {
//     isAvailable: true,
//     _id: "620a58584ccbf8301c9f0847",
//     day: "Wednesday",
//     numberOfPatients: 9,
//     scheduleFor: "doctor",
//     doctorId: "61f92722a9987b478cfd7af0",
//     createdAt: "2022-02-14T13:25:44.628Z",
//     updatedAt: "2022-02-14T13:25:44.628Z",
//     __v: 0,
//   },
//   {
//     isAvailable: true,
//     _id: "620a586c4ccbf8301c9f084c",
//     day: "Friday",
//     timings: [
//       {
//         _id: "620a586c4ccbf8301c9f084d",
//         startTime: "12:00 PM",
//         endTime: "04:00 PM",
//       },
//       {
//         _id: "620a586c4ccbf8301c9f084d",
//         startTime: "12:00 PM",
//         endTime: "04:00 PM",
//       },
//     ],
//     numberOfPatients: 12,
//     scheduleFor: "doctor",
//     doctorId: "61f92722a9987b478cfd7af0",
//     createdAt: "2022-02-14T13:26:04.946Z",
//     updatedAt: "2022-02-14T13:26:04.946Z",
//     __v: 0,
//   },
//   {
//     isAvailable: true,
//     _id: "620a586c4ccbf8301c9f084c",
//     day: "Saturday",
//     timings: [
//       {
//         _id: "620a586c4ccbf8301c9f084d",
//         startTime: "12:00 PM",
//         endTime: "04:00 PM",
//       },
//     ],
//     numberOfPatients: 12,
//     scheduleFor: "doctor",
//     doctorId: "61f92722a9987b478cfd7af0",
//     createdAt: "2022-02-14T13:26:04.946Z",
//     updatedAt: "2022-02-14T13:26:04.946Z",
//     __v: 0,
//   },
//   {
//     isAvailable: true,
//     _id: "620a586c4ccbf8301c9f084c",
//     day: "Sunday",
//     timings: [
//       {
//         _id: "620a586c4ccbf8301c9f084d",
//         startTime: "12:00 PM",
//         endTime: "04:00 PM",
//       },
//       {
//         _id: "620a586c4ccbf8301c9f084d",
//         startTime: "12:00 PM",
//         endTime: "04:00 PM",
//       },
//       {
//         _id: "620a586c4ccbf8301c9f084d",
//         startTime: "12:00 PM",
//         endTime: "04:00 PM",
//       },
//     ],
//     numberOfPatients: 12,
//     scheduleFor: "doctor",
//     doctorId: "61f92722a9987b478cfd7af0",
//     createdAt: "2022-02-14T13:26:04.946Z",
//     updatedAt: "2022-02-14T13:26:04.946Z",
//     __v: 0,
//   },
//   {
//     isAvailable: true,
//     _id: "620a586c4ccbf8301c9f084c",
//     day: "Thursday",
//     timings: [
//       {
//         _id: "620a586c4ccbf8301c9f084d",
//         startTime: "12:00 PM",
//         endTime: "04:00 PM",
//       },
//     ],
//     numberOfPatients: 12,
//     scheduleFor: "doctor",
//     doctorId: "61f92722a9987b478cfd7af0",
//     createdAt: "2022-02-14T13:26:04.946Z",
//     updatedAt: "2022-02-14T13:26:04.946Z",
//     __v: 0,
//   },
// ];
// const dayNum = moment(moment().day(appointmentDay).format()).day();

// const apptmentDate = moment(getDate(dayNum)).format("YYYY-MM-DD");
