import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Route, BrowserRouter as Router, Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

// import { FiHome } from "react-icons/fi";
import {
  MdOutlineLocalPharmacy,
  MdContactPhone,
  MdPayments,
  MdCategory,
  MdPrivacyTip,
} from "react-icons/md";
import { ImLab, ImUsers } from "react-icons/im";
import { BsCreditCard2BackFill, BsFillChatLeftFill } from "react-icons/bs";
import {
  RiLockPasswordFill,
  RiUserSettingsFill,
  RiAdminFill,
  RiPagesFill,
  RiLogoutCircleFill,
  RiRefreshFill,
} from "react-icons/ri";

import {
  AiFillCalendar,
  AiFillShopping,
  AiFillContacts,
  AiFillHome,
} from "react-icons/ai";
import { HiShoppingCart } from "react-icons/hi";
import { FaBookOpen, FaCity } from "react-icons/fa";
import { IoMdNotifications, IoIosCash } from "react-icons/io";
import { BiError } from "react-icons/bi";

import { signOutRequest } from "../store/Actions/authAction";
import { apiUrl, BaseURL, apiHeader } from "../Config/apiUrl";
import { Get, Post, Patch } from "../Axios/AxiosFunctions";

import Dashbaord from "../Screens/Dashbaord";
import UpdatePassword from "../Screens/UpdatePassword";
import Appointments from "../Screens/Appointments";
import Specialization from "../Screens/Specialization";
import City from "../Screens/City";
import TermsAndCondition from "../Screens/TermsAndCondition";
import ContactsUs from "../Screens/ContactUs";
import Chats from "../Screens/Chats";
import Notifications from "../Screens/Notifications";
import AdminLists from "../Screens/AdminList";
import Users from "../Screens/Users";
import CovidLab from "../Screens/CovidLab";
import Pharmacy from "../Screens/Pharmacy";
import Products from "../Screens/Products";
import Category from "../Screens/Category";
import Payments from "../Screens/Payments";
import Withdrawals from "../Screens/Withdrawals";
import Schedule from "../Screens/DoctorSchedule";
import Commission from "../Screens/Commission";
import Orders from "../Screens/Orders";
import Cms from "../Screens/Pages";
import HomeCms from "../Screens/HomeCms";
import AboutCms from "../Screens/AboutCms";
import PharmacyCms from "../Screens/PharmacyCms";
import PageSections from "../Screens/PageSections";
import Profile from "../Screens/Profile";
import AppointmentError from "../Screens/AppointmentError";

import { refreshIcon, logo } from "../constant/imagePath";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import DoctorCms from "../Screens/DoctorCms";
import Covid19Cms from "../Screens/Covid19Cms";
import Support from "../Screens/Support";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#ef464b",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const interval = useRef();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("/");
  const [lastRefresh, setLastRefresh] = useState(0);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const currentUser = useSelector((state) => state.authReducer.user);

  function SidebarItem({ route, text, position, icon }) {
    return (
      <Link to={route} className="mouse-cursor sideBarNavLink">
        <ListItem
          button
          key={"MyEvents"}
          style={
            position == route
              ? { borderLeft: "5px solid #51087E", paddingLeft: "11px" }
              : {}
          }
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} className={open && "ml--20"} />
        </ListItem>
      </Link>
    );
  }

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const pathName = location?.pathname;
    setSelected(pathName);
    setLastRefresh(0);
  }, [location]);

  useEffect(() => {
    interval.current = setInterval(() => {
      setLastRefresh((pre) => pre + 1);
    }, 60000);
    return () => clearInterval(interval.current);
  }, []);

  async function logoutUser() {
    const logoutUrl = BaseURL("users/logout");
    const responseData = await Post(logoutUrl, {}, apiHeader(accessToken));
    if (responseData !== undefined) {
      dispatch(signOutRequest());
    } else {
      return toast.error("Logout failed.");
    }
  }
  const adminRoleCondition = currentUser?.role === "admin";
  const adminAndManagerRoleCondition = ["admin", "manager"].includes(
    currentUser?.role
  );
  return (
    <Row className="g-0">
      <Col lg={open ? 2 : 1}>
        {/* <Box sx={{ display: "flex", width: "100%" }}> */}
        <CssBaseline />
        <Drawer variant="permanent" open={open} className="drawer">
          <DrawerHeader className="SideBarHeaderContainer">
            {open && <img src={logo} alt="" className="img-fluid logoImage" />}
            <IconButton
              onClick={handleDrawerToggle}
              className="navHeaderIconBtn"
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            style={{
              marginTop: "25px",
            }}
            className="sidebar-scroll"
          >
            <SidebarItem
              text={"Dashboard"}
              route="/"
              position={selected}
              icon={<AiFillHome size={25} color="#51087E" />}
            />

            {adminRoleCondition && (
              <SidebarItem
                text={"Admins"}
                route="/admins"
                position={selected}
                icon={<RiAdminFill size={25} color="#51087E" />}
              />
            )}

            {adminAndManagerRoleCondition && (
              <>
                <SidebarItem
                  text={"Users"}
                  route="/allusers"
                  position={selected}
                  icon={<ImUsers size={25} color="#51087E" />}
                />

                <SidebarItem
                  text={"Appointment"}
                  route="/appointments"
                  position={selected}
                  icon={<AiFillCalendar size={25} color="#51087E" />}
                />

                <SidebarItem
                  text={"Errors"}
                  route="/appointment-errors"
                  position={selected}
                  icon={<BiError size={25} color="#51087E" />}
                />
              </>
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Covid Lab"}
                route="/covid-lab"
                position={selected}
                icon={<ImLab size={25} color="#51087E" />}
              />
            )}

            {/* <SidebarItem
            text={"Pharmacy"}
              route="/pharmacy-user"
              position={selected}
              icon={<MdOutlineLocalPharmacy size={25} color="#51087E" />}
            /> */}

            {adminRoleCondition && (
              <SidebarItem
                text={"Category"}
                route="/category"
                position={selected}
                icon={<MdCategory size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Products"}
                route="/products"
                position={selected}
                icon={<AiFillShopping size={25} color="#51087E" />}
              />
            )}

            <SidebarItem
              text={"Orders"}
              route="/orders"
              position={selected}
              icon={<HiShoppingCart size={25} color="#51087E" />}
            />

            {adminRoleCondition && (
              <SidebarItem
                text={"City"}
                route="/city"
                position={selected}
                icon={<FaCity size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Specialization"}
                route="/specialization"
                position={selected}
                icon={<RiUserSettingsFill size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Cms"}
                route="/cms"
                position={selected}
                icon={<FaBookOpen size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Page Sections"}
                route="/page-sections"
                position={selected}
                icon={<RiPagesFill size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Support"}
                route="/support"
                position={selected}
                icon={<MdContactPhone size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Contacts"}
                route="/contacts"
                position={selected}
                icon={<AiFillContacts size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Terms & Condition"}
                route="/terms-and-condition"
                position={selected}
                icon={<MdPrivacyTip size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Chats"}
                route="/chats"
                position={selected}
                icon={<BsFillChatLeftFill size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Notifications"}
                route="/notifications"
                position={selected}
                icon={<IoMdNotifications size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Payments"}
                route="/payments"
                position={selected}
                icon={<BsCreditCard2BackFill size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Commission"}
                route="/commission"
                position={selected}
                icon={<IoIosCash size={25} color="#51087E" />}
              />
            )}

            {adminRoleCondition && (
              <SidebarItem
                text={"Withdraw Request"}
                route="/withdraw"
                position={selected}
                icon={<MdPayments size={25} color="#51087E" />}
              />
            )}

            <SidebarItem
              text={"Update Password"}
              route="/update-password"
              position={selected}
              icon={
                <RiLockPasswordFill
                  src={refreshIcon}
                  size={25}
                  color="#51087E"
                />
              }
            />

            {/* LOGOUT  */}
            <div className="sideBarNavLink mouse-cursor" onClick={logoutUser}>
              <ListItem button key={"logout"}>
                <ListItemIcon>
                  <RiLogoutCircleFill size={25} color="#51087E" />
                </ListItemIcon>
                <ListItemText primary={"Log out"} />
              </ListItem>
            </div>
            <div
              className="sideBarNavLink mouse-cursor"
              style={{
                marginTop: "75px",
              }}
            >
              {open && (
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "19px",
                    letterSpacing: "0.03em",
                    color: "#fff",
                    opacity: 0.75,
                    padding: "0px 21px",
                  }}
                >
                  Updated {lastRefresh} mins ago
                </p>
              )}
              <ListItem
                button
                key={"Refresh Page"}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <ListItemIcon>
                  <RiRefreshFill size={27} color="#51087E" />
                </ListItemIcon>
                <ListItemText primary={"Refresh Page"} />
              </ListItem>
            </div>
          </List>
        </Drawer>
      </Col>
      <Col lg={open ? 10 : 11}>
        <Box
          component="main"
          sx={{ flexGrow: 1, height: "100vh", padding: "0 3%" }}
        >
          {/* <Route path="/" exact component={Dashbaord} /> */}
          {adminRoleCondition && (
            <>
              <Route path="/admins" exact component={AdminLists} />
              <Route path="/specialization" exact component={Specialization} />
              <Route path="/city" exact component={City} />
              <Route
                path="/terms-and-condition"
                exact
                component={TermsAndCondition}
              />
              <Route path="/support" exact component={Support} />
              <Route path="/contacts" exact component={ContactsUs} />
              <Route path="/chats" exact component={Chats} />
              <Route path="/notifications" exact component={Notifications} />
              <Route path="/payments" exact component={Payments} />
              <Route path="/withdraw" exact component={Withdrawals} />
              <Route path="/covid-lab" exact component={CovidLab} />
              <Route path="/products" exact component={Products} />
              <Route path="/category" exact component={Category} />
              <Route path="/pharmacy-user" exact component={Pharmacy} />
              <Route path="/commission" exact component={Commission} />
              <Route path="/cms" exact component={Cms} />
              <Route path="/home" exact component={HomeCms} />
              <Route path="/about_us" exact component={AboutCms} />
              <Route path="/pharmacy" exact component={PharmacyCms} />
              <Route path="/doctor" exact component={DoctorCms} />
              <Route path="/covid19" exact component={Covid19Cms} />
              <Route path="/page-sections" exact component={PageSections} />
            </>
          )}

          {adminAndManagerRoleCondition && (
            <>
              <Route
                path="/appointment-errors"
                exact
                component={AppointmentError}
              />
              <Route path="/schedule" exact component={Schedule} />
              <Route path="/profile/:id" exact component={Profile} />
              <Route path="/" exact component={Dashbaord} />
              <Route path="/orders" exact component={Orders} />
              <Route path="/appointments" exact component={Appointments} />
              <Route path="/update-password" exact component={UpdatePassword} />
              <Route path="/allusers" exact component={Users} />
            </>
          )}
        </Box>
      </Col>
    </Row>
  );
};

export default SideNavbar;
