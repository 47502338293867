import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import { Col, Container, Row } from "react-bootstrap";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import ViewAppointmentModal from "../components/ViewAppointmentModal";
import { toast } from "react-toastify";
import AreYouSureModal from "../components/AreYouSureModal";
import { DatePicker } from "../components/DatePicker/index";
import PaginationComponent from "../components/PaginationComponent/index";
// Mui Button

const Appointments = () => {
  const history = useHistory();
  const [usersType, setUsersType] = useState("all");
  const [statusType, setStatusType] = useState("active");
  const [limit, setLimit] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelId, setCancelId] = useState("");
  const [viewAppointmentData, setViewAppointmentData] = useState(false);
  const [appointment, setAppointment] = useState([]);
  const [search, setSearch] = useState("");

  const getAllAppointments = async (
    pageNo,
    newLimit,
    newUserType,
    newStatusType,
    from,
    to
  ) => {
    setIsGetting(true);
    const url = BaseURL(
      `appointment/get-by-admin?page=${pageNo}&limit=${newLimit}`
    );
    let params = {
      status: newStatusType,
      type: newUserType,
    };

    search && (params.search = search.trim());

    from && to && (params = { ...params, from, to });

    const responseData = await Post(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setLimit(responseData?.data?.totalRecords);
      setAppointment(responseData?.data?.appointments);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const cancelAppointment = async () => {
    setIsGetting(true);
    const url = BaseURL(`appointment/cancel/${cancelId}`);

    const responseData = await Patch(url, {}, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsCancelling(false);
      toast.success("Appointment Cancelled Successfully!");
      getAllAppointments(1, recordsLimit, usersType, statusType);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getAllAppointments(1, recordsLimit, usersType, statusType);
  }, []);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllAppointments(page, recordsLimit, usersType, statusType);
  };

  const handleSearch = () => {
    getAllAppointments(1, recordsLimit, usersType, statusType, search);
  };

  const onSelectDate = (date) => {
    const from = date[0];
    const to = date[1] ? date[1] : null;
    getAllAppointments(1, recordsLimit, usersType, statusType, from, to);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>APPOINTMENTS</h1>
      </div>

      <div className="fieldSetMainContainer">
        {/* Add Section Button Container */}
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center">
            <p className="m-0 me-3 fw-bold">Please Choose User Type:</p>
            <select
              value={usersType}
              onChange={(e) => {
                setUsersType(e.target.value);
                setPageNumber(1);
                getAllAppointments(1, recordsLimit, e.target.value, statusType);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value="all">All</option>
              <option value="doctor" className="pt-2">
                Doctor
              </option>
              <option value="covid19">Covid19</option>
            </select>
          </div>

          <div className="d-flex align-items-center ms-3">
            <p className="m-0 me-3 fw-bold">Status:</p>
            <select
              value={statusType}
              onChange={(e) => {
                setStatusType(e.target.value);
                setPageNumber(1);
                getAllAppointments(1, limit, usersType, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={"active"} className="pt-2">
                Active
              </option>
              <option value={"cancelled"}>Cancelled</option>
              <option value={"completed"}>Completed</option>
            </select>
          </div>
        </div>

        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center">
            <DatePicker onUpdate={onSelectDate} />
          </div>
          <div className="d-flex align-items-center" style={{ width: "35%" }}>
            <div class="input-group">
              <input
                type={search}
                className="form-control"
                placeholder="Name, Phone & Appointment Num"
                aria-label=" username"
                aria-describedby="basic-addon2"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div class="input-group-append">
                <button
                  className="custom-btn login100-form-btn mx-2"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Date</th>
                    {/* <th>Time</th> */}
                    <th>Patient Name</th>
                    <th>Phone Number</th>
                    <th>Appointment With</th>
                    <th>User Name</th>
                    <th>Appointment Number</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {appointment.length < 1 && (
                    <tr className="no-data-table-row">
                      {" "}
                      <td colSpan={usersType === "vendor" ? 10 : 9}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {appointment?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (pageNumber - 1) * recordsLimit}</td>
                          <td>
                            {moment(item.appointmentDate).format(
                              "ddd, DD MMM YYYY"
                            )}
                          </td>
                          {/* <td>{item?.appointmentStartTime}</td> */}
                          <td>{item?.patientName}</td>
                          <td>{item?.patientNumber}</td>
                          <td>{item?.appointmentWith}</td>
                          <td>
                            {item?.doctorId
                              ? item?.doctorId?.displayName
                              : item?.covid19Id?.displayName}
                          </td>
                          <td>{item?.tokenNumber}</td>
                          <td>{item?.status}</td>
                          {/* {usersType === 'vendor' && <td>{item?.companyName}</td>} */}
                          <td>
                            {moment(item.createdAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          {/* <td>{item?.isActive ? "Yes" : "No"}</td> */}
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item"
                                onClick={() => {
                                  setViewAppointmentData(item);
                                  setIsViewing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Details
                                </Button>
                              </li>
                              {item?.status === "active" && item?.payLater && (
                                <li
                                  className="page-item"
                                  onClick={() => {
                                    setCancelId(item?.id);
                                    setIsCancelling(true);
                                  }}
                                >
                                  <Button className="delete-btn me-2">
                                    Cancel
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Col md={12} className="paginationContainer">
          {appointment?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      {isCancelling && (
        <AreYouSureModal
          open={isCancelling}
          setOpen={setIsCancelling}
          label={"Are You Sure?"}
          message="Do you really want to cancel this appointment?"
          handleYes={() => cancelAppointment()}
          // isLoading={isDeletingApiCall}
        />
      )}

      {isViewing && (
        <ViewAppointmentModal
          open={isViewing}
          setOpen={setIsViewing}
          data={viewAppointmentData}
          // label={"Are You Sure?"}
          // message={`Do you really want to delete this appointment?`}
          // handleYes={() => deleteAppointment(false, appointmentId)}
        />
      )}
    </section>
  );
};

export default Appointments;
