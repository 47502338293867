import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import ModalSkeleton from "./ModalSkeleton";
import AddImageComponent from "../components/AddImageComponent";
import { imageUrl, urlToObject } from "../Config/apiUrl";

const UpdateCategoryModal = ({ open, setOpen, onUpdate, data }) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(data?.catName);
  const [icon, setIcon] = useState(data?.image);
  // const [description, setDescription] = useState(data?.description);
  const [noIcon, setNoIcon] = useState(true);
  const iconInput = useRef();

  useEffect(async () => {
    const _section1Image = await urlToObject(`${imageUrl}${data.image}`);
    setIcon(_section1Image);
    setLoading(false);
  }, []);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title text-color text-left">
          Edit Category
        </span>

        <div data-validate="Category Name is required">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="category-name"
            placeholder="Category Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="validate-input mt-4" data-validate="Icon is required">
          <span className="add-evidence-modal-label">Edit Image</span>
          <div
            className="d-flex flex-wrap mt-5"
            style={{ width: "50px", height: "50px" }}
          >
            {!loading && (
              <AddImageComponent
                index={null}
                item={icon}
                onSubmit={(data) => setIcon(data)}
                initialState={icon}
              />
            )}
          </div>
        </div>

        <input
          ref={iconInput}
          className="d-none"
          type="file"
          onChange={(e) => {
            setNoIcon(true);
            setIcon(e.target.files[0]);
          }}
        />

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isApiCall}
              class="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                if (!name) return toast.warning("Category name is required");
                onUpdate(name, icon, setIsApiCall);
              }}
            >
              {isApiCall ? "Editing..." : "Edit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default UpdateCategoryModal;
