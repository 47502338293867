import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import ViewAppointmentErrorModal from "../components/ViewAppointmentErrorModal";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Col, Container, Row } from "react-bootstrap";
import PaginationComponent from "../components/PaginationComponent/index";

// Mui Button

const AppointmentError = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [statusType, setStatusType] = useState("all");
  const [isViewing, setIsViewing] = useState(false);
  const [appointmentData, setAppointmentData] = useState(false);

  const [appointmentErrors, setAppointmentErrors] = useState([]);

  const getAllAppointmentErrors = async (pageNo, newLimit, status) => {
    setIsGetting(true);
    const url = BaseURL(
      `appointment/errors/admin?page=${pageNo}&limit=${newLimit}&status=${status}`
    );

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setLimit(responseData?.data?.totalRecords);
      setAppointmentErrors(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getAllAppointmentErrors(1, recordsLimit, statusType);
  }, []);

  const HandlepageChange = (page) => {
    setPageNumber(page);
    getAllAppointmentErrors(page, recordsLimit, statusType);
  };

  return (
    <section className="reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>APPOINTMENT ERRORS</h1>
      </div>

      <div className="fieldSetMainContainer">
        {/* Add Section Button Container */}
        <div className="place-add-btn-container mb-4">
          <div className="d-flex align-items-center"></div>
          <div className="d-flex align-items-center ms-3">
            <p className="m-0 me-3 fw-bold">Status:</p>
            <select
              value={statusType}
              onChange={(e) => {
                setStatusType(e.target.value);
                setPageNumber(1);
                getAllAppointmentErrors(1, recordsLimit, e.target.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={"all"} className="pt-2">
                All
              </option>
              <option value={"booking"}>Booking</option>
              <option value={"cancel"}>Cancel</option>
            </select>
          </div>
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Patient</th>
                    <th>Appointment With</th>
                    <th>Doctor</th>
                    <th>Lab</th>
                    <th>Error</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {appointmentErrors.length < 1 && (
                    <tr className="no-data-table-row">
                      {/* {/* {" "} */}
                      <td colSpan={9}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                          <p className="">No Data Found!</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  {appointmentErrors?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (pageNumber - 1) * recordsLimit}</td>
                          <td>{item?.patientId?.email}</td>
                          <td>{item?.appointmentWith}</td>
                          <td>{item?.doctorId?.email || "-"}</td>
                          <td>{item?.covid19Id?.email || "-"}</td>
                          <td>{item?.error}</td>
                          <td>
                            {moment(item.createdAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            <ul>
                              <li
                                className="page-item"
                                onClick={() => {
                                  setAppointmentData(item);
                                  setIsViewing(true);
                                }}
                              >
                                <Button className="update-btn me-2">
                                  Details
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Col md={12} className="paginationContainer">
          {appointmentErrors?.length > 0 && (
            <PaginationComponent
              totalPages={Math.ceil(limit / recordsLimit)}
              currentPage={pageNumber}
              setCurrentPage={HandlepageChange}
            />
          )}
        </Col>
      </div>

      {isViewing && (
        <ViewAppointmentErrorModal
          open={isViewing}
          setOpen={setIsViewing}
          data={appointmentData}
        />
      )}
    </section>
  );
};

export default AppointmentError;
