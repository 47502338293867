import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import ModalSkeleton from "./ModalSkeleton";
import { FaImage } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import AddImageComponent from "./AddImageComponent";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader, imageUrl } from "../Config/apiUrl";

const AddProductModal = ({
  open,
  setOpen,
  onUpdate,
  isLoading,
  categories,
}) => {
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isApiCall, setIsApiCall] = useState(false);
  const [name, setName] = useState();
  const [catId, setCatId] = useState(categories[0]?._id);
  const [flavor, setFlavor] = useState();
  const [size, setSize] = useState();
  const [price, setPrice] = useState();
  const [stock, setStock] = useState();
  const [icon, setIcon] = useState();
  // const [loading, setLoading] = useState(true);
  const iconInput = useRef();

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-color text-left">
          Add Product
        </span>
        <div data-validate="Name is required" className="mb-3">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div data-validate="Category is required" className="mb-3">
          <span className="add-evidence-modal-label">Select Category: </span>
          <select
            value={catId}
            onChange={(e) => {
              setCatId(e.target.value);
            }}
            className="px-3 py-2 custom-selection"
          >
            {categories.length > 0 &&
              categories.map((category) => (
                <option value={category._id}>{category.catName}</option>
              ))}
          </select>
        </div>

        <div data-validate="Flavor is required" className="mb-3">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Flavor"
            value={flavor}
            onChange={(e) => {
              setFlavor(e.target.value);
            }}
          />
        </div>

        <div data-validate="Flavor is required" className="mb-3">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Size"
            value={size}
            onChange={(e) => {
              setSize(e.target.value);
            }}
          />
        </div>

        <div data-validate="Flavor is required" className="mb-3">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
        </div>

        <div data-validate="Flavor is required" className="mb-3">
          <input
            className="add-evidence-modal-input full"
            type="text"
            name="specialization-text"
            placeholder="Stock"
            value={stock}
            onChange={(e) => {
              setStock(e.target.value);
            }}
          />
        </div>

        <div className="validate-input mt-4" data-validate="Image is required">
          <span className="add-evidence-modal-label">Add Image</span>
          <div
            className="d-flex flex-wrap mt-5"
            style={{ width: "50px", height: "50px" }}
          >
            <AddImageComponent
              index={null}
              item={icon}
              onSubmit={(data) => setIcon(data)}
              initialState={icon}
            />
          </div>
        </div>

        <input
          ref={iconInput}
          className="d-none"
          type="file"
          onChange={(e) => setIcon(e.target.files[0])}
        />

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="custom-btn login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsApiCall(true);
                onUpdate(name, catId, flavor, size, price, stock, icon);
              }}
            >
              {isApiCall ? "Adding..." : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddProductModal;
